<script>
import Next from '@/components/Next-button.vue';
import Previous from '@/components/Previous-button.vue';
import IconSun from '@/assets/images/vueicons/sunicon.vue';
import IconMoon from '@/assets/images/vueicons/moonicon.vue';


export default {
  components: {  Next, Previous, IconSun, IconMoon },  

  props: {
    id: Number,
    title: String,
    type: String,
    typedescription: String,
    period: String,
    role1: String,
    role2: String,
    company: String,
    challenge: String,
    cover: String,
    overviewhighlight: String, 
    overview: String,
    bubbles: Object,
    colormode: Boolean,
    deviceMobile: Boolean,
    deviceTablet: Boolean, 
    deviceDesktop: Boolean,
    contraints: Object, 
    photo: Object,
    problemhighlight: String,
    problem: String,
    solution: String,
  },


}
</script>
<template>
    <div class="container">
    <section class="intro">
        <div class="column1">
        <h1>{{ title }}</h1>  
        <div class="pagination">
           <p><span>0{{ id }}</span>/03</p>
          <div class="arrows">
            <Previous @click="back"/>
            <Next @click="forward"/>
          </div>

        </div>
        </div>   
        <div class="column2">
            <div><h3>{{ type }}</h3><p>{{ typedescription }}</p></div>
            <div><h3>period</h3><p>{{ period }}</p></div>
        </div> 
        <div class="column3">
            <div><h3>Role</h3><p>{{ role1 }}<br>{{ role2 }}</p></div>
        </div> 
        <div class="column4">
        <div><h3>The product</h3><p> {{ company }} </p></div>  
        <div><h3>The challenge</h3><p> {{ challenge }} </p></div>  
        </div> 
    </section> 


    <div class="CoverSpace"></div>    
    <div class="CaseCover" ></div>
    </div>
           

    <section class="projectbrief">
        <h2><span>long story short</span><br>
        the project</h2>
        <div class="colormode" v-if="colormode">
            <IconSun/> 
            <IconMoon/> 
        </div>
        <div class="devices">
            <div class="mobile" v-if="deviceMobile"></div>
            <div class="tablet" v-if="deviceTablet"></div>
            <div class="desktop" v-if="deviceDesktop"></div>
        </div>
        <div class="overview half2">
            <p><span> {{ overviewhighlight }}
                </span></p>
            <p>{{ overview }}</p>    
        </div>
        <div class="bubbleswrap">
            <p class="bubble" v-for="bubble in bubbles" :key="bubble"> {{ bubble }}</p>
        </div>        
        <div class="half1" id="theproblem">
            <h3>The problem</h3>
            <p><span>{{ problemhighlight }}</span></p>
            <p>{{ problem }}</p>
        </div>
        <!-- <div class="half1" id="contraints">
            <h3>constraints & requirements</h3>
            <p  v-for="contraint in contraints" :key="contraint" > {{ contraint }} </p>
            

        </div> -->        <div class="photo">
        </div>

        <div class="half2" id="thesolution">
            <h3>Action plan</h3>
            <p>{{ solution }}</p>
        </div>

    </section>



</template>
<style scoped>

.brief, .wrap, h1 {
    display: flex;
}



.intro {
    height: 70vh;
    position: relative;
}

h1 {
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    max-width: 180px;
}

.column1 {
    grid-column: 2 / span 2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between

}

.column1 span {
    font-size: 1.1rem;
}

.pagination, .arrows {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.pagination p span {
    font-weight: 600;
}
.arrows {
    padding: 0 12px;
    gap: 8px;
}
.arrows .container {padding: 0}

.column2 {
    grid-column: 6 / span 2;
}

.column3 {
    grid-column: 8 / span 2;
}

.column4 {
    grid-column: 10 / span 4;
}

.container {
  position: relative;
}

.CoverSpace, .CaseCover  {
    height: 90vh;
}

.CaseCover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: fixed;
    z-index: -1;
}

.photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(1) opacity(.95);
    width: 100%;
    grid-column: 9 / span 6;
    height: 300px;
}

.Caseiis .projectbrief .photo {
    background-image:url(@/assets/Caseiis/photo1.jpg) ;
}

.Casebits .projectbrief .photo {
    background-image:url(@/assets/Casebits/photo1.png) ;
}

.Caseeve .projectbrief .photo {
    background-image:url(@/assets/Caseeve/photo1.jpg) ;
} 

.Caseiis .container .CaseCover {
    background-image:url(@/assets/Caseiis/coveriis.png) ;
}

.Caseeve .container .CaseCover {
    background-image:url(@/assets/Caseeve/EveCover.png) ;
}

.Casebits .container .CaseCover {
    background-image:url(@/assets/Casebits/BitsCover.png) ;
}

.projectbrief {
    background-color: var(--background);
    position: relative;
    z-index: 1;
    padding: 48px 0;  
}



.devices {
    grid-column: 11 / span 3;
    padding: 12px 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
}

.mobile, .tablet, .desktop {
    background-color: var(--secondary-color);
    opacity: .8;
}
.mobile {
    height: 48px;
    width: 28px;
}

.tablet {
    height: 90px;
    width: 65px;
}

.desktop {
    height: 70px;
    width: 110px;
}

.overview {
    padding: 48px 0;

}

.bubbleswrap {
    grid-column: 2 / span 5;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 36px 0;
}

.bubble {
    padding: 6px 16px;
    border: solid .5px;
    border-radius: 24px;
    align-self: center;
}

#thesolution {
    padding: 36px 0 0 0 ;
}



@media only screen and (max-width: 900px) {

    .intro {
         height: unset;
    }
    .CoverSpace   {
    height: 50vh;
    }
     .CaseCover  {
    height: 90vh;
    }
    .column1 {
        grid-column: 2 / span 5;
        grid-row: 1 / span 2;
    } 
    .column2 {
        grid-column: 7 / span 3;
    }
    .column3 {
        grid-column: 10 / span 3;
    }
    .column4 {
        grid-column: 7 / span 7;

    } 
    .photo {
            grid-column: 8 / span 7;
    }

}

@media only screen and (max-width: 720px) {


    .column1{
    flex-direction: row;
    align-items: flex-start;
    }

    .pagination {
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 8px;
    }

    .arrows {
    padding: 0.83em 0 0 0;
    }

    .column1, .column2, .column3, .column4, .contraints  {
        grid-column: 2 / span 9;
    }

    .devices{
        grid-column: span 6;
        transform: scale(.7);
        justify-content: flex-end;
        position: relative;
        top: -24px;
    }
    .overview {
    padding: 24px 0;
    }
    .photo {
    grid-column: 1 / span 12;
    width: 100%;
    height: 220px;
    margin: 30px 0;
    }
}

@media only screen and (min-width: 1800px) { 

    .photo {
   grid-column: 9 / span 5;

    }}


</style>