<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_65)">
<path d="M11.306 20.5C11.1265 20.5 10.947 20.4646 10.7744 20.3939C10.262 20.1805 9.93324 19.6869 9.93461 19.1361L9.9579 11.4547C9.9579 11.3827 9.92913 11.312 9.87844 11.2616L4.40126 5.82115C4.00808 5.43089 3.89163 4.8489 4.10534 4.33899C4.31906 3.82907 4.81499 3.5 5.37121 3.5H18.6299C19.1847 3.5 19.682 3.82907 19.8944 4.33763C20.1081 4.84618 19.9916 5.42817 19.5998 5.81843L14.1432 11.2616C14.0911 11.3133 14.0637 11.3813 14.0637 11.4534V17.7777C14.0637 18.1381 13.9158 18.4916 13.6568 18.7445L12.2677 20.107C12.006 20.364 11.6594 20.5 11.306 20.5ZM5.37121 4.58783C5.20544 4.58783 5.13968 4.70477 5.11776 4.75508C5.09721 4.80539 5.06022 4.93593 5.17667 5.05151L10.6538 10.492C10.91 10.7463 11.0553 11.0985 11.0553 11.4574L11.032 19.1389C11.032 19.302 11.1498 19.37 11.2005 19.3904C11.2512 19.4108 11.3813 19.4475 11.4991 19.3333L12.8883 17.9708C12.9403 17.9205 12.9691 17.8498 12.9691 17.7777V11.4534C12.9691 11.0917 13.1102 10.7504 13.3678 10.4947L18.823 5.05151C18.9395 4.93593 18.9025 4.80539 18.882 4.75508C18.8614 4.70477 18.7943 4.58783 18.6285 4.58783H5.37121Z" />
</g>
<defs>
<clipPath id="clip0_1_65">
<rect width="16" height="17"  transform="translate(4 3.5)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>