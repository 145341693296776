<script>
import Projectbrief from '@/components/CaseComponents/Projectbrief.vue'
import '@/assets/casestudystyle.css'


export default {
    components: {
        Projectbrief
    }, 
    data () {
        return {
            projects : [
                {
                id: 2, 
                title: 'How to grade feelings? - Core experience', 
                type: 'Android App',
                typedescription: 'Mobile application',
                period: '6 weeks',
                role1: 'Product Designer', 
                role2: 'Ui/Ux',
                company: 'It\'\s safe? is a crowdsourced community focused on the safety of LGBTQ+ people. It opens up space for sharing experiences, allowing people to help each other on their journey, highlighting areas and places of concern, and promoting welcome points.',
                challenge: 'Assisting users in translating elusive emotions and sensations into measurable elements that can be quantified, ranked and shared.',
                colormode: true,
                deviceMobile: true,
                deviceTablet: false,
                deviceDesktop: false,
                bubbles: ['Research', 'Strategy', 'Concept', 'Design'],
                overviewhighlight: 'This sprint focused on redesigning the review flow, for a security-focused platform. Facing the challenge of assisting users to translate emotions and sensations into shareable elements, such as ratings (quantitative) and reviews (qualitative).',
                overview: 'The primary goal was to create a more intuitive review experience. This experience should guide the user into translating experiences and concerns about safety, which can be difficult to express or define clearly, into actionable data points that can be measured, ranked, and communicated effectively.',
                contraints: ['The deadline for this redesign was 4 weeks, including research, design and testing;', 'The new design must handle a growing number of brands and user base and enhanced search options allowing users to find brands based on various criteria (e.g., category, popularity, recent additions).', 'Due to the impact that this flow has on the entire application, we need to narrow the focus, prioritizing simple solutions that align with our development budget, and time constraints.',],
                problem: 'Users face unique safety challenges that are difficult to quantify and communicate. Without a structured way to translate subjective feelings of safety into measurable data, it becomes challenging to highlight areas of concern, promote welcoming points, and drive meaningful change. '


                },
            ],
        }
    }    
}

</script>


<template>
    <div class="theproject Caseiis" >
        <Projectbrief  v-for="project in projects" v-bind="project" :key="project.title"/> 
        <div class="process">
            <section>
      <h2><span>let me tell you a story</span><br>
        Design process</h2>
        <p>teste</p>
        <p>teste</p>
        <p>teste</p>
        <p>teste</p>
            </section>
        </div>
    </div>
</template>
