<script>
import Projectbrief from '@/components/CaseComponents/Projectbrief.vue'
import '@/assets/casestudystyle.css'

export default {
    components: {
        Projectbrief
    }, 
    data () {
        return {
            projects : [
                {
                id: 1, 
                title: 'supplier request FOR AN EVENT MANAGEMENT PLATFORM', 
                type: 'Web application',
                typedescription: 'Primarily desktop',
                period: '2 weeks',
                role1: 'Product Designer', 
                company: ' Platform used by event organizers, among other things, to facilitate communication and manage their suppliers. As part of its functionality, the platform allows organizers to send Production Requests to suppliers, asking them to provide essential details about event logistics, staffing and accommodation.',
                challenge: 'Improve the request flow, ensuring that all necessary information is filled in without skipping steps, improve the communication tool, and find a better way to present the event/supplier production schedule.',
                colormode: false,
                deviceMobile: true,
                deviceTablet: true,
                deviceDesktop: true,
                overviewhighlight: 'This project assess one particular flows of a comprehensive platform utilized by event organizers to optimize communication and supplier management processes. ',
                overview: 'The primary goal was to improve the request process, point up all the required steps to the user, and making sure all necessary info gets filled in properly in the process. In addition I aimed to upgrade our communication tool for clearer exchanges, and create a clear way to display event and supplier schedules. These changes intent to simplify workflow, boost coordination, and enhance the overall user experience for both organizers and suppliers. (This is part of a personal redesign project.)',
                bubbles: ['Strategy', 'Concept', 'Design'],
                // contraints: ['The primary goal of this redesign is to enhance the information request process. Any addicional improvements would be nice to have but must fit into the same timeline.', 'The expected timeframe for this redesign was 2 weeks.', 'Familiarity should be preserved for current users accustomed to the current platform interface. Additions must align with the platform\'\s existing design system and workflow.', 'This flow runs in the browser and integrates with the web platform so it is import to ensure that the redesigned is responsive and compatible with multiple devices. Desktop remains the primary focus.'],
             problem: 'The previous Production Requests was not clear, leading to incomplete submissions and missed information. Users often skip essential steps, resulting in delays. In addition to that the presentation of event and supplier schedules is not intuitive, making it difficult to align schedules and manage timelines effectively.',
                solution: 'To ensure all necessary information is captured before submission, we implemented a linear, guided process with visual cues for validation checks. For the schedule presentation, I created a timeline-based visual representation, allowing users to easily visualize different phases and compare schedules with organizers and other providers. Additionally, we upgraded the communication module to include a slow chat feature, facilitating clearer and more effective communication. This redesign focuses on specific and actionable solutions, directly addressing the identified problems and providing a clear path for implementation.',

                },
            ],
        }
    }    
}

</script>
<template>
    <div class="theproject Caseeve">
        <Projectbrief  v-for="project in projects" v-bind="project" :key="project.title"/> 
        <div class="process">
            <section v-motion-slide-visible-once-bottom>
                <img class="full" src="@/assets/Caseeve/evebts.png">
            </section></div>
        <div class="process">
            <section>
                  <h2 v-motion-slide-visible-once-bottom><span>let me tell you a story</span><br>
                    Design process</h2>
                <div class="space full"></div>
                <div class="half1" v-motion-slide-visible-once-bottom>
                        <h3>Some background</h3>
              <p>The platform enables event organizers to gather essential information from suppliers by sending Production Requests. Suppliers receive a request email with a link to fill in their details on an external webpage.</p>
                </div>
                <div class="half2" v-motion-slide-visible-once-bottom>     
                    <dl>
                        <dt>What a Production Request is for?</dt>
                        <dt>Through this Production Request the supplier can let the organiser know</dt>
                        <dd> which people will be working as crew on the event site, </dd>
                        <dd> which people are invited as guests to the event, </dd>
                        <dd> what accreditation (For example; parking tickets, portophones, access, dinner vouchers) these people need </dd>
                        <dd> if these people require accommodation for an overnight stay. </dd>
                        <dd> specify their power need during the event </dd>
                        <dd> specify their transports from and to the event site </dd></dl>
                    <p>And when the supplier is done filling in his request, the organiser will then review and approve the request.</p>  
                </div>
                <div class="space full"></div>
                <div class="full" v-motion-slide-visible-once-bottom>        
                     <img src="@/assets/Caseeve/EveFlow01.png" alt="Initial Userflow">
                     <figcaption> [Production request flow] </figcaption>
                 </div>
                 <div class="space full"></div>
                               <div class="half2">
                    <p>The kickstart of this project was the need to refine the process of gathering initial information from suppliers, which is crucial to promoting effective communication between the organizer and the supplier throughout the entire event cycle. </p>
                    <p>Rather than reinventing the wheel, the goal was to refine the existing flow. So the first step was review the previous flow, breaking down ower requirements and potencial improvements.</p>
                 </div>
                <div class="space full"></div>
                <div class="full" v-motion-slide-visible-once-bottom>        
                     <img src="@/assets/Caseeve/Requirements.png" alt="Requirements">
                </div>

            </section>
        </div>

    </div>
</template>

<style>

.process {
    position: relative;
    z-index: 1;
    background-color: var(--background);
}

</style>