<template>
<div class="wrap">
    <div class="msg">
    <div>
        <p id="Error">ERROR</p>
        <p id="Number">404</p>
    </div>
    <div class="text">
        <p class="line1">it Seems that the page you are looking for cannot be found,</p>
        <p>it might have been removed or the url might be incorrect.</p>
    </div></div>
      <Goback/>
</div>
</template>
<script>
import Goback from '@/components/GobackHome-button.vue'

export default {
    components: {
        Goback,
    }
}

</script>

<style scoped>
@import url("https://use.typekit.net/awa6knv.css");

* {
    display: flex;
    padding: 0;
    align-content: center;
    line-height: 150%;
}

.wrap {
    height: 85vh;
    width: 100vw;    
    margin: auto;  
    flex-direction: row;
    align-items: center;
    padding: 0 108px;
    justify-content: space-between;
}

.text {
    flex-direction: column; 
    padding: 0 36px; }
   

#Error {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 100%;
    letter-spacing: initial;
}

#Number {
    font-size: 2.4rem;
    line-height: 100%;
    font-weight: 100;
    letter-spacing: initial;
}

.line1 {
    font-weight: 700;
}
@media only screen and (max-width: 900px) {

    * {
      align-items: flex-start;
      line-height: 160%;
    }
    .wrap {
      padding: 0;
      height: 80vh;
      justify-content: center;
    }
    .wrap, .msg {
      flex-direction: column; 
    }
    .text {
      padding: 6px 2px;
      width: 180px;
    }
}

</style>