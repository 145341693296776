<template>
    <!--------overview------->
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="half2 textbox">
          <h2>Overview</h2>
          <div class="text-block half2">
            <p>When I joined this project, I took on the role of a solo designer and worked closely with the product owner to develop the project from its initial stages. We had a lot of ideas and possibilities, which made us realize the need to narrow our focus. </p>    
            <p>For our initial efforts, we decided to focus on two fronts. We were making the first visual choices for our app, developing a consistent identity that met our current needs and also be scalable in the future. And the second centered on the refinement of essential features; specifically, the creation and display of user reviews and ratings, which would significantly influence the core user experience.</p>      
          </div>
        </div>   
    </div>

    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="half2" src="@/assets/case01/iis01.png" alt="">
    </div>
     
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="text-block textbox half2">
            <p>In addition to reviewing the existing flow, to start the project off on the right foot, I opted to engage myself to our target users and asked them to participate in surveys and interviews. On a personal and professional level, it gave me a better understanding of the reality and needs of our audience, preventing the process of what we were starting from being developed based on guesswork.
            </p>
         </div>
    </div>

    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case01/iis02.png" alt="">
        <div class="lspace"></div>
        <img class="center" src="@/assets/case01/iis04.png" alt="">
    </div> 
            <div class="lspace"></div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
         <div class="sspace"></div>
        <div class="center insights">
            <p>Participants get together with friends or peer on avarage 1 - 2 times per week.</p>
            <p>More than 63% of participants claim had suffered or known someone who suffered homophobia and harassment in public places;</p>
            <p>There is demand for places aimed at the public, but still few options;</p>
            <p>Most of the targets claimed to be unresponsive and not knowing what to do about it, starting to avoid public places;</p>
            <p>More than 75% said avoid to go alone to places they had not previously visited;</p>
            <p>The opinion of another community member about a place is a decisive factor for most participants;</p>
            <p>About digital products, were highlighted privacy and the perception of safety, often connected to visual and language aspects;</p>
            <p> Interviewees reported difficulty when trying to evaluate a place/service, not being able to translate sensations into a grade or put them into a few words.
</p>
        </div>
    </div> 
    <div class="lspace"></div>

</template>
<script>
import '@/assets/caseslayout.css'

export default {
    components: {  }
}

</script>

<style scoped>

* {
    max-width: 100%;
}

.insights{
columns: 3 200px;
column-gap: 48px;


}

.insights p{
 border-left: 4px solid #A093F3;
 padding: 4px 16px ;
 margin:  0 0 48px 0;


}

  @media only screen and (max-width: 900px) { 

    .insights{
    columns: 2 220px;
    column-gap: 48px;
    padding: 0 48px ;

    }
  }

  @media only screen and (max-width: 700px) { 

    .insights{
      padding: 0 36px ;

    }
  }

</style>