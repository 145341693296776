<template>
    


<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_68)">
<path d="M12.5222 19.983C7.70026 20.2906 3.70774 16.2993 4.01651 11.4774C4.27143 7.49082 7.49082 4.27143 11.4762 4.01651C16.2981 3.70774 20.2906 7.70026 19.9818 12.5222C19.7269 16.5075 16.5075 19.7281 12.521 19.9818L12.5222 19.983ZM12.4599 4.97276C8.21488 4.70108 4.70108 8.21488 4.97156 12.4599C5.19536 15.9689 8.03058 18.803 11.5384 19.0268C15.7834 19.2984 19.2972 15.7834 19.0268 11.5384C18.803 8.02938 15.9677 5.19536 12.4587 4.97036L12.4599 4.97276Z" />
<path d="M16.3857 12.4937L11.5207 12.4769L11.5159 7.61194C11.5159 7.34745 11.7301 7.13322 11.9946 7.13322C12.2591 7.13322 12.4733 7.34745 12.4733 7.61194L12.4769 11.5231L16.3881 11.5362C16.6526 11.5362 16.8656 11.7517 16.8656 12.0162C16.8656 12.2807 16.6502 12.4937 16.3857 12.4937Z" />
</g>
<defs>
<clipPath id="clip0_1_68">
<rect width="16" height="16" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>
</template>

<style scoped>

path {
    fill: var(--main-color)
}

</style>