<template>
<svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Arrow-right">
<path id="arrow" d="M21.7017 14.9979L15.0147 21.5495C14.9108 21.6512 14.775 21.7021 14.6378 21.7021C14.5007 21.7021 14.3649 21.6512 14.261 21.5495C14.0533 21.346 14.0533 21.0159 14.261 20.8111L19.4495 15.729C19.5135 15.6663 19.4691 15.5575 19.3795 15.5575L7.65918 15.5575L7.65918 14.5139L19.4568 14.5139C19.5464 14.5139 19.5908 14.4051 19.5268 14.3424L14.2677 9.18856C14.0586 8.98504 14.0586 8.65498 14.2677 8.45147C14.4754 8.24665 14.8123 8.24665 15.02 8.45147L21.7017 14.9979Z" fill="black"/>
<path id="spinner" d="M15 0.499992C23.0081 0.499991 29.5 6.99186 29.5 15C29.5 23.0081 23.0081 29.5 15 29.5C6.99187 29.5 0.5 23.0081 0.499999 15C0.499999 6.99187 6.99187 0.499992 15 0.499992Z" stroke="black"/>
</g>
</svg>
</template>
<style scoped>

#arrow {
    fill: var(--main-color);
    
    }

#spinner{
    stroke:  var(--main-color);

}
</style>