<template>
 <div class="project-container" >
       <div class="navigation">  
          <Goback @click="$router.push({name: 'archieve'})"/>  
          <div class="arrows">
            <Previous @click="$router.push({ name: 'icl' })"/>
            <Next @click="$router.push({name: 'blm'})"/> 
          </div>  
        </div>
    <div class="projectwrap">
         <div class="info">          
            <h1>Approve - Website concept </h1>
            <p class="note"></p>   
        </div>      
        <div class="filegallery"> 
          <div @click="toggleModal(image)"  v-for="image in images" :key="image">
            <img class="figure" :src="image" /></div>
         </div>
    </div>
    <div class="modalwrap" v-if="showModal">
        <Modal @close="toggleModal" :image="currentImage" />
    </div>
</div>
</template>
<script>
import Goback from '@/components/Goback-button.vue';
import Next from '@/components/Next-button.vue';
import Previous from '@/components/Previous-button.vue';
import Modal from '@/components/modal.vue';

// APV
import Apv01 from '@/assets/images/visualjournal/APV/NavBar.png';
import Apv02 from '@/assets/images/visualjournal/APV/image 270.png';
import Apv03 from '@/assets/images/visualjournal/APV/image 272.png';
import Apv04 from '@/assets/images/visualjournal/APV/image 665.png';
import Apv05 from '@/assets/images/visualjournal/APV/image231.png';

export default { 
    components: { Goback, Next, Previous, Modal },
    data () {
         return {
            images: [Apv02, Apv03, Apv04, Apv05, Apv01, ],
            showModal: false,
            currentImage: {}
            }
    },
        methods: {
      toggleModal(image) {
        this.showModal = !this.showModal;
        this.currentImage = image;
      } 
    }
    
}
</script>
