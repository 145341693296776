<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_59)">
<path d="M15.2216 11.4162C14.8393 11.2124 14.4366 11.0499 14.0203 10.9274C14.9672 10.3091 15.6161 9.2654 15.7059 8.05095C15.7127 7.95318 15.7167 7.85542 15.7167 7.76454C15.7167 7.38589 15.6623 7.01411 15.5535 6.65886C15.0719 5.0685 13.6421 4 11.9974 4C9.94592 4 8.2767 5.6895 8.2767 7.76454C8.2767 9.10706 8.9569 10.2788 9.99353 10.9453C9.38135 11.127 8.79638 11.3941 8.26446 11.7453C6.32453 13.0217 5.35048 15.1394 5.51917 17.7088L5.53549 17.9401L5.72187 18.075C7.5108 19.3597 9.7541 20.0014 11.9988 20.0014C14.2434 20.0014 16.4854 19.3597 18.2743 18.075L18.4552 17.9442L18.4756 17.7198C18.492 17.5215 18.5015 17.3246 18.5015 17.1332V17.1208C18.4362 14.5336 17.2717 12.5081 15.2229 11.4176L15.2216 11.4162ZM9.24123 7.76454C9.24123 6.22788 10.4778 4.97625 11.996 4.97625C13.215 4.97625 14.2734 5.76661 14.6311 6.94664C14.7114 7.20964 14.7522 7.48365 14.7522 7.76454C14.7522 7.83339 14.7495 7.90499 14.7454 7.97797C14.6379 9.39897 13.5006 10.5019 12.0954 10.5515C12.0627 10.5515 12.0301 10.5515 11.9974 10.5515C11.9648 10.5515 11.9321 10.5515 11.8995 10.5515C10.4085 10.5005 9.24123 9.27917 9.24123 7.76454ZM17.5288 17.4072C14.3822 19.536 9.62078 19.5373 6.47009 17.4114C6.39527 15.317 7.21423 13.6014 8.78957 12.5645C10.5309 11.4176 12.9361 11.3033 14.7713 12.2809C16.4963 13.1993 17.4771 14.9246 17.5356 17.1387C17.5356 17.2282 17.5329 17.3164 17.5288 17.4072Z" />
</g>
<defs>
<clipPath id="clip0_1_59">
<rect width="13" height="16" fill="white" transform="translate(5.5 4)"/>
</clipPath>
</defs>
</svg>



</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>