<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_138)">
<path d="M18.8045 18.7977L18.8011 18.8011C18.5381 19.0641 18.1133 19.0641 17.8503 18.8011L12.1205 13.073C12.0548 13.0072 11.9486 13.0072 11.8828 13.073L6.15304 18.8028C5.89007 19.0657 5.46358 19.0657 5.2006 18.8028C4.93763 18.5398 4.93763 18.1116 5.2006 17.8486L10.927 12.1188C10.9928 12.0531 10.9928 11.9469 10.927 11.8812L5.19723 6.15304C4.93426 5.89007 4.93426 5.46526 5.19723 5.20229C5.4602 4.93931 5.88669 4.93931 6.14967 5.20229L11.8795 10.9304C11.9452 10.9961 12.0514 10.9961 12.1172 10.9304L17.8453 5.19723C18.1082 4.93426 18.5364 4.93426 18.7994 5.19723C19.0624 5.4602 19.0624 5.88838 18.7994 6.15135L13.073 11.8795C13.0072 11.9452 13.0072 12.0514 13.073 12.1172L18.8045 17.8453C19.0674 18.1082 19.0674 18.5331 18.8045 18.796V18.7977Z" fill="#2A2A46"/>
</g>
<defs>
<clipPath id="clip0_1_138">
<rect width="14" height="14" fill="white" transform="translate(5 5)"/>
</clipPath>
</defs>
</svg>



</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>