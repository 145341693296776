<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.01886 12.5884C2.67149 7.16336 7.16305 2.67146 12.5877 3.01885C17.0712 3.30566 20.6943 6.92773 20.9811 11.4115C21.3285 16.8366 16.8369 21.3285 11.4123 20.9811C6.92743 20.6956 3.30564 17.0736 3.01886 12.5884ZM19.9053 12.5184C20.2109 7.74235 16.2566 3.78905 11.4809 4.09336C7.53331 4.34515 4.34505 7.53499 4.09328 11.4816C3.78765 16.2576 7.742 20.2109 12.5177 19.9066C16.4653 19.6548 19.6535 16.4649 19.9067 12.517L19.9053 12.5184Z" />
<path d="M15.8607 15.8429H15.8594C15.6494 16.0543 15.3101 16.0529 15.1 15.8442L11.9926 12.7513L8.86623 15.8927C8.65619 16.1041 8.31555 16.1054 8.10417 15.894H8.10282C7.89279 15.6826 7.89413 15.3433 8.10282 15.1319L11.2291 11.9905L8.10148 8.87745C7.89144 8.66874 7.89009 8.32673 8.10013 8.11533C8.31017 7.90528 8.65081 7.90528 8.86219 8.11398L11.9899 11.2271L15.0664 8.13418C15.2764 7.92278 15.617 7.92144 15.8284 8.13284H15.8298C16.0398 8.34423 16.0385 8.68355 15.8298 8.89495L12.7533 11.9878L15.8607 15.0807C16.0721 15.2908 16.0735 15.6315 15.8621 15.8429H15.8607Z" />
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>