<template>
    <div class="menu-trigger" >
        <svg class="iconx" id="close" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"> <g id="icon-plus"> <path id="shape" d="M20.8809 15L20.8809 27M27 20.9995L15 20.9995"   stroke-width="2"/></g>
        </svg>
    </div> 
</template>


<style scoped>

.menu-trigger {
    height: 36px;
    width: 36px;
    min-width: 36px;
    display: flex;
    background-color: var(--surface);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    margin: 1% 1.2%;
    z-index: 999;
}

.menu-trigger:hover {
   background-color:  var(--main-color)
}

.menu-trigger:hover #icon-plus {
   stroke:   var(--surface);
}

 #icon-plus  {
    stroke: var(--main-color);

}

.menu-trigger svg {
    animation: spin-back .5s linear 0.05s forwards ;
    -webkit-animation: spin-back .5s linear 0.05s forwards;
    transform: rotateZ(0deg);
    animation-play-state: running;
    transform-origin: center center;
    animation-delay:0.1s;
}

#app:has(.sideMenuActive) .menu-trigger svg{
    animation: spin .5s linear 0.05s forwards;
    -webkit-animation: spin .5s linear 0.05s forwards ;
    transform: rotateZ(0deg);
    animation-play-state: running;
    transform-origin: center center;
}


@keyframes spin {
    0% {
        transform:rotatez(0deg);
    }
    4% {
        transform:rotate(-15deg);
    }
    8% {
        transform:rotate(90deg);
    }
    65% {
        transform:rotate(280deg);
    }
    100% {
        transform:rotate(315deg);
    }
}

@keyframes spin-back {
    0% {
        transform:rotatez(0deg);
    }
    4% {
        transform:rotate(15deg);
    }
    8% {
        transform:rotate(-90deg);
    }
    65% {
        transform:rotate(-280deg);
    }
    100% {
        transform:rotate(-360deg);
    }
}

@media only screen and (max-width: 760px) {

    .menu-trigger {
    height: 42px;
    width: 42px;
    min-width: 42px;
    margin: 4%;
 }
}
</style>