<template>

      <!--------COLOR MODE------->   
    <div class="tile grid"   v-motion-slide-visible-once-bottom>
      <div class="sspace"></div>
      <figure class="center">
          <figcaption>COLOR MODE</figcaption>
          <img src="@/assets/case02/colormode.png" alt="">
      </figure>
    </div>
      <!--------DOCUMENTATION & HANDOFF PROCESS------->     
    <div class="tile grid"   v-motion-slide-visible-once-bottom>
      <div class="half2 textbox">
          <h2 class="column4">DOCUMENTATION & HANDOFF PROCESS</h2>
          <p class="text-block"> Colin, our CDO at Bits, suggested using the Figma Token plugin to centralize and improve our design system. It simplifies project documentation, ensuring clarity for the whole team and making handovers smoother. It also helps me stick to established standards. </p>
      </div>
    </div>
    <div class="tile grid"   v-motion-slide-visible-once-bottom>
      <div class="center">
          <img src="@/assets/case02/figmatoken.png" alt="">
      </div>
    </div>  
      <!--------CLOSING AND TAKEAWAYS------->   
     <div class="tile grid"   v-motion-slide-visible-once-bottom>  
      <div class="half1 textbox">
            <h2 class="column4">CLOSING AND TAKEAWAYS</h2>
            <div class="text-block">
              <p>The redesign led to smoother user navigation, improved task completion accuracy, and increased user confidence. The project took just under 5 weeks to effectively address the main complaints and pain points from the previous layout.</p>
              <p> We introduced Reusable Tokens alongside the redesign to improve the design system's efficiency and dynamism. My first experience with this tool was positive, and I intend to apply it to other projects. This greatly simplified design documentation, enhancing clarity for the entire team and streamlining the handoff process. </p>
            </div>
      </div>
      <div class="lspace"></div>
    </div>    

</template>
<script>
import ArrowRight from '@/assets/navigationicons/Arrowright.vue';
import '@/assets/caseslayout.css'

export default {
    components: {
        ArrowRight,
    }
}
</script>

<style scoped>






</style>