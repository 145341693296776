<template>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="half2 textbox text-block ">
        <p>To create a consistent identity for our project, I began by selecting fundamental elements such as colors, typography, and a grid structure. These elements a foundation that we could build upon formed throughout the design process by creating new elements as needed.</p>
        <p>Our color scheme revolved around the color purple, which we intensified with different hues. This approach allowed us to create a dynamic and vibrant visual identity that worked well for both light and dark modes. I believe this color scheme, connected with our target audience and helped us create the atmosphere we envisioned for the project.</p>
    </div></div>
     <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/colors.png" alt="">
    </div></div>
         <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/mk1.png" alt="">
    </div></div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/G-MK1.png" alt="">
    </div></div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/G-MK2.png" alt="">
    </div></div>
        <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/G-MK3.png" alt="">
    </div></div>
<div class="lspace"></div>    
            <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/G-MK4.png" alt="">
    </div></div>
    <div class="lspace"></div>
            <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="center">
        <img src="@/assets/case01/G-MK5.png" alt="">
    </div></div>
        <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="half2 textbox text-block ">
        <p>Working on IIS was incredibly rewarding. The team trusted me with a lot of autonomy, which allowed me to experiment and refine my design process. This experience taught me a great deal in a short period. My biggest challenge during the project was prioritization. Despite our enthusiasm for the project, we had to ensure we focused on the essential aspects in this first stage. Esther's partnership and her calm and forbearance throughout the process taught me a valuable lesson. By concentrating on small steps, we surpassed my original expectations.</p>

    </div></div>
</template>

<script>
import '@/assets/caseslayout.css'

export default {
    components: {  }
}

</script>
<style scoped>

* {
    max-width: 100%;
}

div img {
    background-color: #F0F0F0;
}

</style>