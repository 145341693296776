<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_131)">
<path d="M18.9502 5.6451C18.8676 5.53258 18.7736 5.42576 18.6725 5.32464C17.5801 4.2408 15.8069 4.24508 14.7188 5.33319L12.8773 7.17472L12.1054 7.94665L10.966 9.08604L5.25482 14.7972C5.12522 14.9268 5.04119 15.092 5.01413 15.2743L4.5071 18.6697C4.47434 18.8904 4.54983 19.1183 4.70792 19.2764C4.84179 19.4117 5.02552 19.4858 5.21352 19.4858C5.24627 19.4858 5.28046 19.4843 5.31464 19.4787L8.75273 18.9802C8.93361 18.9545 9.10452 18.8691 9.23412 18.7395L16.7569 11.2167L17.8108 10.1628L17.8963 10.0773L18.6796 9.29398C18.7779 9.1957 18.869 9.09031 18.9502 8.97922C19.3091 8.49925 19.5014 7.91817 19.5 7.30717C19.4986 6.70045 19.3063 6.12364 18.9502 5.6451ZM14.4582 11.9032L8.49495 17.8664L5.71912 18.2695L6.12787 15.535L10.966 10.6968L12.1054 9.55746L14.162 7.50229L16.5105 9.85085L14.4582 11.9032ZM17.8963 8.46365C17.8892 8.47219 17.3166 9.04473 17.3166 9.04473L14.9667 6.69618C14.9667 6.69618 15.599 6.06667 15.6375 6.03533C15.9437 5.7804 16.3225 5.65222 16.6999 5.65222C17.0973 5.65222 17.4961 5.79464 17.8108 6.07806C17.8308 6.09658 17.8507 6.11509 17.8692 6.13361C18.1854 6.44694 18.3606 6.86566 18.3606 7.31002C18.362 7.74299 18.1968 8.15174 17.8963 8.46365Z"/>
</g>
<defs>
<clipPath id="clip0_1_131">
<rect width="15" height="14.9715" fill="white" transform="translate(4.5 4.51424)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>