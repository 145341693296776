<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8817 7.9605L15.8224 19.7525H8.18483L6.11831 7.9605H17.8831H17.8817ZM19.0228 6.96448H4.97572L7.28263 20.1254C7.3449 20.4849 7.71563 20.75 8.15152 20.75H15.8572C16.2931 20.75 16.6638 20.4834 16.7261 20.1239L19.0243 6.96448H19.0228Z" />
<path d="M9.80387 18.9497C9.56347 18.9497 9.35783 18.7595 9.33177 18.5048L8.39336 9.31449C8.36585 9.0404 8.55411 8.79476 8.81622 8.7663C9.07689 8.73785 9.31294 8.93555 9.34046 9.20815L10.2789 18.3985C10.3064 18.6726 10.1181 18.9182 9.856 18.9467C9.83862 18.9482 9.82125 18.9497 9.80532 18.9497H9.80387Z" />
<path d="M14.1947 18.9497C14.1787 18.9497 14.1628 18.9497 14.1469 18.9467C13.8848 18.9197 13.6951 18.6756 13.7211 18.4015L14.5987 9.21114C14.6248 8.93705 14.8565 8.73635 15.12 8.7648C15.3822 8.79176 15.5719 9.0359 15.5458 9.30999L14.6682 18.5003C14.6436 18.7565 14.4365 18.9482 14.1947 18.9482V18.9497Z" />
<path d="M11.9993 18.9497C11.7372 18.9497 11.5228 18.7265 11.5228 18.4509V9.26057C11.5228 8.98498 11.7357 8.76181 11.9993 8.76181C12.2628 8.76181 12.4757 8.98498 12.4757 9.26057V18.4509C12.4757 18.7265 12.2628 18.9497 11.9993 18.9497Z" />
<path d="M19.0228 5.08777H15.5487V4.66689C15.5487 3.88506 14.9405 3.25 14.1947 3.25H9.80387C9.05662 3.25 8.44985 3.88506 8.44985 4.66689V5.08777H4.97572C4.71215 5.08777 4.49928 5.31094 4.49928 5.58653C4.49928 5.86212 4.71215 6.08529 4.97572 6.08529H19.0243C19.2878 6.08529 19.5007 5.86062 19.5007 5.58653C19.5007 5.31244 19.2878 5.08777 19.0243 5.08777H19.0228ZM9.40128 5.08777V4.66689C9.40128 4.43474 9.5823 4.24602 9.80387 4.24602H14.1947C14.4162 4.24602 14.5973 4.43474 14.5973 4.66689V5.08777H9.40273H9.40128Z" />
</svg>

</template>

<style scoped>

path { 
    fill: var(--main-color)
}

</style>