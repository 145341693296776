<template>
    <div class="project-container">
        <div class="navigation">  
          <Goback @click="$router.push({name: 'archieve'})"/>  
          <div class="arrows">
            <Previous @click="$router.push({name: 'int'})"/>
            <Next @click="$router.push({name: 'apv'})"/> 
          </div>  
        </div> 
    
    <div class="projectwrap" > 
            <div class="iconcontainer">  
                <div class="icondisplay"><IconHome/></div>
                <div class="icondisplay"><IconSearch/></div>
                <div class="icondisplay"><IconSearch2/></div>
                <div class="icondisplay"><IconUser1/></div>
                <div class="icondisplay"><IconUser2/></div>
                <div class="icondisplay"><IconUsers/></div>
                <div class="icondisplay"><IconFilter/></div> 
                <div class="icondisplay"><IconClock/></div>
                <div class="icondisplay"><IconEmail/></div>
                <div class="icondisplay"><IconEmailNotification/></div>

                <div class="icondisplay"><IconFlag/></div>
                <div class="icondisplay"><IconFlagFilled/></div>
                <div class="icondisplay"><IconTrash/></div>
                <div class="icondisplay"><IconHeart/></div>
                <div class="icondisplay"><IconHeartFilled/></div>
                <div class="icondisplay"><IconShare/></div>
                <div class="icondisplay"><IconAdd/></div>
                <div class="icondisplay"><IconPin/></div>
                <div class="icondisplay"><IconPaper/></div>
                <div class="icondisplay"><IconDocument/></div>

                <div class="icondisplay"><IconMoon/></div>
                <div class="icondisplay"><IconSun/></div>
                <div class="icondisplay"><IconEdit1/></div>
                <div class="icondisplay"><IconEdit2/></div>
                <div class="icondisplay"><IconStar/></div>
                <div class="icondisplay"><IconStarFilled/></div>
                <div class="icondisplay"><IconCamera/></div>
                <div class="icondisplay"><IconVideoCamera/></div>
                <div class="icondisplay"><IconDesktop/></div>
                <div class="icondisplay"><IconPlay/></div>
                <div class="icondisplay"><IconCardId/></div>

                <div class="icondisplay"><IconInfo/></div>
                <div class="icondisplay"><IconHamburgerMenu/></div>
                <div class="icondisplay"><IconTextLeft/></div>
                <div class="icondisplay"><IconPadlockOpen/></div>
                <div class="icondisplay"><IconPadlockClosed/></div>
                <div class="icondisplay"><IconKey/></div>
                <div class="icondisplay"><IconExit/></div>

                <div class="icondisplay"><IconShop/></div>
                <div class="icondisplay"><IconRedirect /></div>
                <div class="icondisplay"><IconClose/></div>
                <div class="icondisplay"><IconInput/></div>
                <div class="icondisplay"><IconProjectplan/></div>
                <div class="icondisplay"><IconError/></div>
                <div class="icondisplay"><IconArrowRight/></div>
                <div class="icondisplay"><IconArrowLeft/></div>
                <div class="icondisplay"><IconArrowUp/></div>
                <div class="icondisplay"><IconArrowDown/></div>

                <div class="icondisplay"><IconClick/></div>

            </div>
        </div>
    </div>
</template>
<script>
import Goback from '@/components/Goback-button.vue';
import Next from '@/components/Next-button.vue';
import Previous from '@/components/Previous-button.vue';

import IconHome from '@/assets/images/vueicons/homeicon.vue';
import IconEmail from '@/assets/images/vueicons/emailicon.vue';
import IconEmailNotification from '@/assets/images/vueicons/email-notificationicon.vue';
import IconMoon from '@/assets/images/vueicons/moonicon.vue';
import IconSun from '@/assets/images/vueicons/sunicon.vue';
import IconClock from '@/assets/images/vueicons/clockicon.vue';
import IconFlag from '@/assets/images/vueicons/flagicon.vue';
import IconFlagFilled from '@/assets/images/vueicons/flagfilledicon.vue';
import IconSearch from '@/assets/images/vueicons/searchicon.vue';
import IconSearch2 from '@/assets/images/vueicons/searchicon2.vue';
import IconAdd from '@/assets/images/vueicons/addicon.vue';
import IconFilter from '@/assets/images/vueicons/filtericon.vue';
import IconHeart from '@/assets/images/vueicons/hearticon.vue';
import IconHeartFilled from '@/assets/images/vueicons/heartfilledicon.vue';
import IconUser1 from '@/assets/images/vueicons/user1icon.vue';
import IconUser2 from '@/assets/images/vueicons/user2icon.vue';
import IconUsers from '@/assets/images/vueicons/usersicon.vue';
import IconTrash from '@/assets/images/vueicons/trashicon.vue';
import IconCamera from '@/assets/images/vueicons/cameraicon.vue';
import IconVideoCamera from '@/assets/images/vueicons/videocameraicon.vue';
import IconPin from '@/assets/images/vueicons/pinicon.vue';
import IconShare from '@/assets/images/vueicons/shareicon.vue';
import IconPlay from '@/assets/images/vueicons/playicon.vue';
import IconPadlockOpen from '@/assets/images/vueicons/Padlock-Openicon.vue';
import IconPadlockClosed from '@/assets/images/vueicons/Padlock-Closedicon.vue';
import IconKey from '@/assets/images/vueicons/keyicon.vue';
import IconDesktop from '@/assets/images/vueicons/desktopicon.vue';
import IconPaper from '@/assets/images/vueicons/papericon.vue';
import IconDocument from '@/assets/images/vueicons/documenticon.vue';
import IconEdit1 from '@/assets/images/vueicons/edit1icon.vue';
import IconEdit2 from '@/assets/images/vueicons/edit2icon.vue';
import IconCardId from '@/assets/images/vueicons/cardidicon.vue';
import IconStar from '@/assets/images/vueicons/staricon.vue';
import IconStarFilled from '@/assets/images/vueicons/starfilledicon.vue';
import IconArrowLeft from '@/assets/images/vueicons/arrowlefticon.vue';
import IconArrowRight from '@/assets/images/vueicons/arrowrighticon.vue';
import IconArrowUp from '@/assets/images/vueicons/arrowupicon.vue';
import IconArrowDown from '@/assets/images/vueicons/arrowdownicon.vue';
import IconInfo from '@/assets/images/vueicons/infoicon.vue';
import IconExit from '@/assets/images/vueicons/exiticon.vue';
import IconHamburgerMenu from '@/assets/images/vueicons/hamburgermenuicon.vue';
import IconTextLeft from '@/assets/images/vueicons/textlefticon.vue';
import IconRedirect from '@/assets/images/vueicons/redirecticon.vue';
import IconError from '@/assets/images/vueicons/erroricon.vue';
import IconInput from '@/assets/images/vueicons/inputicon.vue';
import IconShop from '@/assets/images/vueicons/shopicon.vue';
import IconProjectplan from '@/assets/images/vueicons/projectplanicon.vue';
import IconClick from '@/assets/images/vueicons/clickicon.vue';
import IconClose from '@/assets/images/vueicons/closeicon.vue';


export default { 
    components: { Goback, Next, Previous, IconHome, IconMoon, IconSun, IconClock, IconEmail, IconEmailNotification, IconFlag, IconFlagFilled, IconSearch, IconSearch2,  IconAdd, IconFilter, IconHeart, IconHeartFilled, IconUser1, IconUser2, IconUsers, IconTrash, IconCamera, IconVideoCamera, IconPin, IconShare, IconPlay, IconPadlockOpen, IconPadlockClosed, IconKey, IconDesktop, IconPaper, IconEdit1, IconEdit2, IconCardId, IconDocument, IconStar, IconStarFilled, IconInfo, IconArrowLeft, IconArrowRight, IconArrowUp, IconArrowDown, IconHamburgerMenu, IconTextLeft, IconExit, IconRedirect, IconError, IconInput, IconShop, IconProjectplan, IconClick, IconClose

        }
    }
       
</script>
<style scoped>


.iconcontainer {
    display: flex;
    flex-flow: row wrap;
    justify-content:flex-start;
    width: 100%;
}

.icondisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--surface);
    padding:20px;
    border: solid var(--background);
    aspect-ratio: 1 / 1;
    border-radius: 6px;
    flex: 0 0 calc(100% / 6)
}

.icondisplay svg {
    transform: scale(1.3);
}

@media (min-width: 2000px) {

    .icondisplay {
      flex: 0 0 calc(100% / 8); }}

@media (max-width: 720px) {
    .icondisplay svg {
    transform: scale(1.1);
}
    
    .iconcontainer {
    padding: 0 2%;
    }

    .icondisplay {
      flex: 0 0 calc(96% / 4); }}
</style>