<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_208)">
<path d="M7.02113 21C6.82539 21 6.63228 20.9354 6.46676 20.8104C6.17381 20.5878 6.02931 20.2169 6.09105 19.8431L6.95807 14.559L3.28635 10.8164C3.02624 10.5512 2.9356 10.1637 3.04726 9.80238C3.15892 9.44241 3.45187 9.18411 3.81051 9.12915L8.88393 8.35837L11.1513 3.55095C11.3129 3.21159 11.6374 3 12 3C12.3625 3 12.687 3.21159 12.8486 3.55095L15.1173 8.35837L20.1907 9.12915C20.5494 9.18411 20.8423 9.44104 20.954 9.80238C21.0656 10.1624 20.975 10.5512 20.7149 10.8164L17.0445 14.559L17.9115 19.8431C17.9733 20.2169 17.8288 20.5878 17.5358 20.8104C17.2429 21.033 16.8606 21.0618 16.54 20.886L12.0026 18.3909L7.46515 20.886C7.3259 20.9629 7.17483 21 7.02376 21H7.02113ZM12 17.2766C12.151 17.2766 12.3021 17.3151 12.44 17.3907L16.8382 19.8088L15.9988 14.6881C15.9463 14.368 16.0474 14.041 16.2707 13.8129L19.8282 10.1857L14.9111 9.43829C14.6037 9.39157 14.337 9.1896 14.1991 8.89695L12 4.23792L9.80087 8.89695C9.66294 9.18823 9.39626 9.39157 9.08886 9.43829L4.17177 10.1857L7.7292 13.8129C7.95253 14.041 8.05368 14.368 8.00113 14.6881L7.1617 19.8088L11.5586 17.3907C11.6965 17.3151 11.8476 17.2766 11.9987 17.2766H12Z" />
</g>
<defs>
<clipPath id="clip0_1_208">
<rect width="18" height="18"  transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>