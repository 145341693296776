<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_8_847)">
<path d="M20 7.60051C20 7.76565 19.9304 7.91578 19.8191 8.02537C19.7079 8.13346 19.5533 8.20102 19.3817 8.20102H4.6183C4.27669 8.20102 4 7.93229 4 7.60051C4 7.43537 4.06956 7.28524 4.18085 7.17565C4.29369 7.06756 4.44827 7 4.6183 7H19.3817C19.7233 7 20 7.26873 20 7.60051Z" />
<path d="M14 16.3995C14 16.5661 13.9565 16.7163 13.887 16.8244C13.8174 16.9324 13.7208 17 13.6136 17H4.38644C4.17293 17 4 16.7313 4 16.3995C4 16.2328 4.04347 16.0827 4.11303 15.9746C4.18356 15.8665 4.28017 15.799 4.38644 15.799H13.6136C13.8271 15.799 14 16.0677 14 16.3995Z" />
<path d="M20 12.0007C20 12.1659 19.9304 12.316 19.8191 12.4256C19.7079 12.5337 19.5533 12.6013 19.3817 12.6013H4.6183C4.27669 12.6013 4 12.331 4 12.0007C4 11.8341 4.06956 11.684 4.18085 11.5759C4.29369 11.4678 4.44827 11.4002 4.6183 11.4002H19.3817C19.7233 11.4002 20 11.669 20 12.0007Z" />
</g>
<defs>
<clipPath id="clip0_8_847">
<rect width="16" height="10" transform="translate(4 7)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>