<template>
<div class="footer-wrap">
    <div class="lastline">
        <div class="copyright">  <p class="year">&copy;<span> {{currentYear}} </span></p> </div>
        <div><p><span>Thank you</span> for watch!</p></div>
        <div class="cordenates"><p>52° 22' 23" N 4° 53' 32" E</p></div>
        <!-- <div class="backtotop" @click="scrollToTop">
             <p class="cta">Back to top</p>
             <Arrowup/>
        </div>
         -->
    </div>
</div>
</template>
<script>
import Arrowup from '../assets/navigationicons/Arrowup.vue'

export default {
    components: {
        Arrowup
    },

    data () {
        return{
            currentYear: new Date().getFullYear()
            };
        },
    
    methods: { 
        scrollToTop() {
            window.scrollTo(0,0);
    }
}}
    
</script>
<style scoped>

.footer-wrap {
    overflow: hidden;
    position: static;
    bottom: 0;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap:24px;
}


.lastline{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
    height: 72px;
}

.copyright{
    padding: 0 8px ;
    display: flex;
    color: var(--main-color);
    align-self: flex-end;
   
}

.year, .cordenates{
    font-size: .7rem;
    opacity: .6;
}

.year{
  padding-bottom: 0;
}

.cordenates {
    padding: 0 24px;
}


/*----------backtotop----------*/
.backtotop{
    display: flex;
    align-items: center;
    gap: 24px;
    opacity: .5;
}

.backtotop:hover{
    opacity: 1;
}

.cta{
    font-size: .75rem;
    color: var(--main-color);
}

@media only screen and (max-width: 900px) {
 .lastline{
    flex-direction:column-reverse;
    align-items: center;
    justify-content: flex-end;
    height: 150px;
}

#footer-pattern {
    width: 160%;
}

.copyright {
display: none;
}}

</style>