<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_147)">
<path d="M14.3988 3.99383C11.8648 4.19008 9.82211 6.23776 9.63326 8.77174C9.57155 9.59377 9.70362 10.3775 9.98627 11.086L4.03579 17.0353C4.01234 17.0587 4 17.0896 4 17.1229V19.9C4 19.9679 4.05554 20.0235 4.12343 20.0235H6.77713C6.80923 20.0235 6.84008 20.0111 6.86353 19.9889L8.83962 18.0609C8.88899 18.0128 8.88899 17.9338 8.83962 17.8857L8.36195 17.408C8.31382 17.3599 8.31382 17.2821 8.36195 17.234L8.58536 17.0106C8.6335 16.9624 8.71126 16.9624 8.75939 17.0106L9.23336 17.4845C9.28149 17.5327 9.35925 17.5327 9.40739 17.4845L11.0823 15.8096C11.1304 15.7615 11.1304 15.6837 11.0823 15.6356L10.6083 15.1616C10.5602 15.1135 10.5602 15.0357 10.6083 14.9876L10.7886 14.8074C10.8367 14.7592 10.9144 14.7592 10.9626 14.8074L11.4366 15.2813C11.4847 15.3295 11.5624 15.3295 11.6106 15.2813L12.8942 13.9977C13.4867 14.2322 14.131 14.3618 14.8061 14.3618C17.6684 14.3618 19.9975 12.0339 19.9975 9.17041C19.9975 6.30688 17.445 3.75808 14.3988 3.99383ZM14.8086 13.2448C14.4531 13.2448 14.1075 13.1991 13.7792 13.1127C13.4595 13.0288 13.1534 12.9078 12.8683 12.7523C12.8202 12.7264 12.7609 12.7338 12.7227 12.772L11.9784 13.5163L11.6131 13.8817C11.5649 13.9298 11.4872 13.9298 11.439 13.8817L11.228 13.6706C11.0354 13.4781 10.7231 13.4781 10.5294 13.6706L9.47774 14.7222C9.2852 14.9148 9.2852 15.227 9.47774 15.4208L9.68881 15.6319C9.73694 15.68 9.73694 15.7578 9.68881 15.8059L9.40986 16.0849C9.36172 16.133 9.28396 16.133 9.23582 16.0849L9.02476 15.8738C8.83221 15.6812 8.51994 15.6812 8.32616 15.8738L7.23135 16.9686C7.0388 17.1612 7.0388 17.4747 7.23135 17.6672L7.43748 17.8733C7.48561 17.9215 7.48561 18.0005 7.43748 18.0486L6.42783 19.0336H4.98989V17.4796L10.4664 12.0031L11.2107 11.2588C11.2489 11.2206 11.2576 11.1601 11.2304 11.1119C11.0749 10.8281 10.9515 10.5244 10.8675 10.2035C10.7811 9.87148 10.7342 9.52341 10.7342 9.16547C10.7342 6.82034 12.7264 4.93065 15.1073 5.09975C17.1155 5.24292 18.741 6.87094 18.878 8.88035C19.041 11.2588 17.1537 13.2435 14.8111 13.2435L14.8086 13.2448Z"/>
<path d="M16.0441 6.61174C14.8814 6.46239 13.8977 7.44488 14.0458 8.60757C14.1458 9.39998 14.7852 10.0393 15.5776 10.1406C16.7403 10.2899 17.7227 9.30864 17.5771 8.14718C17.4771 7.35478 16.8378 6.71418 16.0454 6.61297L16.0441 6.61174ZM15.8108 9.16794C15.369 9.16794 15.0098 8.80382 15.0184 8.36071C15.0271 7.94106 15.3825 7.58929 15.8022 7.58435C16.2428 7.57941 16.6033 7.93736 16.6033 8.37676C16.6033 8.81617 16.2478 9.16794 15.8108 9.16794Z"/>
</g>
<defs>
<clipPath id="clip0_1_147">
<rect width="16" height="16.0444" fill="white" transform="translate(4 3.97778)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>