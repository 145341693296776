<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11_38)">
<path d="M3.01886 12.5877C2.67149 7.16307 7.16307 2.67148 12.5877 3.01885C17.0712 3.30564 20.6944 6.92745 20.9812 11.411C21.3285 16.8356 16.8369 21.3272 11.4123 20.9798C6.92745 20.6944 3.30564 17.0726 3.01886 12.5877ZM19.9054 12.5177C20.211 7.74202 16.2566 3.78899 11.481 4.09328C7.53333 4.34505 4.34506 7.53467 4.09328 11.481C3.78765 16.2566 7.74202 20.2097 12.5177 19.9054C16.4653 19.6536 19.6536 16.464 19.9067 12.5163L19.9054 12.5177Z"/>
<path d="M12.5581 10.8607V15.9393C12.5581 16.1938 12.3171 16.4 12.0195 16.4C11.722 16.4 11.481 16.1938 11.481 15.9393V10.8607C11.481 10.6062 11.722 10.4 12.0195 10.4C12.3171 10.4 12.5581 10.6062 12.5581 10.8607Z"/>
<path d="M12 9.38C11.6685 9.38 11.4 9.11547 11.4 8.78887V8.77113C11.4 8.44453 11.6685 8.18 12 8.18C12.3315 8.18 12.6 8.44453 12.6 8.77113V8.78887C12.6 9.11547 12.3315 9.38 12 9.38Z"/>
</g>
<defs>
<clipPath id="clip0_11_38">
<rect width="18" height="18" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>