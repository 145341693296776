<template>
    <div class="tile grid"   v-motion-slide-visible-once-bottom>
        <div class="center row">
            <figure>
                <figcaption>[01] - Discover tab</figcaption>
                <img src="@/assets/case02/mk1.png" alt="">
            </figure> 
            <div class="column">
                <figure>
                    <figcaption>[02] - Search</figcaption>
                    <img src="@/assets/case02/mk2.png" alt="search bar">
                </figure>
                <figure>
                    <img src="@/assets/case02/mk3.png" alt="search bar componet variations"></figure>
            </div>
        </div>
     </div>   
    <div class="tile grid"   v-motion-slide-visible-once-bottom>
        <div class="lspace"></div>
        <div class="half2">
            <p class="text-block">[01] [02] - We chose to keep the search bar at the top of the page keeping this feature as still an integral part of this tab, but the content browsing and search experience aims also to help people browse brands and navigate them to a specific categories of interest, getting users stimulated by the new content and instead of just coming back to things they are familiar with.</p>
        </div>
        <div class="lspace"></div>
    </div>  

    <div class="tile grid"   v-motion-slide-visible-once-bottom>

        <div class="center row">
           <figure>
                <figcaption>[03] - Banners</figcaption>
                <img src="@/assets/case02/mk4.png" alt="">
            </figure>
           <figure>
                <figcaption>[04] - Brand card</figcaption>
                <img src="@/assets/case02/mk5.png" alt="brand card detail">
            </figure>
        </div>
    </div>    

    <div class="tile grid"   v-motion-slide-visible-once-bottom>

        <div class="lspace"></div>
        <div class="half2">
            <p class="text-block">[03] - We incorporated the banner as an optional feature, which may or may not be present on this page. The main objective would be to highlight relevant offers for each user, based on their past activities or location.</p>
        </div>

        <div class="half2">
            <p class="text-block">[04] - By adding the slogan, we provide an indicator of each brand's segment, without the need to enter each one individually.</p>
        </div>
        <div class="lspace"></div>
      </div>     

      <div class="tile grid"   v-motion-slide-visible-once-bottom> 

        <figure class="center"><img src="@/assets/case02/mk6.png" alt=""></figure>
      </div>    
       <div class="tile grid"   v-motion-slide-visible-once-bottom>  
        <div class="lspace"></div>
        <div class="center"><img src="@/assets/case02/screems.png" alt="all screems"></div>
      </div>    
        
</template>
<script>
import ArrowRight from '@/assets/navigationicons/Arrowright.vue';
import '@/assets/caseslayout.css'

export default {
    components: {
        ArrowRight,
    }
}
</script>

<style scoped>


</style>