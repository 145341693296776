<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_172)">
<path d="M18.9976 9.8597C18.9489 10.1244 18.7322 10.3382 18.4693 10.3879C17.9933 10.479 17.5831 10.0686 17.6744 9.59619C17.7255 9.33389 17.9397 9.11773 18.2039 9.06794C18.6774 8.97687 19.0889 9.38732 18.9963 9.8597H18.9976Z"/>
<path d="M19.5393 7.33141H15.7401L14.5301 5.45159C14.395 5.24273 14.1649 5.11765 13.9154 5.11765H10.1333C9.88375 5.11765 9.65368 5.24151 9.51856 5.45159L8.30615 7.33141H4.46074C3.65612 7.33141 3 7.98595 3 8.78864V17.4251C3 18.229 3.65612 18.8824 4.46074 18.8824H19.5393C20.3451 18.8824 21 18.229 21 17.4251V8.78864C21 7.98595 20.3451 7.33141 19.5393 7.33141ZM20.0262 17.4251C20.0262 17.6935 19.8083 17.9109 19.5393 17.9109H4.46074C4.19294 17.9109 3.97383 17.6935 3.97383 17.4251V8.78864C3.97383 8.52148 4.19294 8.3029 4.46074 8.3029H8.83688L9.46379 7.33141L10.266 6.08913H13.7827L14.5825 7.33141L15.2082 8.3029H19.5393C19.8083 8.3029 20.0262 8.52148 20.0262 8.78864V17.4251Z"/>
<path d="M12.0213 17.1725C9.51979 17.1725 7.48328 15.1421 7.48328 12.6454C7.48328 10.1487 9.51858 8.11832 12.0213 8.11832C14.5241 8.11832 16.5594 10.1487 16.5594 12.6454C16.5594 15.1421 14.5241 17.1725 12.0213 17.1725ZM12.0213 9.09101C10.0566 9.09101 8.4571 10.6855 8.4571 12.6466C8.4571 14.6078 10.0554 16.2023 12.0213 16.2023C13.9872 16.2023 15.5855 14.6078 15.5855 12.6466C15.5855 10.6855 13.9872 9.09101 12.0213 9.09101Z"/>
</g>
<defs>
<clipPath id="clip0_1_172">
<rect width="18" height="13.7647" fill="white" transform="translate(3 5.11765)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>