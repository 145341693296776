<template>
  <div class="about" ref="animationtarget">
      <div class="photo" >
        <img src="@/assets/images/photo1.png" alt="">
      </div>
 
      <div class="abouttext" > I enjoy doing things to people, useful things that make them feel better about themselves, empowering people to navigate their lives with confidence, or just having a little fun. As a designer, I found a way to do this, helping to build products with the potential to make life easier.  I started as a Visual Designer and have spent the last years honing my skills in UI/UX design. I’m into anything that could merge design, tech and complex chalenges. </div>
      <div class="social-links" >
          <a href="https://www.linkedin.com/in/jessicadiogo/" target="_blank" rel="external">Linkedin</a>
          <a href="https://dribbble.com/Jessicacfd" target="_blank" rel="external">Dribbble</a>
          <a href="https://codesandbox.io/u/jessicacfd" target="_blank" rel="external">Codeandbox</a>
      </div>
  </div>

</template>
<script setup>
import { ref } from 'vue'
import { useMotion } from '@vueuse/motion'

const animationtarget = ref()

useMotion(animationtarget, {
  initial: {
    opacity: 0,

    y:-200,
    
  },
  visibleOnce: {
    opacity: 1,
    y: 0,

  },
  enter: { transition: {
      delay: 1000,
    },
}
})

</script>


<style scoped>
.about {
  display: grid;
  grid-template-columns: var(--80X12);
  width: 100%;
  padding-top: 10%;
  align-self: center;
    margin-left: 58px;
  /* mix-blend-mode: luminosity !important;  */

}

.photo {
    grid-column: 2 / span 2;
    filter: grayscale(1)


}


.abouttext {
 grid-column: 8 / span 6;
 font-size: 0.75rem;
 font-weight: 300;
 line-height: 200%; /* 1.5975rem */
 letter-spacing: 0.0375rem;
 padding: 1.5rem;
}

.social-links {
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.06rem;
  line-height: 1.4rem;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  grid-column: 2 / span 2;
  padding: 48px 0;
}

.social-links a {
  text-decoration: none;
  color: var(--main-color);
  transition: 1s;
}

.social-links a:hover {
  font-weight: 600;
  font-size: 0.9rem;
}


@media only screen and (max-width: 900px) {

.photo {
    grid-column: 2 / span 4;
    mix-blend-mode: luminosity !important; 
}

.abouttext {
 padding:  0;

}

.social-links {
  line-height: 2rem;

}}



@media only screen and (max-width: 700px) {

.photo {
    grid-column: 1 / span 8;
    mix-blend-mode: luminosity !important; 
}


.abouttext {
 grid-column: 2 / span 13;
 padding: 2rem 0;

}}


</style>