<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5_829)">
<path d="M14.3411 11.8442L10.189 15.8511C9.96766 16.0626 9.61237 16.0626 9.39242 15.8511C9.17248 15.6396 9.17248 15.2956 9.39242 15.0827L11.9866 12.5802C12.0754 12.4942 12.012 12.3489 11.8865 12.3489H2.91682C2.60665 12.3489 2.35428 12.1064 2.35428 11.8061C2.35428 11.5058 2.60665 11.2633 2.91682 11.2633H11.809C11.9344 11.2633 11.9965 11.1167 11.9091 11.0321L9.38679 8.59724C9.16684 8.38576 9.16684 8.04175 9.38679 7.83026C9.49676 7.72452 9.64056 7.67095 9.78437 7.67095C9.92818 7.67095 10.072 7.72452 10.182 7.83026L14.3411 11.8442Z" />
<path d="M5.08805 9.00752C5.26852 8.9765 5.42502 8.86089 5.50256 8.69453C5.78877 8.07559 6.16097 7.48202 6.6206 6.93076C9.13724 3.91643 13.5643 3.23545 16.8747 5.34746C20.8421 7.8782 21.7289 13.3091 18.7428 16.9706C16.2586 20.0159 11.84 20.7505 8.50279 18.6751C7.03228 17.7601 5.97487 16.4489 5.37285 14.9727C5.27275 14.7274 5.01756 14.5822 4.75673 14.6273C4.40003 14.6879 4.19278 15.063 4.32812 15.3971C4.99641 17.0368 6.15534 18.5003 7.76542 19.5436C11.8386 22.1829 17.1933 21.1466 19.9976 17.1863C22.9273 13.0454 21.8544 7.25221 17.5655 4.45218C13.5601 1.83544 8.13481 2.85337 5.34465 6.7362C5.00346 7.21133 4.71584 7.70761 4.47898 8.2208C4.29006 8.62967 4.64394 9.08365 5.08805 9.00752Z" />
</g>
<defs>
<clipPath id="clip0_5_829">
<rect width="19.2915" height="18" transform="translate(2.35428 3)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>