<template>
    <div class="project-container">
    <div class="navigation">  
          <Goback @click="$router.push({name: 'archieve'})"/>  
          <div class="arrows">
            <Previous @click="$router.push({name: 'blm'})"/>
            <Next @click="$router.push({name: 'icl'})"/> 
          </div>  
        </div>      
          
        <div class="projectwrap" >
            <div class="info">          
                <h1> Interactive Graphic Production Book </h1>
                <p class="note">This project is the Final Course Assignment of our Graphic Design Bachelor Degree. Our dissertation focused on creating a Graphic Production Experimental Book that would enhance the user\'\s learning experience. We used mobile graphics and infographics to achieve this goal. Focusing on user experience, the main idea behind the book was to make technical content easier to understand by using Pop-Up and 3D Interactive Devices. By presenting information in a playful and engaging way, we hoped to spark the reader\'\s interest and facilitate learning.</p>   
            </div>      
            <div class="filegallery">  
                <div @click="toggleModal(image)"  v-for="image in images" :key="image">
                    <img class="figure" :src="image"/> 
                </div>
            </div>
       </div>
  
      <div class="modalwrap" v-if="showModal">
        <Modal @close="toggleModal" :image="currentImage" />
      </div>
  </div>
</template>

<script>
import Goback from '@/components/Goback-button.vue';
import Next from '@/components/Next-button.vue';
import Previous from '@/components/Previous-button.vue';
import Modal from '@/components/modal.vue';


//INT 1
import INT1 from '@/assets/images/visualjournal/INT/cronograma.png';
import INT2 from '@/assets/images/visualjournal/INT/moodboard-l.png';
import INT3 from '@/assets/images/visualjournal/INT/ideate-l.png';
import INT4 from '@/assets/images/visualjournal/INT/IMG9121.jpg';
import INT5 from '@/assets/images/visualjournal/INT/IMG_9124.jpg';
import INT6 from '@/assets/images/visualjournal/INT/03-Disco-l.jpg';
import INT7 from '@/assets/images/visualjournal/INT/04 Reticula.jpg';
import INT8 from '@/assets/images/visualjournal/INT/05-Pantone-xl.jpg';
import INT9 from '@/assets/images/visualjournal/INT/06 Medida.jpg';
import INT10 from '@/assets/images/visualjournal/INT/07-Fontes-xl.jpg';
import INT11 from '@/assets/images/visualjournal/INT/50_Kerning-l.jpg';
import INT12 from '@/assets/images/visualjournal/INT/08 Acabamentos.jpg';
import INT13 from '@/assets/images/visualjournal/INT/09 Encadernacoes.jpg';
import INT14 from '@/assets/images/visualjournal/INT/55_Tipos de papel.jpg';
import INT15 from '@/assets/images/visualjournal/INT/45_Moiree.jpg';
import INT16 from '@/assets/images/visualjournal/INT/48_Composicao.jpg';
import INT17 from '@/assets/images/visualjournal/INT/51_Entrelinha.jpg';
import INT18 from '@/assets/images/visualjournal/INT/56_Refile.jpg';
import INT19 from '@/assets/images/visualjournal/INT/IMG_2740.jpg';
import INT20 from '@/assets/images/visualjournal/INT/43_Sintese.jpg';
import INT21 from '@/assets/images/visualjournal/INT/bienal.jpeg';


export default { 
    components: { Goback, Next, Previous, Modal },

    data () {
         return {
            images:  [INT1, INT2, INT3, INT4, INT5, INT6, INT7, INT8, INT9, INT10, INT11, INT12, INT13, INT14, INT15, INT16, INT17, INT18,  INT19, INT20, INT21,],
            showModal: false,
            currentImage: {}
            }
    },
    methods: {
      toggleModal(image) {
        this.showModal = !this.showModal;
        this.currentImage = image;
      } 
    }
}
</script>

<style>
* {
  box-sizing: border-box;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
}

.arrows {
  display: flex;
  align-items: center;
}

.project-container {
  position: relative;
    width: calc(100vw - 58px);
    margin-left: 58px;
    padding: 20px;
    background: var(--background);
    border-radius: 4px;
    min-height: 100vh;
}

.projectwrap {
  padding: 10px 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h1 {
    font-size: 1rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 140%;
    padding: 0;
}
.info {
    padding-right: 24px ;
    width: 320px;

}
 .info p {
    font-size: .65rem;
    line-height: 1.6;
}

.filegallery {
    width: 75%;
    columns: 6 210px;
    gap: 0;
}

.figure{ 
  display: flex;
  padding: 8px;
  object-fit: contain;
  object-position: left top;
}

.modalwrap { 
  position: absolute;
  z-index: 90;
  width: calc(100% - 58px);
  height: 100%;
  top: 0;
  margin: 0;
  }


@media only screen and (min-width: 2001px) {
  .projectwrap {
  padding: 10px 10%;
  } 

  .navigation {
  padding: 0 9.5%;
  }

  .info {
        width: 340px;
  }
} 

@media only screen and (max-width: 1024px) { 

  .projectwrap {
  padding: 10px 16px;
  flex-direction: column;
  margin: 2%;
  height: auto;
  }

  .info {
  padding: 0 40px 24px 6px;
  min-width: 80vw;
  padding: 0 ;
  }


  .filegallery {
     width: 100%;
    columns: 3 160px;
  }
  .figure{ 
  padding: 4px;
  }

  .project-container {
    padding: 0;
  }  

  .projectwrap {
  padding: 10px 0;
  }

  .navigation {
    padding:0 20px 0 0;
  }

  .filegallery {
    columns: 2 120px;
  }

  .info {
    padding: 0 6% 48px 6% ;
  }
    .info p {
  min-width: 65vw;

  } 
  .modalwrap{
    width: calc(100% - 58px);
  }

    
}


</style>