<template>    

      <div class="sidemenuinner"  @click.self="closeSideMenu"  >
            <div class="wrap">
                <div class="column "><Logo id="logo"/></div>
                <nav class="column">
                    <router-link to="/" @click="closeSideMenu">Case Studies</router-link> 
                    <router-link to="/archieve" @click="closeSideMenu">Archieve</router-link > 
                    <router-link to="/about" @click="closeSideMenu">About</router-link>
                </nav>
                <div class="social column">
                    <div>   
                        <MenuTrigger class="trigger" @click="closeSideMenu" />
                        <ul class="social-media">                
                            <a href="https://www.linkedin.com/in/jessicadiogo/"
                                    target="_blank"
                                    rel="external"><div class="externallink" @click="closeSideMenu">LinkedIn</div></a>
                            <a href="https://dribbble.com/Jessicacfd"
                                    target="_blank"
                                    rel="external" ><div  class="externallink" @click="closeSideMenu">Dribbble</div></a>  
                            <a href="https://codesandbox.io/u/jessicacfd" target="_blank" rel="external"><div  class="externallink" @click="closeSideMenu">Codeandbox</div></a>        
                        </ul>   
                    </div> 
                    <div class="contact">
            
                        <p id="getintouch">If you want to chat about a project, collaborations, or simply want to connect, feel free to say hello, or schedule a meeting with me <a id="inlinelink" href="https://calendly.com/jessica-cfdiogo/30min?month=2022-09"
                            target="_blank"> here</a>.</p>  

                        <a class="button" id="inlinelink" href="mailto:jessica.cfdiogo@gmail.com?cc=jessica.cfd1@gmail.com">
                            <span class="button-text">Get in touch</span>
                        </a>      
                    </div>
                </div>    
            </div>
        </div>
</template>
 <script>
 import MenuTrigger from '../assets/MenuTrigger.vue'
 import Logo from '../assets/Logosvg.vue'

 export default {
  name: 'Sidemenu',
  components: {
    Logo, MenuTrigger }, 

methods: {
   closeSideMenu() {
   this.$emit('close');
  },
 }
}

</script>
 
<style scoped>

.sidemenuinner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100dvh;
  max-height: 100vh;
}

.wrap { 
  background-color:var(--surface) ;
  backdrop-filter: blur(8.5px);
  display: flex;
  flex-direction: row;
  width: 72.5%;
  padding: 1%;
  box-shadow: 25px 10px 51.3px -2px rgba(0, 0, 0, 0.25);
}

.column{
    width: 33%;
}

 #logo {
    padding: 2%
}

nav, .social {
    border-left-style: solid;
    border-width: .7px;
    border-color: var(--secondary-color);   
}

nav {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 6% 2%;
}

a {
    text-decoration: none;
}

nav a {
    padding: 6px 20px ;
    font-weight: 500;
}

nav a:hover {
    font-weight: 700;
} 

.externallink {
    font-weight: 300;
    font-size: .8rem;
    padding: 8px 20px ;
}

.externallink:hover {
    font-weight: 500;
}

.router-link-exact-active {
    text-decoration: line-through
}

.social {
    justify-content: space-between;
}

.social div {
    display: flex;
    flex-direction: column;
}

.contact {
    padding: 12% 18%;
}

.contact a {
    font-weight: 700;
}

.trigger {
    align-self: flex-end;
}

/*---button---*/

.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 10px 16px;
     margin: 16px 0 0 0;
    background: transparent;
    border-radius: 2.2rem;
    border: solid .7px;
    transition: all 500ms;
}

.button-text {
   color: var(--main-color);
   text-transform: uppercase;
   text-align: center;
   font-weight: 700;
   font-size: .65rem;
   padding: 0.75rem 0;
   display: flow;
}

.button:hover {
    background: var(--main-color);
}

.button:hover .button-text  {
    color: var(--OnNegativeSurface)
}

/*----------Animation----------*/

.slide-enter-active {
  animation: slideIn 800ms;
}

.slide-leave-active {
  animation: slideOut 800ms;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(+100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideOut {
  from {
    opacity: 0;
    transform: translateX(0);
  }
  to {
    opacity: 1;
    transform: translateX(+100vw);
  }
}

@media only screen and (max-width: 900px) {
    .wrap { 
        width: 100%;
 }
}

@media only screen and (max-width: 720px) {
    .wrap { 
         flex-direction: column;
 }
    .column {
    width: 100%;
 }
    .trigger{
    position: absolute;
    top: 2px;
 }

 #logo {
    padding: 4%
 }

 .social {
    max-height: 60%;
 }


nav, .social {
    border-left-style: none;
 }

nav a {
    padding: 10px 20px ;
 }

ul { padding: 0;
 }

.externallink {
    padding: 12px 28px ;
 }

.contact {
    padding: 12% 8%;
 }
}

</style>