<template>
<div>
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="arrowwrap">
<path id="arrow" d="M30.9783 12.4985L30.488 29.6087C30.4804 29.8744 30.3662 30.1177 30.1857 30.2982C30.0052 30.4787 29.762 30.5929 29.4962 30.6005C28.9647 30.6157 28.5461 30.1971 28.5597 29.6639L28.9473 16.1935C28.9499 16.1031 28.8406 16.0559 28.7766 16.1199L13.2081 31.6885L11.8843 30.3648L27.5546 14.6945C27.6186 14.6305 27.5715 14.5212 27.481 14.5238L13.8266 14.9134C13.2934 14.9304 12.8747 14.5117 12.8917 13.9785C12.9053 13.4453 13.3486 13.002 13.8818 12.9884L30.9783 12.4985Z" fill="black"/>
</g>
</svg>

</div>
</template>
<style>

#arrowwrap  { min-width: 100%;} 
.arrow {
    fill:var(--main-color);
    
    }

</style>