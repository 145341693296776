<template>
    <div class="caseLiber">
        <Intro class="intro" v-for="intro in intros" v-bind="intro" :key="intro.title"  :class="{ 'intro--hidden': !showIntro }" />  
        <div class="intro-space"></div>  
        <Container>    
            <CaseCover/>
            <Discover/>
             
        </Container>   
    </div>
    <Footer/>
</template>

<script>
import Container from '@/components/Container.vue'
import Footer from '@/components/Footer.vue'
import CaseCover from '@/components/CaseCover.vue'
import Intro from '@/components/CaseComponents/Intros.vue'
import Discover from '@/assets/case03/LiberDiscover.vue'


export default {
  components: {
   Container, CaseCover, Intro, Footer, Discover,
},
 data () {
    return {
      intros : [
        {
          id: '[03]', 
          title: 'Online catalog for modern furniture store', 

           
          productbrief: 'Liber, a unique brand, store, and factory specializing in custom furniture, in Goiás, Brazil, strives to add personality physical spaces of everyday life.',
          challenge: 'Present an extensive range of products more effectively, valuing the product, ensuring better options for customers, and avoiding products stuck in stock. Build a foundation for the company\'\s future aspirations for e-commerce.',
          basics: 'Concept & design',
        },
      ],
     
      showIntro: true,
      lastScrollPosition: 700
    }
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
  onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    this.showIntro = currentScrollPosition < 700;
  }
}

}

</script>


<style scoped>

* {  max-width: 100%;
    box-sizing: border-box;
}

.caseLiber {
    position: relative;
    overflow: hidden;
    margin-left: 58px;
}

.intro {
  z-index: -2;
  position: fixed ;
}

.intro-space {
  min-height: 85vh;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .intro-space {
  min-height: 110vh;

  }

}
</style>