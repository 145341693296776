<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_111)">
<path d="M12.5224 19.9832C7.7005 20.2908 3.70798 16.2995 4.01676 11.4776C4.27168 7.49106 7.49106 4.27168 11.4764 4.01676C16.2983 3.70798 20.2908 7.7005 19.982 12.5224C19.7271 16.5077 16.5077 19.7283 12.5212 19.982L12.5224 19.9832ZM12.4602 4.973C8.21513 4.70133 4.70133 8.21513 4.9718 12.4602C5.1956 15.9692 8.03082 18.8032 11.5386 19.027C15.7837 19.2987 19.2975 15.7837 19.027 11.5386C18.8032 8.02962 15.968 5.1956 12.459 4.97061L12.4602 4.973Z" fill="#2A2A46"/>
<path d="M10.728 15.9345C10.6406 15.9345 10.5532 15.9153 10.473 15.877C10.2636 15.7777 10.1296 15.5647 10.1308 15.3325L10.1631 8.87575C10.1631 8.64237 10.2959 8.43532 10.5078 8.33719C10.7196 8.23785 10.9625 8.26897 11.1433 8.41857L15.0233 11.6308C15.1609 11.7445 15.2399 11.912 15.2399 12.0915C15.2399 12.2699 15.1609 12.4386 15.0233 12.5523L11.1109 15.7968C11.002 15.8878 10.8656 15.9345 10.728 15.9345ZM11.1157 9.6393L11.0906 14.5689L14.0778 12.0915L11.1157 9.6393Z" fill="#2A2A46"/>
</g>
<defs>
<clipPath id="clip0_1_111">
<rect width="15.9989" height="15.9999" fill="white" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>