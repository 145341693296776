<template>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_194)">
            <path d="M11.9722 17.0518C9.18711 17.0518 6.91971 14.7858 6.91971 11.9993C6.91971 9.21285 9.18575 6.9468 11.9722 6.9468C14.7587 6.9468 17.0247 9.21285 17.0247 11.9993C17.0247 14.7858 14.7587 17.0518 11.9722 17.0518ZM11.9722 8.03239C9.78479 8.03239 8.00394 9.81189 8.00394 12.0007C8.00394 14.1895 9.78343 15.969 11.9722 15.969C14.161 15.969 15.9405 14.1895 15.9405 12.0007C15.9405 9.81189 14.161 8.03239 11.9722 8.03239Z" />
            <path d="M12.5143 5.99268L11.4301 5.98455L11.4545 2.53805C11.4572 2.23853 11.7012 1.99729 12.0007 2C12.3002 2.00271 12.5415 2.24666 12.5387 2.54618L12.5143 5.99268Z" />
            <path d="M11.9722 22C11.6727 21.9973 11.4315 21.7533 11.4342 21.4538L11.4586 18.0073L12.5428 18.0154L12.5184 21.4619C12.5157 21.7615 12.2717 22.0027 11.9722 22Z" />
            <path d="M18.2946 19.0753L15.8755 16.6209L16.648 15.8592L19.0672 18.3136C19.2772 18.5264 19.2745 18.8706 19.0617 19.0807C18.849 19.2908 18.5047 19.2881 18.2946 19.0753Z" />
            <path d="M7.35338 8.13946L4.93419 5.68503C4.72412 5.47225 4.72683 5.12801 4.93961 4.91794C5.15239 4.70787 5.49664 4.71058 5.70671 4.92336L8.1259 7.37779L7.35338 8.13946Z" />
            <path d="M16.6602 8.10558L15.8985 7.33306L18.3529 4.91387C18.5657 4.7038 18.91 4.70651 19.12 4.91929C19.3301 5.13207 19.3274 5.47632 19.1146 5.68639L16.6602 8.10558Z" />
            <path d="M4.95723 19.0414C4.74716 18.8286 4.74987 18.4844 4.96266 18.2743L7.41708 15.8551L8.17876 16.6276L5.72433 19.0468C5.51155 19.2569 5.1673 19.2542 4.95723 19.0414Z" />
            <path d="M21.4538 12.5726L18.0073 12.5482L18.0155 11.464L21.462 11.4884C21.7615 11.4911 22.0027 11.735 22 12.0346C21.9973 12.3341 21.7533 12.5753 21.4538 12.5726Z" />
            <path d="M5.98456 12.5767L2.53806 12.5523C2.23854 12.5496 1.9973 12.3056 2.00001 12.0061C2.00272 11.7066 2.24667 11.4653 2.54619 11.468L5.99269 11.4924L5.98456 12.5767Z" />
        </g>
        <defs>
        <clipPath id="clip0_1_194">
        <rect width="20" height="20" transform="translate(2 2)"/>
        </clipPath>
        </defs>
    </svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>