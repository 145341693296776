<template>
  <div class="tile">
    <div class="cover-container">
      <div class="zoom-out" ref=zoom></div>
    </div>

  </div>
</template>

// <script setup>
import { ref } from 'vue'
import { useMotion } from '@vueuse/motion'

const zoom = ref()

useMotion(zoom, {
  initial: {

    scale: 1.4,
  },

  visibleOnce: {
    scale: 1,
  },

  enter: { transition: {
      delay: 1200,
      damping: 25,
    },
}
})

</script>


<script>
// import Cover01 from '@/assets/case01/Cover.png';
// import Cover02 from '@/assets/case02/Cover.png';
// import Cover03 from '@/assets/images/case03/Cover.png';


export default { }

</script>

<style scoped>

* { 
    max-width: 100%;
}

.tile {
  width: 100%;
}

.cover-container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    z-index: 2;
    isolation: isolate;
    position: sticky;
}

.cover-container:hover{
  box-shadow: 15px -15px 55.2px -13px rgba(0, 0, 0, 0.10);
}

.zoom-out {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: sticky;
  will-change: transform;
  transform: scale(1.2);
  transition: transform 0.15s linear;
}


.caseIis .container .tile .cover-container .zoom-out {
  background-image: url("../assets/case01/Cover.png");
}

.caseBits .container .tile .cover-container .zoom-out {
  background-image: url("../assets/case02/Cover.png");

}

.caseLiber .container .tile .cover-container .zoom-out {
  background-image: url("../assets/case03/Cover.png");

}
</style>