<template>
    <div class="container">
        <button>
            <svg class="iconshape" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 7.49763L8.93831 14.8292C8.82863 14.9431 8.6852 15 8.54037 15C8.39554 15 8.25211 14.9431 8.14243 14.8292C7.92307 14.6014 7.92307 14.2321 8.14243 14.0029L13.7935 8.13727C13.7984 8.13226 13.7948 8.12392 13.7879 8.12392L0.583957 8.12392C0.261447 8.12392 0 7.86247 0 7.53996V7.53996C0 7.21746 0.261446 6.95601 0.583956 6.95601L13.8693 6.95601C13.8763 6.95601 13.8799 6.94762 13.875 6.94259L8.14946 0.99674C7.92869 0.768997 7.92869 0.399645 8.14946 0.171902C8.36882 -0.0573007 8.72457 -0.0573007 8.94393 0.171902L16 7.49763Z"/>
            </svg>
        </button>
    </div>
</template>
<style scoped>

* {
    box-sizing: border-box;

}  

.container {
    display: flex;
    align-self: center;
    justify-self: center;
    padding: 40px 10px 40px 10px;
    max-width: fit-content;
}


button {
    position: relative;
    cursor: pointer;
    background: transparent;
    border-radius: 2.2rem;
    border: solid .7px;
    transition: all 500ms;
    padding: 8px 8px;
    height: 32px;
    width: 32px;
}

.iconshape {
    fill: var(--main-color);
}

button:hover {
    background: var(--main-color);
}

button:hover .iconshape  {
    fill: var(--OnNegativeSurface)
}



</style>
