<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_40)">
<path d="M5.17274 20.9062C5.08396 20.9062 4.99518 20.8888 4.91043 20.8538C4.66023 20.7488 4.49881 20.5051 4.49881 20.2332V4.44251C4.50015 3.70083 5.10279 3.09644 5.84533 3.09644L18.155 3.09375C18.5141 3.09375 18.8518 3.23374 19.106 3.48815C19.3603 3.74255 19.5002 4.08042 19.5002 4.43982V20.2319C19.5002 20.5038 19.3387 20.7474 19.0885 20.8524C18.8383 20.9574 18.5518 20.9022 18.3568 20.7124L12.0237 14.499L5.64221 20.7151C5.51441 20.8403 5.34492 20.9062 5.17409 20.9062H5.17274ZM18.155 4.1706L5.84533 4.1733C5.69736 4.1733 5.57629 4.29444 5.57629 4.44251V19.2762L11.4601 13.5446C11.7708 13.2417 12.2779 13.2417 12.5887 13.546L18.424 19.2708V4.43982C18.424 4.3429 18.3742 4.27829 18.3447 4.25002C18.3151 4.22175 18.2518 4.1706 18.155 4.1706Z" />
</g>
<defs>
<clipPath id="clip0_1_40">
<rect width="15" height="17.8125"  transform="translate(4.5 3.09375)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>