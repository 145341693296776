<template>

        <!--------overview------->
      <div class="tile grid"   v-motion-slide-visible-once-bottom>
          <div class="half2 textbox">
          <h2>Overview</h2>
          <p class="text-block half2">The core of Bits relies on the discovery it provides. As you make more transactions through the app, your rewards grow, and the discovery of new offers and brands available takes center stage in this experience. However, as the application scaled and incorporated new brands, the initial discovery process proposed by the application became problematic. Users found themselves scrolling through an overwhelming array of brands, making it harder to find offerings that truly resonated with their interests.</p>
          <div class="sspace"></div>
          <p class="highlight half2"> Acknowledging the need for more efficient data organization to cope with the increased volume, <span>the objective of this sprint was to directly address the pain points highlighted by our users.</span></p>
          </div>

      </div>
        <!--------userfeedback------->
        <div class="tile grid" v-motion-slide-visible-once-bottom>

          <img class="center" id="officepicture" src="@/assets/case02/BTS02.png" alt="">
          <!-- <div class="column8" id="userinsights"><img  src="@/assets/case02/BTS03.png" alt="post-its with user insights"></div> -->

          </div>
          <div class="tile grid" v-motion-slide-visible-once-bottom>
          <div class="textbox half2">
              <p class="highlight"> We’ve heard from users that the current discovery experience is frustrating due to the <span>abundance of unorganized and non-prioritized information.</span></p>
              <p>The discovery page should serve as a user-friendly gateway for finding both known brands and exciting offers tailored to the user's interests.</p><p>Nevertheless, it became evident that the screen fell short in effectively presenting crucial information and maintaining an organized structure. Users faced challenges, such as difficulty locating specific brands, unfamiliarity with available brands and their offerings, and, on occasion, this frustration resulted in users abandoning the process</p>
          </div>
        </div>
        <!--------BREAKDOWN OF THE CURRENT EXPERIENCE------->   

            <div class="division bleed ">    
              <div class="bleed grid" v-motion-slide-visible-once-bottom>
                <h2 id="currentexp" >BREAKDOWN OF THE CURRENT EXPERIENCE</h2>
                <p class="text-block" id="currentexp">Initially, the Overview tab had two primary sections: one focused on personal account status and the other dedicated to showcasing the brands. Meanwhile, the discovery tab worked more like a search and sort page, what created confusion among our users.</p>
              </div>
              <div class="mspace"></div>
              <div class="bleed grid" v-motion-slide-visible-once-bottom>
                <div class="columns-block">
                  <p class="painpoints">It's not possible to <span>compare brands or sort</span> them according to user interest.</p>
                  <p class="painpoints">Users are constantly having to scroll through lots of brands that don’t seem relevant to them.</p>
                  <p class="painpoints">It’s possible to search based on the brand names, but <span>don't by categories or services.</span></p>
                  <p class="painpoints">The use of high saturation colours in combination with low contrast impairs the readability.</p>
                  <p class="painpoints">The brands are currently presented on the overview page in a single list, competing for space with general user information.</p>
                  <p class="painpoints">The brand's offers/products have <span>no indication of the segment </span> they belong to (Every discovery just revolves around the brand name/ID)</p>
                </div>
              </div>
              <div class="bleed grid" v-motion-slide-visible-once-bottom>
                <div class="currentexpscreem"><img src="@/assets/case02/BTS04.png" alt=""></div>
              </div>
               <div class="bleed grid" v-motion-slide-visible-once-bottom>
                <div class="textbox text-block" id="currentexp">
                  <p>After reviewing our own experience, the next step involved a comprehensive analysis of how competitors and similar companies handled these issues. Other rewards and cashback apps were selected for relevance and key points were analyzed.</p>
                  <p>By identifying shared organizational structures and presenting effective solutions, we were able to establish a concise and solid foundation for Bits.</p>
                </div>
              </div>
              <div class="lspace"></div>
              <div class="bleed grid" v-motion-slide-visible-once-bottom>
                <div class="center"><img src="@/assets/case02/BTS07.png" alt=""></div>
              </div>  
            </div>     
</template>

<script>
import ArrowRight from '@/assets/navigationicons/Arrowright.vue';
import '@/assets/caseslayout.css'

export default {
    components: {
        ArrowRight,
    }
}
</script>

<style scoped>
* {
    max-width: 100%;
}



/*------Text-----*/


/*------Discover-----*/

/* #officepicture {
  grid-row: 6 / span 2; 
} */

.division {
  background-color: var(--surface);
  display:grid;
  grid-template-columns: var(--80X12);
  padding: 160px 0;
}

.columns-block {
  grid-column: 3 / span 12;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
}

.painpoints {
  padding: 0 48px 0 0;
  margin-bottom: 24px ;
}

#currentexp {
  grid-column: 3 / span 8;
  padding: 4px 0;
}  

.currentexpscreem {
  grid-column: 3 / span 8;
  transform: scale(.9);
  padding: 5rem 0;
}


@media only screen and (min-width: 1800px) {

}


@media only screen and (min-width: 2200px) {

}


@media only screen and (max-width: 900px) {
  
  .columns-block {
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: auto auto auto;
}

  .currentexpscreem {
  grid-column: 3 / span 10;
  transform: scale(1);

}

  /* .division {
  grid-template-columns: 1% repeat(12, 1fr) 1%;
}  */


}


@media only screen and (max-width: 700px) {


  .division {
  padding: 80px 0;
}

  #currentexp {
  grid-column: 3 / span 12;
  padding: 4px 0;
}  

  .currentexpscreem {
  grid-column: 3 / span 12;
  transform: scale(1);

}

  .columns-block {
   grid-template-columns: repeat(1, 1fr);
   grid-template-rows: auto auto auto auto auto auto;
}

}




</style>