<template>
    <h2>WORK - <span>Case Studies</span></h2>
    <ul>
      <li v-for="project in cases" :key="project.id">
        <a 
        @mouseover="hoverIn($event, project)"  
        @mouseout="hoverOut($event, project)"
        :href= "project.path">
          <Arrow class="ArrowCase"/>
          <h3> {{project.title}} </h3>
        </a>
      </li>
    </ul>
</template>
<script>
// @ is an alias to /src
import Cover01 from '@/assets/case01/gif-coveriis.gif'
import Cover02 from '@/assets/case02/gif-coverbits.gif'
import Cover03 from '@/assets/case03/gif-coverlib.gif'
import Cover04 from '@/assets/case04/gif-covereve.gif'
import Arrow from '@/assets/navigationicons/ArrowD.vue'

export default {
     components: {
    Arrow,
  },

    data () {
      return {
        cases: [
          {path:' ', id: '', number: '', title: '', role: ''},
          {path:'/project/project-eve ', id: 'eve', number: '04', title: 'supplier information request FOR AN EVENT MANAGEMENT PLATFORM', role: 'Strategy, concept & design', img:[Cover04]},
          {path:'/project/project-iis', id: 'iis', number: '03', title: 'How to grade feelings? - Core experience', role: 'Product Design, Strategy & Art Direction', img:[Cover01]},
          {path:'/project/project-bits', id: 'bits', number: '02', title: 'Discovery flow redesign for a rewards app', role:'Product Designer, UX Research & Test', img:[Cover02]},
          {path:'/project/project-liber', id: 'liber', number: '03', title: 'Online catalog for modern furniture store', role: 'Product Design & Art Direction', img:[Cover03]}
        ]
      };
    },
    methods: {
        hoverIn(event, project) {
            event.currentTarget.style.backgroundImage = 'url(' + project.img + ')' 
        },
        hoverOut(event) {
            event.currentTarget.style.backgroundImage = 'none' 
        }
   }
 }
// 
// Para imagem estática 
// :style="{ backgroundImage:  'url(' + project.img + ')' }"
</script>
<style scoped>

h2 { 
    font-size: .7rem;
    line-height: 100%;    
    padding: 7%;
}

h2 span {
  font-weight: 400;
  text-decoration: none;
}
ul {
    display: flex;
    flex-direction: row;
    margin: 0 ;
    flex-wrap: wrap;
    padding: 0 1%;
} 
 
li {
  list-style: none;
  flex: 0 0 calc(96% / 3);
  background: var(--gradient);
  overflow: hidden;
  margin: 0 0 8px 8px;
}

li:first-child {
  opacity: 0;
}

a {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  text-decoration: none;
  flex-wrap: wrap;
  aspect-ratio: 1 / 1;
  padding: 40px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 600ms linear;
}

li:hover {
  box-shadow: 25px 10px 51.3px -2px rgba(0, 0, 0, 0.2);
}


li:hover a {
  transform: scale(1.1);
  animation-delay: 4s;
} 

li:hover a h3, li:hover a .ArrowCase {
  opacity: 0;
} 

h3 {
  color:var(--main-color);
  font-weight: 400;
  font-size: .8rem;
  line-height: 1.1rem;
  text-transform: uppercase;
  margin: 0;
  max-width: 60%;
  padding: 5%;
}

.ArrowCase {
  align-self: flex-end;
}

@media only screen and (max-width: 900px) { 

  li {
    flex: 0 0 calc(96% / 2);
  }

  a {
    padding: 20px;
  }

  h3 {
    font-size: .7rem;
    max-width: 75%;
  }
}

@media only screen and (max-width: 720px) { 

h2 { 
  
    padding: 4% 12%;
}

li:first-child {
  display: none;
}

li {
  flex: 0 0 calc(96% / 1);
}}
/*
a:first-child {
    border-top: solid 1px;
    border-image-slice: 1;
    border-image-source: var(--gradient-border);
}

a{ 
    display: grid;
    grid-template-columns: repeat(12, 1fr); 
    border-bottom: solid 1px;
    border-image-slice: 1;
    border-image-source: var(--gradient-border);
    padding: 24px 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 80px;
    height: fit-content;
}


h3, .number {
  color:var(--main-color);
}

a:hover{
    animation: Foco 400ms linear;
    height: 240px;
    padding: 30px;
    box-shadow: 0px 180px 84.1px 0px rgba(22, 22, 22, 0.2) inset;
}

.animate{
    transition: all 500ms;
}

@keyframes Foco {
  from {
    height: 60px;
    padding: 24px 10px;

  }
  to {
    height: 240px;
    padding: 30px;
  }
}

a:hover > h3, a:hover > .number, a:hover > .role {
   color:#FEFEFE;
}

.number {
  font: .9rem 'Besley';
  font-weight: 700 ;
  grid-column: 1;
}

h3{
    grid-column: 2 / span 2;
    font-size:.9rem;
    font-weight: 300;
    width: 210px;
    padding: 0 24px;
    line-height: 1.25rem;
    }

.role{
     grid-column: 10 / span 2;
     font-weight: 700;
     font-size:.75rem;
     color:var(--main-color);
     text-transform: uppercase;
     padding: 0 24px;
     width: 160px;
     letter-spacing: .8px;
     line-height: 1.15rem;
 }

 @media only screen and (max-width: 900px) {
   
   a {
    grid-template-rows: 60px 60px auto;
   }

   h3 {
     grid-column: 3 / span 2;
   }

   .role {
     grid-column: 3 / span 2;
     margin: 0;
     padding: 0 24px 12px 24px;
   }
 } */
</style>