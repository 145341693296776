<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_181)">
<path d="M4 12.0023L11.6192 4.38308C11.7375 4.26474 11.8923 4.20557 12.0486 4.20557C12.2048 4.20557 12.3596 4.26474 12.4779 4.38308C12.7146 4.61976 12.7146 5.0036 12.4779 5.2418L6.53754 11.1807C6.47453 11.2437 6.51914 11.3514 6.60824 11.3514L20 11.3514L20 12.5651L6.52016 12.5651C6.43107 12.5651 6.38645 12.6728 6.44944 12.7358L12.4703 18.7582C12.7085 18.9949 12.7085 19.3787 12.4703 19.6154C12.2336 19.8536 11.8498 19.8536 11.6131 19.6154L4 12.0023Z"/>
</g>
<defs>
<clipPath id="clip0_1_181">
<rect width="16" height="15.5888" transform="translate(20 19.7944) rotate(-180)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>