<template>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <p class="textbox text-block half2">Operating exclusively as a physical store, the company found it challenging to present the various product ranges, which has limited space for a showroom. Despite being a long-standing issue, it had the worsening of the pandemic period where its options were even more restricted.</p>
    </div>

    <div class="tile grid textbox text-block" v-motion-slide-visible-once-bottom>
        <h2 class="half2">About the users/shoppers</h2>
        <p class=" half2">- Mainly A and B consumers <br>
- Young-adult and and newlyweds furnishing the first house<br>
- Adults looking for the specific piece<br>
- Architects looking for differentials for their projects</p>
        <h2 class="half2">Pain Points</h2>
        <p class=" half2">- Difficulty in accessing physical stores (geographic limitation)<br>
- Difficulty in viewing different materials and finishes<br>
- Products stuck in stock by limited space for a showroom<br>
- Physical store visit limitations due to the pandemic</p>
    </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/dg-cover.png" alt=""> </div>
   <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-01homepage.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-02homepage.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-CATALOG.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-productpage.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-INSPIRATION.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-WISHLIST.png" alt=""> </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/MK-INSTITUCIONAL.png" alt=""> </div>    
    <div class="tile grid" v-motion-slide-visible-once-bottom>
        <img class="center" src="@/assets/case03/guidelines.png" alt=""> </div>    
    








</template>

<script>
import '@/assets/caseslayout.css'

export default {
    components: {  }
}

</script>
<style scoped>

* {
    max-width: 100%;
}

.draft {
    filter:grayscale(1)
    opacity(.9)
}

div img {
    background-color: #F0F0F0;
}
</style>