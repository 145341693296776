<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_88)">
<g clip-path="url(#clip1_1_88)">
<path d="M20.3894 5.93657C20.2584 5.61898 20.0193 5.35495 19.7167 5.18931C19.4967 5.0685 19.2437 5 18.9741 5H5.02592C4.79067 5 4.56814 5.05231 4.3685 5.14572H4.36723C4.05315 5.29392 3.79883 5.54301 3.65005 5.85064C3.55341 6.04617 3.5 6.26412 3.5 6.49453V17.5055C3.5 17.7209 3.54705 17.9252 3.63097 18.1095C3.69074 18.2415 3.76958 18.3623 3.86113 18.4694L3.86368 18.4719C3.91327 18.5292 3.96668 18.5828 4.02517 18.6326L4.02771 18.6351C4.11927 18.7111 4.21972 18.7796 4.32908 18.8356C4.53762 18.9402 4.77541 19 5.02592 19H18.9741C19.2131 19 19.4395 18.9464 19.6417 18.8505C19.6925 18.8256 19.7421 18.7982 19.7892 18.7683C19.9812 18.6488 20.1427 18.4906 20.2648 18.3026C20.2991 18.2502 20.3283 18.1967 20.355 18.1406C20.4479 17.9476 20.5 17.7321 20.5 17.5055V6.49453C20.5 6.29775 20.4606 6.10844 20.3894 5.93657ZM18.8787 5.99635L12.363 12.2759C12.1647 12.4677 11.843 12.4664 11.6471 12.2734L5.237 5.99635H18.8787ZM19.4827 17.2863L14.7587 12.6595L14.0339 13.3582L18.777 18.0036H5.19123L10.0068 13.3669L9.28704 12.662L4.51728 17.2564V6.70003L9.94955 12.0205L10.668 12.7242L10.9274 12.9783C11.2237 13.2685 11.6153 13.4142 12.0057 13.4142C12.3961 13.4142 12.7801 13.2722 13.0764 12.987L14.0924 12.0068L19.4827 6.81212V17.2863Z" />
</g>
</g>
<defs>
<clipPath id="clip0_1_88">
<rect width="17" height="14" transform="translate(3.5 5)"/>
</clipPath>
<clipPath id="clip1_1_88">
<rect width="17" height="14" transform="translate(3.5 5)"/>
</clipPath>
</defs>
</svg>
</template>


<style scoped>

 
path {
    fill: var(--main-color)
}

</style>