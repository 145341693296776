<template>
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrow">
            <path id="arrow" d="M39.9988 33L47.618 40.6192C47.7363 40.7375 47.7955 40.8923 47.7955 41.0486C47.7955 41.2048 47.7363 41.3596 47.618 41.4779C47.3813 41.7146 46.9975 41.7146 46.7593 41.4779L40.8204 35.5375C40.7574 35.4745 40.6497 35.5191 40.6497 35.6082L40.6497 49L39.4359 49L39.4359 35.5202C39.4359 35.4311 39.3282 35.3865 39.2652 35.4494L33.2429 41.4703C33.0062 41.7085 32.6224 41.7085 32.3857 41.4703C32.1475 41.2336 32.1475 40.8498 32.3857 40.6131L39.9988 33Z"/>
            <path id="spinner" opacity="0.4" d="M0.349994 40.5C0.349996 18.3258 18.3258 0.350001 40.5 0.350003C62.6742 0.350005 80.65 18.3258 80.65 40.5C80.65 62.6742 62.6742 80.65 40.5 80.65C18.3258 80.65 0.349992 62.6742 0.349994 40.5Z" stroke-width="0.7"/>
        </g>
    </svg>
</template>

<style scoped>

#arrow{
    fill: var(--main-color);
}

#spinner{
    stroke:  var(--main-color);
}
</style>