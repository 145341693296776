<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_207)">
<path d="M16.9211 12.794V5.57849C16.9211 4.87903 16.3353 4.30905 15.6165 4.30905H14.0274V4.16433C14.0274 3.74541 13.6752 3.40266 13.2446 3.40266H12.2909C12.0444 2.73112 11.3842 2.25 10.6119 2.25C9.83952 2.25 9.17937 2.73112 8.93279 3.40266H7.9791C7.54727 3.40266 7.19632 3.74541 7.19632 4.16433V4.30905H5.60727C4.88711 4.30905 4.30263 4.87903 4.30263 5.57849V20.4806C4.30263 21.18 4.88711 21.75 5.60727 21.75H15.6165C16.3353 21.75 16.9211 21.18 16.9211 20.4806V19.8331H15.8774V20.4806C15.8774 20.6202 15.76 20.7344 15.6165 20.7344H5.60727C5.46246 20.7344 5.34634 20.6202 5.34634 20.4806V5.57849C5.34634 5.43885 5.46246 5.3246 5.60727 5.3246H7.19632V5.43759C7.19632 5.85777 7.54727 6.19925 7.9791 6.19925H13.2446C13.6752 6.19925 14.0274 5.85777 14.0274 5.43759V5.3246H15.6165C15.76 5.3246 15.8774 5.43885 15.8774 5.57849V12.794H16.9211ZM12.9837 5.1837H8.24003V4.41822H10.0248C9.99738 4.38394 9.9739 4.3484 9.95433 4.30905C9.94519 4.29254 9.93606 4.27477 9.92823 4.25573C9.92301 4.24303 9.9178 4.23034 9.91388 4.21638C9.90084 4.18083 9.8904 4.14275 9.88388 4.10339C9.88127 4.08689 9.87866 4.07039 9.87735 4.05262C9.87474 4.02977 9.87344 4.00692 9.87344 3.98407C9.87344 3.74541 9.99477 3.53214 10.18 3.40266C10.3014 3.31634 10.4501 3.26556 10.6119 3.26556C10.7736 3.26556 10.9224 3.31634 11.0437 3.40266C11.229 3.53214 11.3503 3.74541 11.3503 3.98407C11.3503 4.00692 11.349 4.02977 11.3464 4.05262C11.3451 4.07039 11.3425 4.08689 11.3399 4.10339C11.3333 4.14275 11.3229 4.18083 11.3098 4.21638C11.3059 4.23034 11.3007 4.24303 11.2955 4.25573C11.2877 4.27477 11.2785 4.29254 11.2694 4.30905C11.2498 4.3484 11.2263 4.38394 11.199 4.41822H12.9837V5.1837Z" />
<path d="M19.5434 14.4659L19.3295 14.6728L18.2884 15.677L16.9211 16.9832L16.2544 17.6205L15.8774 17.2396L14.7906 16.1441L15.5421 15.4383L15.8774 15.7772L16.274 16.1771L16.9211 15.5589L17.7704 14.7477L18.7241 13.8248L18.8076 13.7449C19.0124 13.5468 19.3451 13.5481 19.5473 13.7487C19.7496 13.9492 19.7469 14.2691 19.5434 14.4659Z" />
<path d="M18.8389 16.1987L18.3275 16.6874C18.3027 16.7116 18.2884 16.7446 18.2884 16.7776V17.9556H14.5088V14.7477H16.5793C16.6132 14.7477 16.6458 14.735 16.6706 14.7109L17.2225 14.1841L17.4664 13.9492C17.5486 13.8693 17.4912 13.7322 17.3751 13.7322H14.5101C13.9335 13.7322 13.4664 14.1866 13.4664 14.7477V17.9556C13.4664 18.5167 13.9335 18.9712 14.5101 18.9712H18.2897C18.8663 18.9712 19.3334 18.5167 19.3334 17.9556V16.0273C19.3334 15.9143 19.1938 15.8572 19.1116 15.9372L18.8402 16.1987H18.8389Z" />
<path d="M7.71426 8.19364C7.67904 8.37771 7.52509 8.52751 7.33592 8.56305C6.99541 8.62652 6.70056 8.3409 6.76709 8.01084C6.80362 7.82804 6.95627 7.67698 7.14674 7.64143C7.48595 7.57796 7.7808 7.86359 7.71426 8.19364Z" />
<path d="M14.4671 8.10216C14.4671 8.36239 14.2688 8.57312 14.0235 8.57312H9.16502C8.91975 8.57312 8.72144 8.36239 8.72144 8.10216C8.72144 7.84192 8.91975 7.63119 9.16502 7.63119H14.0235C14.2688 7.63119 14.4671 7.84192 14.4671 8.10216Z" />
<path d="M7.71426 10.5409C7.67904 10.7249 7.52509 10.8747 7.33592 10.9103C6.99541 10.9737 6.70056 10.6881 6.76709 10.3581C6.80362 10.1753 6.95627 10.0242 7.14674 9.98864C7.48595 9.92517 7.7808 10.2108 7.71426 10.5409Z" />
<path d="M14.4671 10.4494C14.4671 10.7096 14.2688 10.9203 14.0235 10.9203H9.16502C8.91975 10.9203 8.72144 10.7096 8.72144 10.4494C8.72144 10.1891 8.91975 9.9784 9.16502 9.9784H14.0235C14.2688 9.9784 14.4671 10.1891 14.4671 10.4494Z" />
<path d="M7.71557 12.8868C7.68034 13.0709 7.52639 13.2207 7.33722 13.2562C6.99671 13.3197 6.70186 13.0341 6.7684 12.704C6.80493 12.5212 6.95757 12.3701 7.14805 12.3346C7.48726 12.2711 7.7821 12.5567 7.71557 12.8868Z" />
<path d="M14.4671 12.7953C14.4671 13.0555 14.2688 13.2663 14.0235 13.2663H9.16502C8.91975 13.2663 8.72144 13.0555 8.72144 12.7953C8.72144 12.5351 8.91975 12.3243 9.16502 12.3243H14.0235C14.2688 12.3243 14.4671 12.5351 14.4671 12.7953Z" />
<path d="M7.71557 15.2326C7.68034 15.4167 7.52639 15.5665 7.33722 15.6021C6.99671 15.6655 6.70186 15.3799 6.7684 15.0498C6.80493 14.867 6.95757 14.716 7.14805 14.6804C7.48726 14.617 7.7821 14.9026 7.71557 15.2326Z" />
<path d="M9.16372 14.6716H12.5401V15.6122H9.16372C8.91844 15.6122 8.72014 15.4015 8.72014 15.1425C8.72014 15.013 8.76971 14.895 8.8493 14.8099C8.93019 14.7236 9.04108 14.6716 9.16372 14.6716Z" />
<path d="M7.71557 17.5799C7.68034 17.7639 7.52639 17.9137 7.33722 17.9493C6.99671 18.0127 6.70186 17.7271 6.7684 17.3971C6.80493 17.2143 6.95757 17.0632 7.14805 17.0277C7.48726 16.9642 7.7821 17.2498 7.71557 17.5799Z" />
<path d="M12.5401 17.0188V17.9594H9.16372C8.91844 17.9594 8.72014 17.7487 8.72014 17.4885C8.72014 17.359 8.76971 17.2409 8.8493 17.1559C8.93019 17.0708 9.04108 17.0188 9.16372 17.0188H12.5401Z" />
</g>
<defs>
<clipPath id="clip0_1_207">
<rect width="15.3947" height="19.5" fill="white" transform="translate(4.30263 2.25)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>