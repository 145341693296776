<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_81)">
<path d="M12.5884 20.9812C7.16374 21.3272 2.67215 16.837 3.01952 11.4124C3.30631 6.92751 6.92812 3.3057 11.4116 3.01892C16.8363 2.67155 21.3278 7.16313 20.9805 12.5878C20.6937 17.0713 17.0719 20.6944 12.587 20.9799L12.5884 20.9812ZM12.5184 4.09469C7.74269 3.78906 3.78966 7.74208 4.09395 12.5178C4.34573 16.4654 7.53534 19.6537 11.4816 19.9054C16.2573 20.2111 20.2103 16.2567 19.9061 11.481C19.6543 7.53339 16.4647 4.34512 12.517 4.092L12.5184 4.09469Z" />
<path d="M17.447 11.9858V11.9885C17.447 12.2847 17.206 12.5244 16.9098 12.5257L12.5259 12.5365L12.5367 16.9689C12.538 17.2664 12.297 17.5088 11.9995 17.5088H11.9968C11.7006 17.5088 11.4609 17.2677 11.4596 16.9715L11.4488 12.5392L7.03664 12.55C6.74043 12.55 6.49808 12.3103 6.49673 12.0128C6.49673 11.7152 6.73774 11.4742 7.03394 11.4729L11.4461 11.4621L11.4353 7.09975C11.434 6.8022 11.675 6.55984 11.9725 6.55984H11.9752C12.2714 6.55984 12.5111 6.80085 12.5124 7.09706L12.5232 11.4594L16.9071 11.4486C17.2046 11.4486 17.447 11.6883 17.447 11.9858Z" />
</g>
<defs>
<clipPath id="clip0_1_81">
<rect width="17.9987" height="17.9999"  transform="translate(3.00064 3.00003)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>