<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_190)">
<path d="M21.4888 12.0236L17.5043 16.0081C17.2924 16.2186 16.951 16.2186 16.7405 16.0081C16.5301 15.7976 16.5301 15.4563 16.7405 15.2444L19.2287 12.7563C19.3137 12.6713 19.253 12.5256 19.1329 12.5256H11.5349V11.4461H19.0573C19.1774 11.4461 19.2381 11.3004 19.1531 11.2154L16.7338 8.79471C16.5233 8.58421 16.5233 8.24284 16.7338 8.03234C16.839 7.9271 16.9767 7.87447 17.1157 7.87447C17.2546 7.87447 17.3923 7.9271 17.4975 8.03234L21.4888 12.0236Z" />
<path d="M15.568 7.49127V4.57945C15.568 3.98305 15.0849 3.5 14.4885 3.5H5.59117C4.99477 3.5 4.51172 3.98305 4.51172 4.57945V19.4205C4.51172 20.0169 4.99477 20.5 5.59117 20.5H14.4885C15.0849 20.5 15.568 20.0169 15.568 19.4205V16.5506C15.568 16.2524 15.3265 16.0108 15.0283 16.0108C14.7301 16.0108 14.4885 16.2524 14.4885 16.5506V19.4205H5.59117V4.57945H14.4885V7.49127C14.4885 7.78947 14.7301 8.03099 15.0283 8.03099C15.3265 8.03099 15.568 7.78947 15.568 7.49127Z" />
</g>
<defs>
<clipPath id="clip0_1_190">
<rect width="16.9771" height="17" fill="white" transform="translate(4.51172 3.5)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>