<template>
<div class="wrap">
    <label>
      <input ref="switcher" type="checkbox" v-model="isDarkTheme" >
      <span class="knob"><div class="dot"></div></span>
      <!-- <span  class="tooltiptext">Change color mode</span>   -->
		</label>
    </div>
</template>
<script>
  import { ref } from 'vue'
  
  export default {
      name: 'ThemeSwicth',
      components: {
      },
 
      data: () => {
        return {
          isDarkTheme: localStorage.getItem('data-theme') === 'dark',
        }
      },
      watch: {
        isDarkTheme: {
          handler(val) {
            const theme = val ? 'dark' : 'light';
            document.documentElement.setAttribute('data-theme', theme);
            localStorage.setItem('data-theme', theme);
          },
          immediate: true
        }
      }, 

  } 
</script>

<style scoped>


input {
 display: none;
}

.knob {
  position: relative;
  background-color: #FEFEFE;
  width: 24px;
  height: 24px;
  border-radius: 2.5rem;
  /* margin: 3px 0; */
  display: flex;
  justify-content:center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  transition: all .3s ease-in-out;
  transform: rotate(-90deg);
  box-shadow:2px 2px 8px rgba(48, 48, 48, 0.20);
  overflow: hidden;
}

.knob:hover {
   overflow: visible;
   transform: .3s;
   transition-delay: 4s;
}

 /* label {
  overflow: hidden;
  display: flex;
  width:24px;
  height:24px;
  justify-content: center;
  border-radius: 2.5rem;

 } */

 label:has(> .knob:hover) {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: flex-end;
  box-shadow: none;
  
}


.knob:hover::before {
   opacity: .8;
   transition: opacity .1s;
   transform: translateX(.5rem);
    

} 

.knob::before{
    content: "Change color mode";
    position: absolute;
    margin-right: 0.5rem;
    transition: all .2s ease;
    transform: translateX(-2rem);
    opacity: 0;
    font-weight: 300;
    text-transform: uppercase;
     color: var(--main-color);
  font-size: .7rem;
  min-width: 240px;
   padding: 0 24px;
   left: 50%;

   

}

.dot {
  background-color: #1b1b1b;
  width: 4px;
  height: 4px;
  border-radius: 2.5rem;
  transition: all .3s ease-in-out;
  
} 

.toggle:has(input:checked) { 
  box-shadow: none;
} 

input:checked + .knob .dot{
  width: 22px;
  height: 22px;
}

/* .tooltiptext::before {
  margin-right: 0.5rem;
  transition: all .2s ease;
  transform: translateX(2rem);
  opacity: 0;
  content: " ";
  position: absolute;
  
}

.tooltiptext {
  visibility: hidden; 
  color: var(--main-color);
  position: absolute;
  z-index: 1;
  padding: 0 24px;
  min-width: 240px;
  float: right;
  left: 50%;
  top: 8px;
  font-size: .7rem;
  font-weight: 300;
  text-transform: uppercase;
  transition: opacity .1s;
    transform: translateX(0rem);
  
} */





</style>