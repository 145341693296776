<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_187)">
<path d="M12.0023 20L4.38308 12.3808C4.26474 12.2625 4.20557 12.1077 4.20557 11.9515C4.20557 11.7952 4.26474 11.6404 4.38308 11.5221C4.61975 11.2854 5.0036 11.2854 5.24179 11.5221L11.1807 17.4625C11.2437 17.5255 11.3514 17.4809 11.3514 17.3918L11.3514 4L12.5651 4L12.5651 17.4798C12.5651 17.5689 12.6728 17.6135 12.7358 17.5506L18.7582 11.5297C18.9949 11.2915 19.3787 11.2915 19.6154 11.5297C19.8536 11.7664 19.8536 12.1502 19.6154 12.3869L12.0023 20Z" />
</g>
<defs>
<clipPath id="clip0_1_187">
<rect width="16" height="15.5888"  transform="translate(19.7944 4) rotate(90)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>