<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_134)">
<path d="M18.562 10.6372C18.2813 10.6372 18.054 10.8655 18.054 11.1473V18.4695C18.054 18.7501 17.8267 18.9797 17.546 18.9797H5.02398C4.74331 18.9797 4.51599 18.7501 4.51599 18.4695V5.89264C4.51599 5.61205 4.74331 5.38248 5.02398 5.38248H12.3823C12.6629 5.38248 12.8903 5.15418 12.8903 4.87231C12.8903 4.59044 12.6629 4.36214 12.3823 4.36214H5.02398C4.18325 4.36214 3.5 5.04959 3.5 5.89264V18.4695C3.5 19.3151 4.18198 20 5.02398 20H17.546C18.388 20 19.07 19.3151 19.07 18.4695V11.1473C19.07 10.8655 18.8427 10.6372 18.562 10.6372Z" />
<path d="M20.0098 4.01268C19.9361 3.91192 19.8523 3.81626 19.7621 3.72571C18.7881 2.75512 17.2069 2.75895 16.2367 3.73336L14.5946 5.38247L13.9062 6.07375L12.8903 7.09408L10.4189 9.57604C10.3033 9.6921 10.2284 9.84005 10.2042 10.0033L9.75213 13.0439C9.72292 13.2416 9.79023 13.4456 9.9312 13.5872C10.0506 13.7084 10.2144 13.7747 10.382 13.7747C10.4113 13.7747 10.4417 13.7734 10.4722 13.7683L13.538 13.3219C13.6992 13.299 13.8516 13.2224 13.9672 13.1064L18.054 9.0021L18.9938 8.05829L19.07 7.98177L19.7685 7.28029C19.8561 7.19229 19.9374 7.09791 20.0098 6.99842C20.3298 6.56861 20.5013 6.04824 20.5 5.50109C20.4987 4.95776 20.3273 4.44122 20.0098 4.01268ZM16.0043 9.61685L13.3081 12.3246L10.8329 12.6855L11.1974 10.2367L12.8903 8.53657L13.9062 7.51624L15.7401 5.67582L17.8343 7.77898L16.0043 9.61685ZM19.07 6.53672C19.0637 6.54438 18.5531 7.05709 18.5531 7.05709L16.4576 4.95393C16.4576 4.95393 17.0215 4.3902 17.0558 4.36214C17.3289 4.13384 17.6667 4.01906 18.0032 4.01906C18.3575 4.01906 18.7131 4.1466 18.9938 4.4004C19.0116 4.41699 19.0294 4.43357 19.0459 4.45015C19.3278 4.73074 19.484 5.10571 19.484 5.50364C19.4853 5.89136 19.338 6.25741 19.07 6.53672Z" />
</g>
<defs>
<clipPath id="clip0_1_134">
<rect width="17" height="17" fill="white" transform="translate(3.5 3)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>