<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_209)">
<path d="M6.46675 20.8104C6.63228 20.9354 6.82539 21 7.02112 21H7.02375C7.17482 21 7.3259 20.9629 7.46515 20.886L12.0026 18.3909L16.54 20.886C16.8606 21.0618 17.2428 21.033 17.5358 20.8104C17.8287 20.5878 17.9732 20.2169 17.9115 19.8431L17.0445 14.559L20.7149 10.8164C20.975 10.5512 21.0656 10.1624 20.954 9.80238C20.8423 9.44104 20.5494 9.18411 20.1907 9.12915L15.1173 8.35837L12.8486 3.55095C12.687 3.21159 12.3625 3 12 3C11.6374 3 11.3129 3.21159 11.1513 3.55095L8.88392 8.35837L3.8105 9.12915C3.45186 9.18411 3.15891 9.44241 3.04725 9.80238C2.93559 10.1637 3.02623 10.5512 3.28634 10.8164L6.95807 14.559L6.09104 19.8431C6.0293 20.2169 6.1738 20.5878 6.46675 20.8104Z" />
</g>
<defs>
<clipPath id="clip0_1_209">
<rect width="18" height="18" fill="white" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>