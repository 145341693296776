<template>
    <div class="indexlist">
    <div class="gallery" >
      <div class="project-card" v-for="project in projects" :key="project.id" @mouseover="toggleVisibility(project)">
        <router-link :to="project.path" class="card">
          <div class="card-info">
            {{project.year}}<br>{{project.title}}</div>
          <div class="cover" :class=" {Visible: project.isVisible}" >
          <img  :src="project.cover"  /></div>  </router-link>
      </div>
    </div>
   <!-- <div class="ModalWrap" v-if="showModal"  >
     <Modal @close="toggleModal" @goback="previousProject" @goforward="nextProject" :project="selectedProject" > 
    </Modal>
    </div>  -->
  
    <div class="postit"> 
      <h3>Good things take time!</h3> 
      <p>I'm working here. New content is being added weekly. Feel free to peek through my ongoing progress. :)</p>
    </div>
  </div> 
</template>

<script>

// Capas
import Thumb01 from '@/assets/images/visualjournal/D02.png';
import Thumb02 from '@/assets/images/visualjournal/BEL/b08.gif'
import Thumb03 from '@/assets/images/visualjournal/IISA-01.png';
import Thumb04 from '@/assets/images/visualjournal/FYO-01.png';
import Thumb05 from '@/assets/images/visualjournal/I2E-01.png';
import Thumb06 from '@/assets/images/visualjournal/ICL.png';
import Thumb07 from '@/assets/images/visualjournal/Aton.png';
import Thumb08 from '@/assets/images/visualjournal/EditEmail-flow.png';
import Thumb09 from '@/assets/images/visualjournal/approve.png';
import Thumb10 from '@/assets/images/visualjournal/Ib-xl.jpg';

// APV
import Apv01 from '@/assets/images/visualjournal/APV/NavBar.png';
import Apv02 from '@/assets/images/visualjournal/APV/image 270.png';
import Apv03 from '@/assets/images/visualjournal/APV/image 272.png';
import Apv04 from '@/assets/images/visualjournal/APV/image 665.png';
import Apv05 from '@/assets/images/visualjournal/APV/image231.png';

// Belma 2
import BelGif from '@/assets/images/visualjournal/BEL/b09-xl.gif';
import Bel01 from '@/assets/images/visualjournal/BEL/P2m.png';
import Bel02 from '@/assets/images/visualjournal/BEL/P3.png';
import Bel03 from '@/assets/images/visualjournal/BEL/P4.png';
import BelGif2 from '@/assets/images/visualjournal/BEL/b22.gif';
import Bel04 from '@/assets/images/visualjournal/BEL/b23.png';
import Bel05 from '@/assets/images/visualjournal/BEL/b24.jpg';
import Bel06 from '@/assets/images/visualjournal/BEL/b01l.jpg';
import Bel07 from '@/assets/images/visualjournal/BEL/P1.png';
import Bel09 from '@/assets/images/visualjournal/belma-cover.png';
import Bel10 from '@/assets/images/visualjournal/BEL/Expandido-biscoito-leite-l.png';
import Bel11 from '@/assets/images/visualjournal/BEL/cartazbelma.png';
import Bel12 from '@/assets/images/visualjournal/BEL/BELMA-ROSQUINHAS-m.jpg';
import Bel13 from '@/assets/images/visualjournal/BEL/BISCOITO-DE-COCO-m.jpg';
import Bel14 from '@/assets/images/visualjournal/BEL/BISCOITO-DE-LEITE-m.jpg';
import Bel15 from '@/assets/images/visualjournal/BEL/photobelma3.jpg';
import Bel16 from '@/assets/images/visualjournal/BEL/Recheado2-02.jpg';
import Bel17 from '@/assets/images/visualjournal/BEL/BISCOITO-SABOR-MARIA-m.jpg';
import Bel18 from '@/assets/images/visualjournal/BEL/CREAM-CRACKER-m.jpg';
import Bel19 from '@/assets/images/visualjournal/BEL/Recheado-Choconegro.jpg';
import Bel20 from '@/assets/images/visualjournal/BEL/Recheado-de-Chocolate-m.jpg';



//INT 1
import INT1 from '@/assets/images/visualjournal/INT/cronograma.png';
import INT2 from '@/assets/images/visualjournal/INT/moodboard-l.png';
import INT3 from '@/assets/images/visualjournal/INT/ideate-l.png';
import INT4 from '@/assets/images/visualjournal/INT/IMG9121.jpg';
import INT5 from '@/assets/images/visualjournal/INT/IMG_9124.jpg';
import INT6 from '@/assets/images/visualjournal/INT/03-Disco-l.jpg';
import INT7 from '@/assets/images/visualjournal/INT/04 Reticula.jpg';
import INT8 from '@/assets/images/visualjournal/INT/05-Pantone-xl.jpg';
import INT9 from '@/assets/images/visualjournal/INT/06 Medida.jpg';
import INT10 from '@/assets/images/visualjournal/INT/07-Fontes-xl.jpg';
import INT11 from '@/assets/images/visualjournal/INT/50_Kerning-l.jpg';
import INT12 from '@/assets/images/visualjournal/INT/08 Acabamentos.jpg';
import INT13 from '@/assets/images/visualjournal/INT/09 Encadernacoes.jpg';
import INT14 from '@/assets/images/visualjournal/INT/55_Tipos de papel.jpg';
import INT15 from '@/assets/images/visualjournal/INT/45_Moiree.jpg';
import INT16 from '@/assets/images/visualjournal/INT/48_Composicao.jpg';
import INT17 from '@/assets/images/visualjournal/INT/51_Entrelinha.jpg';
import INT18 from '@/assets/images/visualjournal/INT/56_Refile.jpg';
import INT19 from '@/assets/images/visualjournal/INT/IMG_2740.jpg';
import INT20 from '@/assets/images/visualjournal/INT/43_Sintese.jpg';
import INT21 from '@/assets/images/visualjournal/INT/bienal.jpeg';

         

export default { 
    data () {
        return {
           projects: [ 
                //  { id: 10, year: '2024', note: '',  title: 'Change email - Flow',  cover: [Thumb08], isVisible:false, },
                //  { id: 9, year: '2023', note: '',  title: 'Aton - Homepage', cover: [Thumb07], isVisible:false},
                { id: 'icl', path: '/archieve/icl', year: '2023', note: '',  title: 'Iconlix', cover: [Thumb06], isVisible:false },
                //  { id: 7, year: '2023', note: '',  title: 'Event management platform', cover: [Thumb05], isVisible:false },
                //  { id: 6, year: '2023', note: '', title: '-',   cover: [Thumb04], isVisible:false },

                { id: 'apv', path: '/archieve/apv', year: '2022', note: '', title: 'Approve - Website concept',  cover: [Thumb09], images: [ Apv02, Apv03, Apv04, Apv05, Apv01, ], isVisible:false },
                // { id: 4, year: '2022', note: '', title: '-',  cover: [Thumb03], isVisible:false },
                // { id: 3, year: '2020', note: 'Experimantation - 2020',  title: 'Checkout flow', cover: [Thumb01], isVisible:false },
                { id: 'blm', path: '/archieve/blm', year: '2016',  note: 'After operating in the food industry for more than a decade, the company realized the importance of updating its branding identity. The company faced several challenges, such as low brand recognition and inconsistency in its product\'\s visual representation. Therefore, this project was initiated to modernize its visual identity while retaining its connection with its history.', title:'Belma - packaging & branding redesign', cover: [Thumb02], isVisible:false
                 },
                { id: 'int', path: '/archieve/int',  year: '2016', note: 'This project is the Final Course Assignment of our Graphic Design Bachelor Degree. Our dissertation focused on creating a Graphic Production Experimental Book that would enhance the user\'\s learning experience. We used mobile graphics and infographics to achieve this goal. Focusing on user experience, the main idea behind the book was to make technical content easier to understand by using Pop-Up and 3D Interactive Devices. By presenting information in a playful and engaging way, we hoped to spark the reader\'\s interest and facilitate learning.', title:'Interactive Graphic Production Book',  cover: [Thumb10] , images: [ INT1, INT2, INT3, INT4, INT5, INT6, INT7, INT8, INT9, INT10, INT11, INT12, INT13, INT14, INT15, INT16, INT17, INT18,  INT19, INT20, INT21,], isVisible:false}, 
            ],
            // showModal: false,
            isVisible: false,
            selectedProject: {}
        };
    },

    methods: {
      // toggleModal(project) {
      //   this.showModal = !this.showModal;
      //   this.selectedProject = project;
      // },
      toggleVisibility(project) {
        project.isVisible = !project.isVisible
      }, 
      previousProject () {
        const previous =  this.selectedProject.number - 1
        this.selectedProject = this.projects.find(e => e.number === (previous === 0 ? 3 : previous));
      },
      nextProject () {
        const next =  this.selectedProject.id + 1
        this.selectedProject = this.projects.find(e => e.id === (next === 4 ? 1 : next));
      }
    }
}
</script>

<style scoped>
* {
    max-width: 100%;
    box-sizing: border-box;
}
.postit {
  	padding: 20px 24px;
    background: #EAF763;
    width: 210px;
    height: 200px;
    transform: rotate(-10deg);
    place-self: center;
    -webkit-box-shadow: 0 8px 10px -7px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: 0 8px 10px -7px rgba(0, 0, 0, 0.2);
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  -webkit-border-bottom-right-radius: 60px 10px;
	-moz-border-radius-bottomright: 60px 10px;
	border-bottom-right-radius: 60px 10px;
  position: relative;
  z-index: 90;

}

.postit h3 {
  font-size: .8rem;
  color: #141414 ;
}

.postit p {
  color: #141414;
}

.indexlist {
  display: flex;
  width: 100vw;
  flex-direction: column ;
  min-height: 100vh;
}

.indexlist:has(.ModalWrap) > .postit{
  display: none;
}

.indexlist:has(.ModalWrap) > .gallery{
  height: 85vh;
  overflow-y: hidden;
}

.gallery {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0;
    margin-left: 58px;
    padding: 0;

}

.card {   
  overflow: hidden;
  padding: 1.1%;
  aspect-ratio: 1 / 1;
  position: relative ;
  display: flex;
}

.cover {
  position: absolute;
  z-index: 1;
  display: flex;
  opacity: 0;
  transition: opacity .3s;
}

 .cover.Visible  {
  opacity: 1;
  transition: opacity .3s;
}

.cover img {
  position: relative;
  object-position: left top;
  width: 100%;
  height: 100%;
  object-fit: contain;
  order: 1;
  align-self: flex-start;
  display: block;
}

a {
  text-decoration: none;

}
.card-info {
    padding: 24px;
    max-width: 160px;
      font-size: .65em;
  text-transform: uppercase;
}

@media (min-width: 2001px) {
  .project-card {
    flex: 0 0 calc(100% / 6); 
    padding: 1.5%;}}

@media (max-width: 2000px) {
  .project-card {
    flex: 0 0 calc(100% / 5); }}
  

@media (max-width: 1400px) {
  .project-card {
    flex: 0 0 calc(100% / 4); }}
  

@media (max-width: 1000px) {
  .project-card {
    flex: 0 0 calc(100% / 3); } 
    
  .cover {
    opacity: 1;  }}

@media (max-width: 600px) {
    .project-card {
      flex: 0 0 calc(100% / 2); }}

</style>