<template>
   <div class="scroll-down">
        <div class="image-wrapper">
            <svg id="arrow-down" width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg"><g>
                <path id="arrow" d="M55.0012 62L47.382 54.3808C47.2637 54.2625 47.2045 54.1077 47.2045 53.9515C47.2045 53.7952 47.2637 53.6404 47.382 53.5221C47.6187 53.2854 48.0025 53.2854 48.2407 53.5221L54.1796 59.4625C54.2426 59.5255 54.3503 59.4809 54.3503 59.3918L54.3503 46L55.5641 46L55.5641 59.4798C55.5641 59.5689 55.6718 59.6135 55.7348 59.5506L61.7571 53.5297C61.9938 53.2915 62.3776 53.2915 62.6143 53.5297C62.8525 53.7664 62.8525 54.1502 62.6143 54.3869L55.0012 62Z" />
                <circle id="spinner" opacity="0.4" cx="54.5" cy="54.5" r="54.15"  stroke-width="0.7"/></g>
            </svg>
        </div>    
        <p class="headline" id="arrow-text">Selected Cases</p>  
    </div> 
</template>
<style>

.scroll-down {
    color:var(--main-color);
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-direction: row;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0 5%;
    

}

#arrow {
    fill: var(--main-color);
    opacity: 1;
    transform: translateY(0);
}

#arrow-down g {
    position: absolute;		
}

.scroll-down:hover > .image-wrapper #arrow-down g path {
  animation: arrow-down 2s infinite;
}

.scroll-down:hover > .image-wrapper #arrow-down g circle {
  stroke-dasharray: 275;
  animation: rotator 1s linear infinite;
}

#spinner{
    stroke: var(--main-color);
    transform-origin: center;
}

@keyframes arrow-down {
    0% {
        transform: translateY(-20%);
        opacity: 0;
      }
    25% {
        transform: translateY(40%);
        opacity: 1;
      }
    100% {
        transform: translateY(100%);
        opacity: 0;
      }
  }

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(250deg); }
  }

@media (max-width: 960px) {    
    .scroll-down {
        padding: 0 5%;
    }
   }  



</style>