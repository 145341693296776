import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import About from '../views/AboutView.vue'
import Resume from '../views/Resume.vue'
import Archieve from '../views/Archieve.vue'

import PageNotFound from '../views/PageNotFound.vue'
import ProjectDetails from '../views/ProjectDetails.vue'
import ProjectIis from '../views/Cases/projectIis.vue'
import ProjectBits from '../views/Cases/projectBits.vue'
import ProjectLiber from '../views/Cases/projectLiber.vue'
import ProjectEve from '../views/Cases/projectEve.vue'
import Caseeve from '../views/Cases/Caseeve.vue'
import Caseiis from '../views/Cases/Caseiis.vue'
import Casebits from '../views/Cases/Casebits.vue'



import blm from '../views/ArchieveFiles/blm.vue'
import int from '../views/ArchieveFiles/int.vue'
import apv from '../views/ArchieveFiles/apv.vue'
import icl from '../views/ArchieveFiles/icl.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/archieve',
    name: 'archieve',
    component: Archieve, 
  },
  {
    path: '/archieve/int',
    component: int , 
    name: 'int',
  },  
  {
    path: '/archieve/blm',
    component: blm , 
    name: 'blm',
  },
  {
  path: '/archieve/apv',
  component: apv , 
  name: 'apv',
  },
  {
    path: '/archieve/icl',
    component: icl , 
    name: 'icl',
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
  path: '/about/resume',
  name: 'resume',
  component: Resume,

  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: PageNotFound, 
  },


  {
    path: '/project',
    component: ProjectDetails,
    props: true,
    children: [
      { path: '/project/project-eve', name: 'project-eve', component: ProjectEve,},
      { path: '/project/project-iis', name: 'project-iis', component: ProjectIis,},
      { path: '/project/project-bits', name: 'project-bits', component: ProjectBits,},
      { path: '/project/project-liber', name: 'project-liber', component: ProjectLiber,},
      { path: '/project/iis', name: 'caseiis', component: Caseiis,},
      { path: '/project/eve', name: 'caseeve', component: Caseeve,},
      { path: '/project/bits', name: 'casebits', component: Casebits,},
    
]
   },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
