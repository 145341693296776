<template>
    <div class="navbar" :class="{ 'hidden-navbar': !showNavbar }">
          <div class="sidebar">
          </div><div class="navmenu">
      
            <div class="logo" alt="Jéssica Diogo"><a href="/"><Logo/></a></div>
            <!-- <nav> 
                <router-link class="tab-1" to="/">Case Studies </router-link> -
                <router-link class="tab-2" to="/visualjournal">Archieve</router-link> -
               
                 <router-link class="tab-3" to="/about">About</router-link> 
                <span class="tab-selector"></span>
            </nav> -->
        </div>
         <MenuTrigger @click="toggleSideMenu" />
    </div>
    <Transition name="slide" mode="out-in">
      <Sidemenu class="sidemenu" v-show="showSideMenu" :class="`${sideMenuActive && 'sideMenuActive'}`" @close="toggleSideMenu" /> 
    </Transition>       
</template>

<script>
import Logo from '../assets/Logosvg.vue'
import MenuTrigger from '../assets/MenuTrigger.vue'
import Sidemenu from '../components/Sidemenu.vue'

//HideNav
const OFFSET = 60
export default {
  name: 'Navbar',
  components: {
  Logo,  MenuTrigger, Sidemenu, }, 
  data() {
        return {
          //HideNavbar
            showNavbar: true,
            lastScrollPosition: 0,
            scrollValue: 0,
          //SideMenu
            showSideMenu: false,
            sideMenuActive: false,
        }  
      }, 


  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    //HideNavbar
    onScroll () {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    //SideMenu
      const App = document.querySelector("#app")
      const SideMenu = document.querySelector(".sidemenu")
      if(App.contains(SideMenu)) {
      this.showSideMenu = false;
      }
    },
    //SideMenu
     toggleSideMenu (event) {
      this.showSideMenu = !this.showSideMenu
      this.sideMenuActive = !this.sideMenuActive
      //event.stopPropagation();
    },
  }
}

</script>

<style scoped>
/* Navbar */ 
.navbar, .navmenu {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.navbar {
  background-color: var(--background);
  backdrop-filter: blur(35px);
  --webkit-backdrop-filter: blur(35px);
  height: 72px;
  width: 100dvw;
  width: 100%;
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  z-index: 997;
}

.navbar.hidden-navbar {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
} 

.navmenu {
    display: flex;
    width: calc(100vw - 80px);
    height: 40px;
    padding: 0 44px 0 130px;
}

.sidebar {
   width: 58px;
     background-color:var(--surface);
  height: 100%;
}

nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--main-color);
    white-space:nowrap ;
    position: relative;
    height: 42px;
    justify-content: flex-end;
    position:relative;
}

nav a {
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin: 0 32px;
    text-decoration: none;
    color: var(--main-color);
    width:fit-content;
    float: left; 
}

nav a:hover {
     font-weight: 800; 
     transition: all ease .5s;
     animation-delay:0.5s;
}

nav a.router-link-exact-active {
    font-weight: 800;
    transition: all ease .5s;
    animation-delay:0.5s;
}

.router-link-exact-active ~ span{
    background-color: var(--main-color);
    height: 1px;
    width: 100%;
    position:absolute;
    z-index: 10;
    left: 0;
    transform: translateX(0);
    transition: transform .5s ease ;
    animation-delay:0.15s;
    border-radius: 5px;
}

.tab-1.router-link-exact-active ~ .tab-selector {
    transform: translateX(-272px);
    right: 0;
    left: unset;
    max-width: 98px;
}

.tab-2.router-link-exact-active ~ .tab-selector {
    transform: translateX(-142px);
    right: 0;
    left: unset;
    max-width: 68px;
}

.tab-3.router-link-exact-active ~ .tab-selector {
    transform: translateX(-30px);
    right: 0;
    left: unset;
    max-width: 48px;
}

.logo {
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    position: absolute;
    left:10px;
    z-index: 1;
}

@media only screen and (max-width: 1400px) {
  .navmenu {
    padding: 0 44px 0 100px;
}}

@media only screen and (max-width: 900px) {
  .navmenu {
    grid-template-columns: 80px 1% repeat(12, 1fr) 1% ;
    padding: 0 44xp 0 44xp;
}
  nav {
    grid-column: 8 / span 4;
  }
}

@media only screen and (max-width: 760px) {
  nav {
    display: none;
  }

  .logo {
    padding: 12px;
    top: 1.5%;
    left:-2.1px;
    transform: scale(1.2);
    z-index: 999;
  }
}

@media only screen and (min-width: 1800px) {
  .navmenu {
    grid-template-columns:  80px 16% repeat(12, 1fr) 16%  ;
  }
}

@media only screen and (min-width: 2200px) {
  .navmenu {
    grid-template-columns:  80px 20% repeat(12, 1fr) 20%  ;
  }
}
</style>

<style scoped>
/* Sidemenu */

.sidemenu {
  position: absolute;
  z-index: 997;
}

</style>