<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_178)">
<path d="M20 11.9977L12.3808 19.6169C12.2625 19.7353 12.1077 19.7944 11.9515 19.7944C11.7952 19.7944 11.6404 19.7353 11.5221 19.6169C11.2854 19.3802 11.2854 18.9964 11.5221 18.7582L17.4625 12.8193C17.5255 12.7563 17.4809 12.6486 17.3918 12.6486H4V11.4349H17.4798C17.5689 11.4349 17.6135 11.3272 17.5506 11.2642L11.5297 5.2418C11.2915 5.00512 11.2915 4.62128 11.5297 4.3846C11.7664 4.14641 12.1502 4.14641 12.3869 4.3846L20 11.9977Z" />
</g>
<defs>
<clipPath id="clip0_1_178">
<rect width="16" height="15.5888" transform="translate(4 4.20558)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>