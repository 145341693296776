<template   >
    <div class="grid tile" ref="animationtarget" >
        <div class="title" >
            <h2>{{ id }}</h2>
            <h1>{{ title }}</h1>
        </div>
        <div id="brief">
          <div><h2>[product brief]</h2><p>{{ productbrief }}</p></div>
          <div><h2> [the challenge]</h2><p>{{ challenge }}</p></div>
          <div><h2>[the basics]</h2><p>{{ basics }}</p></div>
        </div>
    </div>   

</template>

<script setup>
import { ref } from 'vue'
import { useMotion } from '@vueuse/motion'

const animationtarget = ref()

useMotion(animationtarget, {
  initial: {
    opacity: 0,
    y:-200,
    
  },
  visibleOnce: {
    opacity: 1,
    y: 0,

  },
  enter: { transition: {
      delay: 1000,
    },
}
})

</script>

<script>
import '@/assets/caseslayout.css'


export default {
  props: {
    id: String,
    title: String,
    productbrief: String,
    challenge: String,
    basics: String,
  },
  

  

  // methods: {
  //   scrollHandler(e) {
  //     this.isVisible = e.target.scrollTop > 300 ? false : true
  //   }
  // }
}




</script>
<style scoped>

.tile {
    padding: 200px 0;  
    width: 100%;
    height: 90vh;

}

.tile.intro--hidden {
  display: none;
}


.title {
    grid-column: 3 / span 3;
    display: flex;
    flex-flow: column;
}

h1 {
  font-weight: 800;
  line-height: 140%;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  max-width: 210px;
}

#brief {
  grid-column: 8 / span 6 ;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 15% 0 5%;
}


@media only screen and (max-width: 700px) {
  .tile {
   padding: 40px 0;  
    height: fit-content;  

  }
  .title {
    grid-column: 2 / span 8;
  }
  #brief {
    grid-column: 2 / span 14 ;
    padding: 5% 0;
  }

}

</style>
