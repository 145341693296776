<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8649 13.9867L21 17.1299L17.872 20.2718C17.652 20.4927 17.2954 20.4927 17.0755 20.2718C16.8542 20.0494 16.8556 19.691 17.0755 19.47L18.6106 17.9288C18.6557 17.8835 18.6233 17.8042 18.5599 17.8056L15.7659 17.8198C13.8164 17.8198 12.0796 16.7149 11.3382 15.0051L10.6601 13.4427L10.0356 14.8819C9.29415 16.5916 7.55603 17.6951 5.60788 17.6951L3.55964 17.6795C3.2481 17.6781 3 17.4217 3 17.1087C3.00282 16.797 3.25656 16.5463 3.56528 16.5463H3.56809L5.61211 16.5619C7.10776 16.5619 8.43989 15.7247 9.00235 14.4286L10.0455 12.0262L8.99671 9.60821C8.43566 8.31209 7.10494 7.47493 5.60788 7.47493L3.56105 7.45935C3.24951 7.45651 3 7.20154 3.00141 6.88849C3.00423 6.57685 3.25515 6.32613 3.56387 6.32613H3.5695L5.61211 6.34171C7.55322 6.34171 9.28992 7.4466 10.0314 9.15634L10.6615 10.6083L11.3452 9.03168C12.0867 7.32336 13.8248 6.21847 15.773 6.21847L18.5641 6.20431C18.6275 6.20289 18.66 6.1264 18.6148 6.08107L17.0684 4.5314C16.8485 4.31042 16.8485 3.95063 17.0684 3.72965C17.1784 3.61916 17.3236 3.5625 17.4674 3.5625C17.6112 3.5625 17.755 3.61774 17.8649 3.72823L21 6.87291L17.872 10.0133C17.652 10.2343 17.2954 10.2343 17.0755 10.0133C16.8542 9.79235 16.8556 9.43256 17.0755 9.21158L18.8207 7.45935C18.8658 7.41402 18.8333 7.33611 18.7699 7.33753L15.7758 7.35169C14.2731 7.35169 12.941 8.18885 12.3785 9.48497L11.2761 12.0248L12.3729 14.5533C12.9339 15.8494 14.2646 16.6866 15.7631 16.6866L18.7713 16.671C18.8348 16.6696 18.8658 16.5931 18.8207 16.5477L17.0684 14.7884C16.8485 14.5674 16.8485 14.2091 17.0684 13.9867C17.1784 13.8762 17.3222 13.8209 17.466 13.8209C17.6098 13.8209 17.755 13.8762 17.8649 13.9867Z" />
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>