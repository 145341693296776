<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_72)">
<path d="M12.006 20.5L11.6597 20.2227C9.56047 18.5462 7.87595 17.0834 6.65585 15.8746C4.9002 14.1371 3.02777 12.0317 3.02777 9.02717C3.02777 6.0226 5.34314 3.5 8.29876 3.5C9.71348 3.5 11.0316 4.07915 12.002 5.10918C12.9738 4.0921 14.3295 3.5 15.7428 3.5C18.6756 3.5 20.9736 5.92802 20.9736 9.02717C20.9736 12.1263 19.0327 14.2512 17.3428 15.8798C15.7831 17.3814 14.0905 18.7898 12.3496 20.2188L12.006 20.5ZM8.29876 4.53651C5.94446 4.53651 4.10157 6.50976 4.10157 9.02717C4.10157 11.5446 5.81561 13.5593 7.42362 15.1516C8.5511 16.2672 10.088 17.6107 11.9966 19.1474C13.5993 17.8284 15.1536 16.5237 16.5831 15.1464C18.1267 13.6591 19.8984 11.6197 19.8984 9.02717C19.8984 6.43461 18.073 4.53651 15.7415 4.53651C14.4476 4.53651 13.2127 5.17007 12.4382 6.23249L11.9993 6.83496L11.5604 6.23249C10.7739 5.15452 9.58597 4.53651 8.29876 4.53651Z" />
</g>
<defs>
<clipPath id="clip0_1_72">
<rect width="17.9444" height="17" transform="translate(3.02777 3.5)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>