<template>
  <transition name="fade">
    <div class="loader" :interval="interval" v-show="ShowLoader">
        <div class="counter" v-motion
            :initial="{ opacity: 0.1}"
            :enter="{ opacity: 1,
                      transition:{
                        duration: 5000,
                        delay:400,
                        ease: 'easeInOut',
                      } }"> {{ `${interval}` }} </div>
        <div class="progressbar">
            <span 

            ></span>
        </div>              
    </div>
  </transition>       
</template>

<script>
import { ref } from "vue";
import { useMotion } from '@vueuse/motion'


export default {
    name: 'preloader',

    data() {
        return {
            ShowLoader: true,
            interval: Number,
            
            
        }
    },
    beforeMount() {
        const interval = ref(0);
        const intervalId = setInterval(
        () =>  {
            interval.value++; 
            if(interval.value === 100) {
                clearInterval(intervalId)
                this.ShowLoader = !this.ShowLoader 

              }
            
        },50);

        this.interval = interval
        
    },


    // methods: {

    //     if(this.interval === 100) {
    //         this.$emit('complete')
    //         }

    //     complete () {
    //     this.ShowLoader = !this.ShowLoader }

              

    // }
}



// onMounted (() =>  console.log(this.$refs.value))
    
// function counter() {
//     var count = setInterval(function() {
//         var c = (this.$refs.count.text());
//             int = parseInt(c.textContent);
//         c.textContent = (++int).toString();    
//         if (int == 100){
//             clearInterval(count);
//         }
//     },60)
// }
// counter ()



</script>


<style>

.loader {
    background: var(--surface);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
    padding: 5%;
}

.counter {
    font-size: 4.2rem;
    font-weight: 700;
    letter-spacing: 0;
}

.counter::after {
    font-size: 2.2rem;
    font-weight: 700;
    content: "%"
} 

.progressbar {
    width:98%;
    height: 8px;
    overflow: hidden;
}

.progressbar span {
    display: flex;
    height: 8px;
    background-color: var(--main-color);
    opacity: .1;
    animation: progress 5s ease-in-out forwards;

}

@keyframes progress {
  0% { 
    opacity: .1; 
    width: 2px;}

  100% { 
    opacity: 1; 
    width: 100%;}
}


</style>