
<script>
import Toggle from '@/assets/Toggle.vue'
import { useScroll } from '@vueuse/core'



export default {
    components: {
     Toggle,
  },
  data () {
     return { 
      isVisible: false,
      lastScrollPosition: 0,
    }
  },
   mounted() {
      this.lastScrollPosition = window.pageYOffset
      window.addEventListener('scroll', this.onScroll)
      
      },

     beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll)
    }, 

  methods: {
      scrollToTop() {
        window.scrollTo(0,0);
    },
      onScroll () {
        const OFFSET = 120
        if (window.pageYOffset < OFFSET) {
          this.isVisible = false
          return
        }

        this.isVisible = true
  } 
}}


</script>

<template>

    <div class="sidebarwrap">
        <Toggle/>
        <a class="buttonwrap" id="backtothetop"  @click="scrollToTop" v-show="isVisible">
          <div class="iconwrap">
              <svg id="arrow" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.35767 1L15.5524 8.61919C15.6641 8.73753 15.72 8.89228 15.72 9.04855C15.72 9.20482 15.6641 9.35957 15.5524 9.47791C15.3289 9.71458 14.9664 9.71458 14.7415 9.47791L9.14499 3.54972C9.08287 3.48393 8.97227 3.52789 8.97227 3.61837L8.97227 17L7.82616 17L7.82616 3.53029C7.82616 3.43981 7.71559 3.39585 7.65346 3.46162L1.97813 9.47032C1.75464 9.70851 1.39218 9.70851 1.16869 9.47032C0.943769 9.23364 0.943769 8.8498 1.16869 8.61312L8.35767 1Z" stroke-width="0.5"/>
</svg>

            </div>
        </a>
    </div>

</template>
<style scoped>

.sidebarwrap {
  display: flex;
  align-items: center ;
  flex-direction: column-reverse ;
  padding: 16px 0;
  gap: 12px;
  height: 96%;
}

.buttonwrap {
  width: 26px;
  height: 26px;
  border-radius: 24px;
  background-color: var(--secondary-color);
  position: relative;
  /* transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0; */
}

.buttonwrap:hover {
  box-shadow:2px 2px 8px rgba(48, 48, 48, 0.20);
  /* border: solid .5px black; */
}

/* .buttonwrap.show {
  opacity: 1;
  visibility: visible;
} */

.iconwrap {
  display: flex;
  justify-content: center;
  padding: 4px;
}

#arrow {
    transform: rotate(90deg);
}

#arrow svg{
  fill: var(--main-color);
  stroke: var(--main-color);
}

#backtothetop {
    transform: rotate(-90deg);
    display: flex;
    justify-content:center;
    align-items: center;
    cursor: pointer;
}

#backtothetop:hover::before {
   opacity: .8;
   transition: opacity .1s;
   transform: translateX(.5rem);
    
}

#backtothetop::before {
    content: "Go back to the top";
    position: absolute;
    margin-right: 0.5rem;
    transition: all .2s ease;
    transform: translateX(-2rem);
    opacity: 0;
    font-weight: 300;
    text-transform: uppercase;
    color: var(--main-color);
    font-size: .7rem;
    min-width: 200px;
    padding: 0 24px;
    left: 50%;

    
}
</style>