<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_184)">
<path d="M11.9977 4L19.6169 11.6192C19.7353 11.7375 19.7944 11.8923 19.7944 12.0485C19.7944 12.2048 19.7353 12.3596 19.6169 12.4779C19.3802 12.7146 18.9964 12.7146 18.7582 12.4779L12.8193 6.53754C12.7563 6.47453 12.6486 6.51914 12.6486 6.60824L12.6486 20L11.4349 20L11.4349 6.52016C11.4349 6.43107 11.3272 6.38645 11.2642 6.44944L5.2418 12.4703C5.00512 12.7085 4.62128 12.7085 4.3846 12.4703C4.14641 12.2336 4.14641 11.8498 4.3846 11.6131L11.9977 4Z" />
</g>
<defs>
<clipPath id="clip0_1_184">
<rect width="16" height="15.5888" transform="translate(4.20557 20) rotate(-90)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>