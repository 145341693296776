<template>
  <div class="caseBits" >
      <Intro class="intro" v-for="intro in intros" v-bind="intro" :key="intro.title"  :class="{ 'intro--hidden': !showIntro }" />   
      <div class="intro-space"></div> 

    <Container>    
      <CaseCover/>
      <Discover/>
      <Define/>
      <Design/>
      <Delivery/>
    </Container>  

  </div>
  <Footer/>
</template>

<script>
import Container from '@/components/Container.vue'
import Footer from '@/components/Footer.vue'
import CaseCover from '@/components/CaseCover.vue'
import Intro from '@/components/CaseComponents/Intros.vue'
import Discover from '@/assets/case02/BTSDiscover.vue'
import Define from '@/assets/case02/BTSDefine.vue'
import Design from '@/assets/case02/BTSDesign.vue'
import Delivery from '@/assets/case02/BTSDelivery.vue'


export default {
  
    components: {
      Container, CaseCover, Intro, Discover, Define, Design, Delivery, Footer
},

  data () {
    return {
      intros : [
        {
          id: '[02]', 
          title: 'Discovery flow redesign for a rewards app', 
          productbrief: 'Bits is a rewards app that allows users to earn small fractions of shares every time they make purchases. Unlike traditional cashback programs, Bits goes a step further offering the possibility of growing in value over time and in dividends.',
          challenge: 'Recreate the discovery experience to accommodate the growing flow of available brands, offering the user more accurate search options and a more intuitive organization.',
          basics: 'Research, design and testing',
        },
      ],

      showIntro: true,
      lastScrollPosition: 700
    }
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
  onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    this.showIntro = currentScrollPosition < 700;

  }
}



}
</script>

<style scoped>


*{  max-width: 100%;
    box-sizing: border-box;
}

.caseBits{
    position: relative;
    overflow: hidden;
      margin-left: 58px;
}

.intro {
  z-index: -2;
  position: fixed ;
}

.intro.intro--hidden {
  display: none;
}

.intro-space {
  min-height: 85vh;
  position: relative;
}


@media only screen and (max-width: 700px) {

  .intro-space {
  min-height: 110vh;

  }

}










</style>