<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_108)">
<path d="M15.976 14.447C15.1383 14.447 14.3786 14.7903 13.83 15.3435L10.7495 13.2249C10.9391 12.8278 11.0467 12.3836 11.0467 11.9152C11.0467 11.4966 10.962 11.0982 10.8073 10.7361L13.83 8.65653C14.3786 9.20974 15.1383 9.55297 15.976 9.55297C17.6433 9.55297 19 8.19485 19 6.52716C19 4.85946 17.6433 3.5 15.976 3.5C14.3087 3.5 12.9533 4.85812 12.9533 6.52716C12.9533 6.96999 13.0488 7.38994 13.2209 7.76952L10.2171 9.83563C9.66711 9.25281 8.88725 8.88804 8.02401 8.88804C6.3567 8.88804 5 10.2462 5 11.9152C5 13.5842 6.3567 14.941 8.02401 14.941C8.83481 14.941 9.57165 14.6193 10.1162 14.0957L13.2209 16.2305C13.0488 16.6101 12.9533 17.03 12.9533 17.4728C12.9533 19.1419 14.31 20.5 15.976 20.5C17.642 20.5 19 19.1419 19 17.4728C19 15.8038 17.6433 14.447 15.976 14.447ZM15.976 4.5768C17.0503 4.5768 17.9243 5.4517 17.9243 6.52716C17.9243 7.60261 17.0503 8.47617 15.976 8.47617C15.5067 8.47617 15.0765 8.30926 14.7403 8.03064C14.4606 7.80048 14.2468 7.49493 14.1299 7.14363C14.064 6.9498 14.029 6.74252 14.029 6.52716C14.029 5.4517 14.903 4.5768 15.976 4.5768ZM9.84595 12.603C9.71552 12.9476 9.48963 13.2464 9.20054 13.4658C8.8738 13.7162 8.46504 13.8642 8.02401 13.8642C6.94967 13.8642 6.07568 12.9893 6.07568 11.9152C6.07568 10.8411 6.94967 9.96485 8.02401 9.96485C8.51748 9.96485 8.97061 10.1506 9.31348 10.4561C9.58375 10.6957 9.78813 11.0093 9.89166 11.3647C9.94276 11.5397 9.971 11.7241 9.971 11.9152C9.971 12.1575 9.92662 12.389 9.84595 12.603ZM15.976 19.4232C14.903 19.4232 14.029 18.5483 14.029 17.4728C14.029 17.2575 14.064 17.0502 14.1299 16.8564C14.2468 16.5051 14.4606 16.1995 14.7403 15.9694C15.0765 15.6907 15.5067 15.5238 15.976 15.5238C17.0503 15.5238 17.9243 16.3987 17.9243 17.4728C17.9243 18.547 17.0503 19.4232 15.976 19.4232Z"/>
</g>
<defs>
<clipPath id="clip0_1_108">
<rect width="14" height="17" fill="white" transform="translate(5 3.5)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>