<script>
import Projectbrief from '@/components/CaseComponents/Projectbrief.vue'
import '@/assets/casestudystyle.css'


export default {
    components: {
        Projectbrief,
    }, 
    data () {
        return {
            projects : [
                {
                id: 3, 
                title: 'Discovery flow redesign for a rewards app', 
                type: 'Android & IOS App',
                typedescription: 'Mobile application',
                period: '4 weeks',
                role1: 'Product Designer', 
                role2: 'Ui/Ux',
                company: 'Bits is a rewards app that allows users to earn small fractions of shares every time they make purchases. Unlike traditional cashback programs, Bits goes a step further offering the possibility of growing in value over time and in dividends.',
                challenge: 'Recreate the discovery experience to accommodate the growing flow of available brands, offering the user more accurate search options and a more intuitive organization.',
                colormode: true,
                deviceMobile: true,
                deviceTablet: false,
                deviceDesktop: false,
                overviewhighlight: 'The objective of this sprint was to address the need for more efficient data organization to handle the increased volume and to directly tackle the pain points highlighted by our users. ',
                overview: 'The core of Bits relies on the discovery it provides. As you make more transactions through the app, your rewards grow, and the discovery of new offers and brands available takes center stage in this experience. However, as the application scaled and incorporated new brands, the initial discovery process proposed by the application became problematic. Users found themselves scrolling through an overwhelming array of brands, making it harder to find offerings that truly resonated with their interests.',
                bubbles: ['Research', 'Design', 'Prototype', 'Testing'],
                //contraints: ['The deadline for this redesign was 4 weeks, including research, design and testing;', 'The new design must handle a growing number of brands and user base and enhanced search options allowing users to find brands based on various criteria (e.g., category, popularity, recent additions).', 'The recommendation system currently relies solely on purchase history, which means that new users won\'\t have initial recommendations. Although we plan to integrate preference options in the future, at this moment we are limited by technical constraints.',],
                problem: 'Existing Bits of Stock users need a more efficient way to navigate brands and offers, because they are currently overwhelmed by the large list, which may require extensive scrolling to find a relevant option. We’ve heard from users that the discovery experience is frustrating due to the abundance of unorganized and non-prioritized information. Not being able to locate specific brands, or being unfamiliar with the ones presented, on occasion, this frustration resulted in users abandoning the process.',
                solution: 'The approach for this project involves restructuring the discovery page.  The new page includes a comprehensive brand catalog, enhanced search options, improved filtering, and sorting. We also added a tagline to aid recognition of the brand\'\s card segment. Additionally, we implemented a carousel to showcase key categories, with an option to explore more, ensuring scalability for future updates. This simple yet effective solution enhances user experience and discovery.',
                },
            ],
        }
    }  

}
</script>

<template>
    <div class="theproject Casebits">
        <Projectbrief  v-for="project in projects" v-bind="project" :key="project.title" /> 
         <section>
                <img class="full" src="">
         </section>
        <div class="process">
            <section>
                <h2><span>let me tell you a story</span><br>
                            Design process</h2>

            </section>
        </div>
    </div>
</template>
