<script setup>


</script>
<script>

export default {
  props: {
    id: String,
    title: String,
    introimg: Object,
    productbrief: String,
    challenge: String,
    basics: String,
    overview: String,
  },
}

</script>
<template>
<div class="intro">
    <div class="wrap" v-motion-slide-bottom>
        <div class="title-image">
            <div class="title">
                <p class="index">{{ id }}</p>
                <h1>{{ title }}</h1>
                <div class="colorsamples">
                    <div class="sample" id="color1"></div>
                    <div class="sample" id="color2"></div>
                    <div class="sample" id="color3"></div>
                </div>
            </div>
            <div class="zoom-out" ref=zoom v-motion-pop><img :src="introimg" alt=""></div>
        </div>
        <div class="brief">
            <div><h2>[product brief]</h2><p>{{ productbrief }}</p></div>
            <div><h2> [the challenge]</h2><p>{{ challenge }}</p></div>
            <div><h2>[the basics]</h2><p>{{ basics }}</p></div>
        </div>
    </div>    
</div>
</template>
<style scoped>

.wrap {
    display: flex;
    gap: 24px;
    padding: 8% 2% 0 2%;
    max-height: 70vh;
    overflow: visible;
    max-width: calc(100% - 58px);

}

.index {
    font-weight: 600;
    padding: 0;
}

.brief {
    display: flex;
    flex-direction: column;
    max-width: 32%;
}

.title {
    max-width: 55%;
    padding: 0 100px;
}

.title-image {
    display: flex;
    flex-direction: column;
    max-width: 60%;
}

h1 {
    text-transform: uppercase;
    font-size: .85rem;
    font-weight: 300;
    line-height: 1.4rem;
}

.colorsamples {
    display: flex;
    flex-flow: row nowrap;
    gap:6px;
}

.sample { 
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

#color1 {
    background-color: var(--color1);
}

#color2 {
    background-color: var(--color2);
}

#color3 {
    background-color: var(--color3);
}

.overview {
    max-width: 40vw;
    align-items: flex-end;
}

@media only screen and (min-width: 1800px) {

    .wrap {
    display: flex;
    gap: 24px;
    padding: 8% 12% 0 12%;

}

    .brief {
    max-width: 25%;
}}

@media only screen and (max-width: 1440px) {
    .title-image {
        max-width: 56%;
    }
}

@media only screen and (max-width: 1024px) {

    .wrap {
    flex-direction: column;
    max-height: fit-content;
    padding: 8% 2%;
    }

    .title, .brief {
    padding: 0 80px;
}

    .brief { 
    max-width: unset;
}
}

@media only screen and (max-width: 900px) {
    .title-image {
    max-width: 100%;
}
}

@media only screen and (max-width: 720px) {

.title, .brief {
    padding: 0 32px;
}

.title {
    max-width: 76%;
}
}

</style>
