<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_141)">
<path d="M14.0001 14.0584C13.7993 13.6968 13.5081 13.395 13.1579 13.1823C13.2433 13.0919 13.3198 12.9913 13.3838 12.8831C13.3889 12.8767 13.3926 12.8704 13.3951 12.864C13.5407 12.6144 13.6236 12.3241 13.6236 12.0134C13.6236 11.7027 13.5407 11.4123 13.3951 11.1628C13.2583 10.9259 13.0663 10.7273 12.8366 10.5834C12.583 10.4229 12.2843 10.3312 11.9655 10.3312C11.6467 10.3312 11.3479 10.4229 11.0944 10.5834C10.8647 10.7273 10.6726 10.9259 10.5358 11.1628C10.3902 11.4123 10.3074 11.7027 10.3074 12.0134C10.3074 12.3241 10.3902 12.6157 10.5358 12.8653C10.5383 12.8716 10.5421 12.8767 10.5459 12.8831C10.6099 12.9913 10.6864 13.0906 10.7731 13.1811C10.4229 13.3937 10.1317 13.6968 9.93082 14.0584C9.75133 14.3818 9.64464 14.7524 9.6346 15.1484C9.96597 15.4031 10.34 15.6068 10.7442 15.7431C11.1283 15.8742 11.5387 15.9443 11.9655 15.9443C12.3922 15.9443 12.8027 15.8742 13.1868 15.7431C13.591 15.6068 13.965 15.4031 14.2964 15.1484C14.2863 14.7524 14.1796 14.3818 14.0001 14.0584ZM11.9655 11.3499C12.3257 11.3499 12.6194 11.6479 12.6194 12.0134C12.6194 12.3788 12.3257 12.6768 11.9655 12.6768C11.6052 12.6768 11.3115 12.3788 11.3115 12.0134C11.3115 11.6479 11.6052 11.3499 11.9655 11.3499ZM11.9655 14.9256C11.5337 14.9256 11.1245 14.8275 10.758 14.6531C10.9676 14.1896 11.4295 13.8674 11.9655 13.8674C12.5014 13.8674 12.9621 14.1896 13.1717 14.6531C12.8052 14.8288 12.396 14.9256 11.9655 14.9256Z" />
<path d="M17.4958 5.4173H13.3951V3.26402C13.3951 2.84254 13.0575 2.5 12.642 2.5H11.2889C10.8735 2.5 10.5358 2.84254 10.5358 3.26402V5.4173H6.50415C5.95061 5.4173 5.5 5.87444 5.5 6.436V20.4813C5.5 21.0429 5.95061 21.5 6.50415 21.5H17.4958C18.0494 21.5 18.5 21.0429 18.5 20.4813V6.436C18.5 5.87444 18.0494 5.4173 17.4958 5.4173ZM13.3951 7.84817H13.8294C14.0604 7.84817 14.2474 8.03917 14.2474 8.2722C14.2474 8.46193 14.1244 8.62238 13.955 8.67586C13.9148 8.68987 13.8734 8.69623 13.8294 8.69623H13.3951V7.84817ZM11.54 3.5187H12.391V8.69623H11.54V3.5187ZM17.4958 20.4813H6.50415V6.436H10.5358V6.82947H10.1015C9.31703 6.82947 8.6794 7.47762 8.6794 8.2722C8.6794 8.70005 8.86391 9.08461 9.15511 9.34947C9.40615 9.57613 9.73752 9.71493 10.1015 9.71493H13.8294C14.1934 9.71493 14.5248 9.57613 14.7759 9.34947C15.0671 9.08461 15.2516 8.70005 15.2516 8.2722C15.2516 7.47762 14.6139 6.82947 13.8294 6.82947H13.3951V6.436H17.4958V20.4813ZM10.5358 7.84817V8.69623H10.1015C10.0576 8.69623 10.0162 8.68987 9.97601 8.67586C9.80656 8.62238 9.68355 8.46193 9.68355 8.2722C9.68355 8.03917 9.87057 7.84817 10.1015 7.84817H10.5358Z" />
<path d="M14.7046 16.7147H9.2264C8.9451 16.7147 8.71705 16.9427 8.71705 17.224C8.71705 17.5053 8.9451 17.7334 9.2264 17.7334H14.7046C14.9859 17.7334 15.2139 17.5053 15.2139 17.224C15.2139 16.9427 14.9859 16.7147 14.7046 16.7147Z" />
<path d="M13.787 18.1268H10.1439C9.86264 18.1268 9.6346 18.3549 9.6346 18.6362C9.6346 18.9175 9.86264 19.1455 10.1439 19.1455H13.787C14.0683 19.1455 14.2964 18.9175 14.2964 18.6362C14.2964 18.3549 14.0683 18.1268 13.787 18.1268Z" />
</g>
<defs>
<clipPath id="clip0_1_141">
<rect width="13" height="19" fill="white" transform="translate(5.5 2.5)"/>
</clipPath>
</defs>
</svg>



</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>