<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_75)">
<path d="M12.006 20.5L11.6597 20.2227C9.56047 18.5462 7.87596 17.0834 6.65586 15.8746C4.90021 14.1371 3.02778 12.0317 3.02778 9.02717C3.02778 6.0226 5.34315 3.5 8.29877 3.5C9.71349 3.5 11.0316 4.07915 12.002 5.10918C12.9738 4.0921 14.3295 3.5 15.7428 3.5C18.6756 3.5 20.9736 5.92802 20.9736 9.02717C20.9736 12.1263 19.0327 14.2512 17.3428 15.8798C15.7831 17.3814 14.0905 18.7898 12.3497 20.2188L12.006 20.5Z" />
</g>
<defs>
<clipPath id="clip0_1_75">
<rect width="17.9444" height="17"  transform="translate(3.02778 3.5)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>