<template>
    <div class="container">
        <button>
            <span class="button-text">Back to list</span>
        </button> 
    </div>
</template>
<style scoped>

* {
    box-sizing: border-box;

}

.container {
    align-self: center;
    justify-self: center;
    max-width: fit-content;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 8px 16px;
    background: transparent;
    border-radius: 2.2rem;
    border: solid .7px;
    transition: all 500ms;
}

.button-text {

   color: var(--main-color);
   text-transform: uppercase;
   text-align: center;
   font-weight: 700;
   font-size: .65rem;
   padding: 0.75rem 0;

}

button:hover {
    background: var(--main-color);
}

button:hover .button-text  {
    color: var(--OnNegativeSurface)
    
}

@media only screen and (max-width: 720px) { 


.container {
    padding: 6%;
}

}
</style>
