<template>
        <div class="case">
        <router-view v-slot="{ Component, route }">
             <component :is="Component" :key="route.path" />
          </router-view>    
        <!-- <router-view/> -->
        </div>
</template>
<script>

// export default {
//     props: ['id']

// }

//     data() {
//         return {
//             id: this.$route.params.id
//         }
//     }
 
</script>
<style scoped>

.case {
    display: flex;
    flex-direction: column;
    margin-left: 58px;
    width: 100vw;
    justify-content: center;
}

hr {
  border: 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  width: 92vw;
  padding: 0;
}


</style>