<template>
    <section class="container">
        <slot />
    </section>
</template>

<style>
.container {
    display: flex;
    flex-direction: column;
    /* background: var(--background); */



}
</style>