<template>
    <div class="backdrop" @click.self="closeModal">
        <div class="modal">
            <div class="icon">
                <IconClose @click.self="closeModal"/>
            </div>
            <div class="slide">
            <img class="slideimg" :src="image"></div>
            
        </div>
    </div>

</template>
<script>
import IconClose from '@/assets/images/vueicons/closeicon.vue';

export default {
    components: {IconClose, },
    props: {
        image: Object,
    },
    methods: {
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>
<style scoped>

.backdrop {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    width: 100%;
    height: 100%;
}

.modal {
    position: static;
    background-color: var(--surface);
    padding: 12px;
    box-shadow: 16px 12px 40px 2px rgba(5, 4, 4, 0.20);
    border-radius: 4px;
    gap: 16px;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 75vh;

   

}

.slide {
    height: calc(100% - 24px - 16px);
    object-fit: contain;
}

.slideimg {
     object-fit: contain;
     height: 100%;
     max-width: 75vw;

}


.icon {
    place-self: flex-end;
    width: 24px;
    height: 24px;
    opacity: .45;
}



@media only screen and (max-width: 1280px) {

.modal {
    position: fixed;
    margin: 0;
    height: unset;
    



}
.slideimg {
     object-fit: contain;
     height: unset;
     max-width: 80vw;
     max-height: 75vh;

}

}




</style>