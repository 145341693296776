<template>
    <div class="logoicon"> 
        <svg id="smile" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M24.0001 8.00008C19.5883 3.58831 12.4108 3.58923 8.00052 7.99948C3.59027 12.4097 3.58815 19.5885 7.99992 24.0002C12.4117 28.412 19.5892 28.4111 24.0007 23.9996C28.4121 19.5882 28.4119 12.4119 24.0001 8.00008ZM8.95737 23.0428C5.07343 19.1588 5.07439 12.8406 8.95804 8.957C12.8417 5.07335 19.1587 5.07359 23.0426 8.95753C26.9266 12.8415 26.9256 19.1597 23.0432 23.0421C19.1607 26.9246 12.8413 26.9267 8.95737 23.0428Z"/>
            <path d="M20.0201 19.7428C19.7805 19.5031 19.3976 19.489 19.126 19.7042C17.1755 21.2412 14.3678 21.1449 12.6128 19.3899C10.8577 17.6348 10.7614 14.8271 12.2984 12.8767C12.5125 12.6039 12.4995 12.2221 12.2598 11.9825C11.9814 11.7041 11.5124 11.7291 11.2609 12.0464C9.29118 14.5306 9.40967 18.1149 11.6487 20.3539C13.8877 22.5929 17.472 22.7114 19.9562 20.7418C20.2723 20.4915 20.2985 20.0212 20.0201 19.7428Z"/>
        </svg>
    </div>
</template>

<style scoped>

#smile {
    padding: 4px;
    }

#smile path{
    fill: var(--main-color);
}

.logoicon:hover #smile{
    animation: spinsmile 1.5s linear;
}

@keyframes spinsmile {
    0%,100% {
      transform: rotate(0deg) 
    }
    50%     {
      transform: rotate(520deg) 
    }
    75%     {
        transform: rotate(520deg) 
      }
  }

</style>