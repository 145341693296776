<template>
    <Preloader/>
    <canvas ref="canvas"></canvas>
  
    <Navbar/>
    <div class="sidebar"> 
      <Sidebar/>
    </div>

    <div class="wrapcontent"  >
        <div class="content"> 
          <router-view v-slot="{ Component, route }">
           <transition name="fade" mode="out-in">
             <component :is="Component" :key="route.path" />
           </transition> 
          </router-view>
        </div>
    </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Preloader from '@/components/Preloader.vue'


export default {
    name: 'App',
    components: { 
    Navbar, Sidebar, Preloader,
  },
  
  
  // //Nav
  //   data() {
  //   return {
  //   showSideMenu: false,
  //   }
  // },

  // created () {
  //  window.addEventListener('scroll', this.handleScroll);
  //  window.addEventListener('click', this.toggleSideMenu);
  // },

 // methods: {
    // toggleSideMenu () {
    //   this.showSideMenu = !this.showSideMenu;
    // },
    
    // handleScroll (event) {
    //   const SideMenu = document.querySelector(".SideMenu")
    //   if(SideMenu.classList.contains('open')) {
    //     this.showSideMenu = false;
    //     event.stopPropagation();
    // }},
  methods: {
    //SideMenu


    
    
    //Noise

  generateNoise() {
      this.noise = document.createElement('canvas')
      this.noise.height = window.innerHeight * 2
      this.noise.width  = window.innerWidth * 2
      let noiseContext = this.noise.getContext('2d')
      let noiseData = noiseContext.createImageData(
      this.noise.width,
      this.noise.height
      )
      let buffer32 = new Uint32Array(noiseData.data.buffer)
      let len = buffer32.length - 1
      while (len--) {
      buffer32[len] = Math.random() < 0.5 ? 0 : -1 >> 0
  }
      noiseContext.putImageData(noiseData, 0, 0)
    },
  moveNoise() {
      let canvas  = this.$refs.canvas
      let context = canvas.getContext('2d')
      let x = Math.random() * canvas.width
      let y = Math.random() * canvas.height
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.drawImage(this.noise, -x, -y)
      requestAnimationFrame(this.moveNoise)
      }
    },
  mounted() {
    this.$refs.canvas.height = window.innerHeight
    this.$refs.canvas.width  = window.innerWidth
    this.generateNoise()
    requestAnimationFrame(this.moveNoise)
  },
}

</script>
<style>

canvas{
  height: 100%;
  left: 0;
  mix-blend-mode: overlay;
  opacity: 0.2;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}

.sidebar{
  position: fixed;
  width: 58px;
  background-color:var(--surface);
  height: 100vh;
  max-height: 100dvh;
  z-index: 1;
}

.wrapcontent{
  display: flex;
  overflow: hidden;
  padding-top: 72px ;
}

.content{
  display: flex;
 /* will-change: transform, opacity;  */
}

.fade-enter-from,
.fade-leave.to {
  opacity: 0;
}

.fade-enter-active,
.fade-leve-active {
  transition: opacity 1.5s;
}

.toggle {
  position: fixed;
  z-index: 99;
  padding: 10px 0;
}

.toggle:hover ~ .sidebutton {
    bottom: 204px;
}

.sidebutton {
  display: inline-block;
  width: 24px;
  height: 24px;
  z-index: 2;
  position: fixed;
  padding: 10px 0;
}


</style>
