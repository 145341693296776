<template>
 <div class="project-container" >
       <div class="navigation">  
          <Goback @click="$router.push({name: 'archieve'})"/>  
          <div class="arrows">
            <Previous @click="$router.push({ name: 'apv' })"/>
            <Next @click="$router.push({name: 'int'})"/> 
          </div>  
        </div>

    <div class="projectwrap">
        <div class="info">       

            <h1> Belma - packaging & branding redesign </h1>
            <p class="note">After operating in the food industry for more than a decade, the company realized the importance of updating its branding identity. The company faced several challenges, such as low brand recognition and inconsistency in its product\'\s visual representation. Therefore, this project was initiated to modernize its visual identity while retaining its connection with its history.</p>   
        </div>      
        <div class="filegallery">
          <div @click="toggleModal(image)"  v-for="image in images" :key="image">
            <img class="figure" :src="image" /></div>
        </div>
    </div>
    <div class="modalwrap" v-if="showModal">
      <Modal @close="toggleModal" :image="currentImage" />
    </div>
 </div> 
</template>
<script>
import Goback from '@/components/Goback-button.vue';
import Next from '@/components/Next-button.vue';
import Previous from '@/components/Previous-button.vue';
import Modal from '@/components/modal.vue';

// Belma
import BelGif from '@/assets/images/visualjournal/BEL/b09-xl.gif';
import Bel01 from '@/assets/images/visualjournal/BEL/P2m.png';
import Bel02 from '@/assets/images/visualjournal/BEL/P3.png';
import Bel03 from '@/assets/images/visualjournal/BEL/P4.png';
import BelGif2 from '@/assets/images/visualjournal/BEL/b22.gif';
import Bel04 from '@/assets/images/visualjournal/BEL/b23.png';
import Bel05 from '@/assets/images/visualjournal/BEL/b24.jpg';
import Bel06 from '@/assets/images/visualjournal/BEL/b01l.jpg';
import Bel07 from '@/assets/images/visualjournal/BEL/P1.png';
import Bel09 from '@/assets/images/visualjournal/belma-cover.png';
import Bel10 from '@/assets/images/visualjournal/BEL/Expandido-biscoito-leite-l.png'; 
import Bel11 from '@/assets/images/visualjournal/BEL/cartazbelma.png';
import Bel12 from '@/assets/images/visualjournal/BEL/BELMA-ROSQUINHAS-m.jpg';
import Bel13 from '@/assets/images/visualjournal/BEL/BISCOITO-DE-COCO-m.jpg';
import Bel14 from '@/assets/images/visualjournal/BEL/BISCOITO-DE-LEITE-m.jpg';
import Bel15 from '@/assets/images/visualjournal/BEL/photobelma3.jpg';
import Bel16 from '@/assets/images/visualjournal/BEL/Recheado2-02.jpg';
import Bel17 from '@/assets/images/visualjournal/BEL/BISCOITO-SABOR-MARIA-m.jpg';
import Bel18 from '@/assets/images/visualjournal/BEL/CREAM-CRACKER-m.jpg';
import Bel19 from '@/assets/images/visualjournal/BEL/Recheado-Choconegro.jpg';
import Bel20 from '@/assets/images/visualjournal/BEL/Recheado-de-Chocolate-m.jpg';

export default { 
    props: ['filecomponent'],
    components: { Goback, Next, Previous, Modal },
    
    data () {
         return {
            images: [ Bel09, Bel07, Bel01, Bel02, Bel03, BelGif, BelGif2, Bel04, Bel05, Bel06, Bel10, Bel16, Bel15, Bel11, Bel12, Bel13, Bel14, Bel17, Bel18, Bel19, Bel20 ],
            showModal: false,
            currentImage: {}
          }
      },
          methods: {
      toggleModal(image) {
        this.showModal = !this.showModal;
        this.currentImage = image;
      } 
    }
}
</script>
<style>
* {
  box-sizing: border-box;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
}

.arrows {
  display: flex;
  align-items: center;
}

.project-container {
    width: calc(100vw - 58px);
    margin-left: 58px;
    padding: 20px;
    position: relative;
    background: var(--background);
    border-radius: 4px;
    height: auto;

}

.projectwrap {
  padding: 10px 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

h1 {
    font-size: 1rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 140%;
    padding: 0;
}

.info {
    padding-right: 24px ;
    width: 280px;

}
 .info p {
    font-size: .65rem;
    line-height: 1.6;

}

.filegallery {
    columns: 6 220px;
    gap: 0;
}

.figure{ 
  display: flex;
  padding: 6px;
  object-fit: contain;
  object-position: left top;
}



@media only screen and (min-width: 2001px) {
    .projectwrap {
  padding: 10px 10%;
  } 

  .navigation {
  padding: 0 9.5%;
  }
} 

@media only screen and (max-width: 1024px) { 

  .projectwrap {
  padding: 10px 16px;
  flex-direction: column;
  margin: 2%;
  }

  .info {
  padding: 0 40px 24px 6px;
  min-width: 80vw;
  padding: 0 ;
  }


  .filegallery {
    columns: 3 160px;
  }
  .figure{ 
  padding: 4px;
  }
}

@media only screen and (max-width: 720px) {
  .project-container {
    padding: 0;
  }  

  .projectwrap {
  padding: 10px 0;
  }

  .navigation {
    padding:0 20px 0 0;
  }

  .filegallery {
    columns: 2 120px;
  }

  .info {
    padding: 0 6% 48px 6% ;
  }
    .info p {
  min-width: 65vw;

  } 
    
}

</style>