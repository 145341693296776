<template>
  
  <div class="home">
    <section class="intro" >
        <div class="eye"  v-motion-slide-bottom  >
            <h1>Designer & <br>Ux engineer</h1>
            <p>A designer with a hint of code, working and having fun across Tech, Communication & Design. Passionate for building things and simplifying complex processes, I've navigated Design in its many dimensions.</p>
            <div class="location">
              <span class="headline">Currently based in</span>
              <p class="info">AMSTERDAM/NL</p>
           </div> </div>  
           
        <div class="photo">
          <img src="@/assets/images/photo1.png" alt=""  v-motion-slide-bottom ></div>
       
    </section>
    <section class="case-list" id="case-list"  v-motion-slide-visible-once-bottom>

     <Selectedcases  />
       
    </section>
  

  <Footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import Arrowdown from '@/assets/navigationicons/Arrowdown.vue'
import Selectedcases from '@/components/Selectedcases.vue'
import Footer from '@/components/Footer.vue'


export default {
     components: {
    Arrowdown, Selectedcases, Footer
  },
}

</script>

<style scoped>

img {
  min-width: 100%;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background);
}


/* -------- Intro -------- */ 

.intro {
  transition: background-color 4s ;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  display: grid;
  grid-template-columns: var(--80X12);
  align-items: end;
  overflow: hidden;
  margin-left: 58px;
   padding: 0 1.2%;

}

.eye {
    grid-column: 2 / span 8;
    color: var(--main-color);
    font-family: Sora;

}

.eye p {  
    font-weight: 300;
    line-height: 180%; /* 1.75rem */
    letter-spacing: 0.05rem;
    padding-bottom: 24px;
        max-width: 320px;
}

.photo {    
  grid-column: 14 / span 2;
  filter: grayscale(1);
  max-height: 50vh;
  }

h1 {
    font-family: 'Sora';
    text-transform: uppercase;
    font-size: 3rem;  
    font-weight: 300;
    line-height: 120%;
    margin: 0;
    grid-column: 2 / span 8;
    color: var(--main-color);
    align-self: flex-start;
    padding: 8px 0;
}

p {
  font-weight: 700;
  color: var(--main-color);
  font-size: .75rem;
}

.headline{
  font-size:.6rem;
  letter-spacing: 0.05rem;

}


/* -------- Cases -------- */  



.case-list {
  scroll-margin-top: 8rem;
  scroll-snap-type: y mandatory;
  padding: 10% 0 4% 0;
  margin-left: 58px;
}

hr {
  grid-column: 3 / span 12;
  width: 100%;
  border-width: solid .5px;
  border-image-slice: 1;
  border-image-source: var(--gradient-border);
}



@media only screen and (max-width: 720px) { 
 
  .intro {
    grid-template-rows:  auto auto auto auto;
    }

  .photo {
    grid-column: 10/ span 8;
    grid-row: 1;
    padding-bottom:48px;
    }

  h1 {
    font-size: 2rem;
    grid-column: 3/ span 12;
    grid-row: 2;
    }

  .eye {
    grid-column: 3 / span 12;
    grid-row: 3;
  }
}


</style>
