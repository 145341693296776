<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_54)">
<path d="M12.5535 11.6705C12.1782 11.4727 11.7825 11.3156 11.3729 11.1964C12.3037 10.5957 12.9403 9.58301 13.029 8.40431C13.0366 8.30925 13.0392 8.21419 13.0392 8.12547C13.0392 7.75792 12.9859 7.3967 12.8794 7.05196C12.4051 5.50824 11.0001 4.47275 9.38337 4.47275C7.36717 4.47275 5.72758 6.11153 5.72758 8.12547C5.72758 9.42839 6.39584 10.5653 7.41536 11.2117C6.81303 11.3878 6.2386 11.6477 5.71617 11.9873C3.81028 13.2281 2.85291 15.2839 3.01902 17.7757L3.03424 18.0013L3.2181 18.1318C4.97689 19.3777 7.18077 20.0013 9.38591 20.0013C11.5911 20.0013 13.7937 19.3777 15.5524 18.1318L15.73 18.0051L15.7503 17.7871C15.7667 17.5957 15.7756 17.403 15.7756 17.2167V17.2053C15.7122 14.6945 14.5672 12.7288 12.5535 11.6705ZM6.67735 8.12674C6.67735 6.63498 7.89214 5.42205 9.38464 5.42205C10.583 5.42205 11.6228 6.19011 11.974 7.33333C12.0539 7.58808 12.0919 7.85424 12.0919 8.12674C12.0919 8.19265 12.0894 8.26235 12.0843 8.3346C11.9791 9.71356 10.8619 10.7845 9.47975 10.8314C9.44804 10.8314 9.41634 10.8314 9.38337 10.8314C9.3504 10.8314 9.31997 10.8314 9.287 10.8314C7.8224 10.782 6.67481 9.59696 6.67481 8.12674H6.67735ZM14.8208 17.4842C11.7293 19.5501 7.04889 19.5513 3.95357 17.488C3.88003 15.455 4.68524 13.7909 6.23353 12.7845C7.94414 11.6717 10.3078 11.5615 12.111 12.5095C13.8051 13.4005 14.7701 15.0748 14.8271 17.2231C14.8271 17.3092 14.8246 17.3954 14.8195 17.4842H14.8208Z" />
<path d="M21 15.4246V15.436C21 15.6046 20.9911 15.7769 20.9772 15.9506L20.9569 16.1686L20.7794 16.2953C19.4593 17.2307 17.859 17.7744 16.2131 17.9278L16.222 17.8289C16.2397 17.6223 16.2486 17.4183 16.2486 17.2129V17.1927C16.2461 17.1141 16.2435 17.0368 16.2397 16.9594C17.613 16.8162 18.9432 16.3802 20.0477 15.6477C20.0515 15.5792 20.0528 15.5108 20.0528 15.4423C20.002 13.5412 19.1499 12.0621 17.6536 11.2738C16.189 10.5044 14.311 10.5234 12.8426 11.289C12.6714 11.1965 12.4964 11.1128 12.3189 11.0368C12.5675 10.7871 12.7843 10.5032 12.9618 10.1952C13.0937 10.142 13.2281 10.0951 13.3638 10.0532C13.2826 9.99873 13.204 9.93916 13.1279 9.87706C13.2763 9.56147 13.3853 9.22307 13.4475 8.86946C13.8621 9.34854 14.4683 9.65653 15.1543 9.67934C15.2126 9.67681 15.2697 9.67681 15.3267 9.67934C16.5339 9.63625 17.5116 8.69962 17.6029 7.4943C17.608 7.43093 17.6105 7.37009 17.6105 7.31306C17.6105 7.07478 17.575 6.84157 17.5065 6.61851C17.1996 5.61724 16.2892 4.9455 15.2418 4.9455C14.2945 4.9455 13.4754 5.50317 13.0962 6.30799C12.9415 5.98606 12.745 5.68948 12.5167 5.42586C13.1153 4.56401 14.1145 3.99873 15.2418 3.99873C16.7076 3.99873 17.9833 4.93916 18.4132 6.33967C18.5095 6.65273 18.559 6.98099 18.559 7.31306C18.559 7.39417 18.5552 7.47909 18.5501 7.56654C18.4715 8.59823 17.9288 9.4905 17.1337 10.038C17.4672 10.1407 17.7893 10.2725 18.0962 10.4347C19.9133 11.3891 20.9455 13.161 21.0025 15.4233L21 15.4246Z" />
</g>
<defs>
<clipPath id="clip0_1_54">
<rect width="18" height="16" fill="white" transform="translate(3 4)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>