<template>
    <div class="caseIis">
      <Intro class="intro" v-for="intro in intros" v-bind="intro" :key="intro.title"  :class="{ 'intro--hidden': !showIntro }" />   
      <div class="intro-space"></div> 
      <Container>    
         <CaseCover/>
         <Discover/>
         <Define/>
         <Design/>

      </Container>  

    </div>
      <Footer/>
</template>
<script>
import Container from '@/components/Container.vue'
import Footer from '@/components/Footer.vue'
import CaseCover from '@/components/CaseCover.vue'
import Intro from '@/components/CaseComponents/Intros.vue'
import Discover from '@/assets/case01/IisDiscover.vue'
import Define from '@/assets/case01/IisDefine.vue'
import Design from '@/assets/case01/IisDesign.vue'

export default {
  components: {
   Container, CaseCover, Intro, Discover, Define, Design, Footer
},

 data () {
    return {
      intros : [
        {
          id: '[01]', 
          title: 'How to grade feelings? - Core experience', 
          productbrief: ' It\'\s safe? is a crowdsourced community focused on the safety of LGBTQ+ people. It opens up space for sharing experiences. creating a sense of community, allowing people to help each other on their journey, highlighting areas and places of concern, and promoting welcome points.',
          challenge: 'Assisting users in translating elusive emotions and sensations into measurable elements that can be quantified and shared. Simultaneously, reshaping the app\'\s ambiance into a space that fosters connection, providing a perceived sense of safety.',
          basics: 'Research, concept & design',
        },
      ],

      showIntro: true,
      lastScrollPosition: 700
    }
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
  onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    this.showIntro = currentScrollPosition < 700;

  }
}

}


</script>
<style scoped>

* {  max-width: 100%;
    box-sizing: border-box;
}

.caseIis{
    position: relative;
    overflow: hidden;
      margin-left: 58px;
}

.intro {
  z-index: -2;
  position: fixed ;
}

.intro-space {
  min-height: 85vh;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .intro-space {
  min-height: 110vh;

  }

}

</style>