<template>
    <div class="resume">
        <div class="container" v-motion-slide-bottom>
         <div class="photo" >
         </div>
         <dl id="education">
            <dt>Education</dt>
            <dd><span>Master in Communication Design Project and research</span> | 2018 - 2021 - University of Lisbon - PT</dd>
            <dd><span>Bachelor of Graphic Design Visual Arts</span> | 2013 - 2016 - Federal University of Goias - BR</dd>
         </dl>
         <dl id="tools">
            <dt>Tools</dt>
                <dd><span>Design</span> - Figma, Sketch, Framer, Maze, Zeplin, Adobe Suite (Ps, Ai, Id, Ae...)</dd>
                <dd><span>Code</span> - HTML5 + CSS3, JS, Vue.JS</dd>
         </dl>
        <dl id="skills">
            <dt>Skills</dt>
                <dd><span>Prototyping</span> - From sketchs and initial wireframes to high-fidelity and testable mockups.</dd>
                <dd><span>Design system</span> - Reusable component library on Figma, documentation and guidelines.</dd>
                <dd><span>Ux architecture</span> - User journey, intuitive flows, and information structure.</dd>
                <dd><span>Research & Test</span> - Surveys, competitor analysis, journey maps and interviews. Foco in usability tests and validation.</dd>
              </dl>
         <dl id="experience">
            <dt>Experience</dt>
                <dd><span>Is it safe? - App | 2022 - 2024</span>
                    <div>
                    <dd>• Performed as a full-circle designer, from initial research through implementation;</dd>
                    <dd>• Revised the mobile app’s architecture, continuously iterating to improve users experiences;</dd>
                    <dd>• Redesigned key flows, based on user feedback, improving the overall application experience;</dd>
                    <dd>• Conceived the current design system, assuring consistency across the product and facilitating
                    handoffs.</dd></div>
                </dd>
                <dd><span>Bits of Stock | 2022</span>
                    <div>
                    <dd>• Conducted user testing and interviews to gain insights and validate new designs;</dd>
                    <dd>• Revised key flows to enhance users experience and address pain points identified in research;</dd>
                    <dd>• Created solutions, from wireframes to high-fidelity mockups and prototypes.</dd>
                    </div>
                </dd>
                <dd><span>Creative Act - Independent Designer | 2020 - 2022</span></dd>
                <dd><span>Agency - Reinvent | 2018</span></dd>
                <dd><span>RR Publicidade | 2017</span></dd>
                <dd><span>Bull Propaganda | 2015 - 2017</span></dd>
            

         </dl>
        </div>

    </div>
</template>
<style scoped>

* {
    color: var(--main-color);
}
.resume {
    max-width: calc(100% - 58px);
    width: 100vw;
    margin-left: 58px;
    padding: 16% 20%;
}

.container {
    padding: 0 6%;
    display: grid;
    grid-template-columns: 256px 1fr 1fr;
    gap: 8px 60px;
    
}

#education, #tools, #skills {
  grid-column-start: 1;
}

#experience {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
}

dt {
    text-transform: uppercase;
    font-weight: 600;
    font-size: .7;
    opacity: .5;
}

dd {
    margin: 0;
    padding-bottom: 4px;
}

dd::before {
    content: "";
}
dd span {
    font-weight: 500;
}

.photo {
    filter: grayscale(1);
    background-image: url(@/assets/images/photo1.png);
    background-size: cover;
    width: 180px;
    height: 180px;
    border-radius: 90px;
    grid-column-start: 1;
}
</style>