<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_159)">
<path d="M17.391 10.7295H6.60904C5.72139 10.7295 5 11.4509 5 12.3386V19.5498C5 20.4374 5.72139 21.1588 6.60904 21.1588H17.391C18.2773 21.1588 19 20.4374 19 19.5498V12.3386C19 11.4509 18.2773 10.7295 17.391 10.7295ZM17.9273 19.5498C17.9273 19.8461 17.686 20.0861 17.391 20.0861H6.60904C6.31405 20.0861 6.07269 19.8461 6.07269 19.5498V12.3386C6.07269 12.0422 6.31405 11.8022 6.60904 11.8022H17.391C17.686 11.8022 17.9273 12.0422 17.9273 12.3386V19.5498Z" />
<path d="M17.1027 7.90432V8.74638C17.1027 9.04271 16.8627 9.28273 16.5663 9.28273C16.27 9.28273 16.03 9.04271 16.03 8.74638V7.90432C16.03 5.72139 14.2547 3.92999 12.0704 3.9139H12.0382C10.9803 3.9139 9.98401 4.32286 9.23178 5.06838C8.47017 5.82061 8.05048 6.82492 8.0478 7.89627L8.04109 10.7295H6.9684L6.9751 7.89359C6.97779 6.22019 7.78767 4.67551 9.21569 3.69533C10.0457 3.12547 11.0379 2.8412 12.0436 2.8412H12.0798C14.85 2.86266 17.1027 5.13409 17.1027 7.90432Z" />
<path d="M13.4595 14.736C13.4595 15.3542 13.0747 15.8838 12.5317 16.0984V17.7047C12.5317 18.0011 12.2916 18.2411 11.9953 18.2411C11.699 18.2411 11.459 18.0011 11.459 17.7047V16.0984C10.9159 15.8852 10.5297 15.3542 10.5297 14.736C10.5297 13.9275 11.1868 13.2705 11.9953 13.2705C12.8039 13.2705 13.4595 13.9275 13.4595 14.736Z" />
</g>
<defs>
<clipPath id="clip0_1_159">
<rect width="14" height="18.3176" transform="translate(5 2.8412)"/>
</clipPath>
</defs>
</svg>


</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>