<template>
    <div class="container">
        <button>
            <svg class="iconshape" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-1.19228e-06 7.50237L7.06169 0.170807C7.17137 0.056936 7.3148 -1.63238e-07 7.45963 -1.50576e-07C7.60447 -1.37915e-07 7.74789 0.0569361 7.85757 0.170807C8.07693 0.39855 8.07693 0.767901 7.85757 0.997104L2.20645 6.86273C2.20163 6.86774 2.20518 6.87608 2.21212 6.87608L15.416 6.87608C15.7386 6.87608 16 7.13753 16 7.46004V7.46004C16 7.78254 15.7386 8.04399 15.416 8.04399L2.13067 8.04399C2.12368 8.04399 2.12011 8.05238 2.12496 8.05741L7.85054 14.0033C8.07131 14.231 8.07131 14.6004 7.85054 14.8281C7.63118 15.0573 7.27543 15.0573 7.05607 14.8281L-1.19228e-06 7.50237Z" />
            </svg>
        </button>
    </div>
</template>
<style scoped>


* {
    box-sizing: border-box;

}  

.container {
    display: flex;
    align-self: center;
    justify-self: center;
    padding: 40px 5px 40px 40px;
    max-width: fit-content;
}


button {
    position: relative;
    cursor: pointer;
    background: transparent;
    border-radius: 2.2rem;
    border: solid .7px;
    transition: all 500ms;
    padding: 8px 8px;
    height: 32px;
    width: 32px;
}

.iconshape {
    fill: var(--main-color);
}

button:hover {
    background: var(--main-color);
}

button:hover .iconshape  {
    fill: var(--OnNegativeSurface)
}


</style>
