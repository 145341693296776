<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_46)">
<path d="M19.8437 19.0929L17.4638 16.7253C18.5623 15.489 19.2684 13.9008 19.3805 12.1586C19.6769 7.54275 15.8354 3.72103 11.1957 4.01582C7.35953 4.26016 4.2615 7.34224 4.0159 11.1573C3.71958 15.7745 7.56108 19.5962 12.2021 19.3001C13.9106 19.1912 15.4723 18.5193 16.7016 17.4702L19.0882 19.8432C19.1923 19.9481 19.3285 19.9999 19.466 19.9999C19.6035 19.9999 19.7383 19.9481 19.8437 19.8432C20.0519 19.636 20.0519 19.3001 19.8437 19.0929ZM11.0942 18.2271C7.92814 17.9469 5.37738 15.4106 5.09574 12.2608C4.72734 8.15094 8.17374 4.72227 12.3035 5.08877C15.4696 5.37029 18.0191 7.9066 18.302 11.0564C18.6704 15.165 15.224 18.5936 11.0942 18.2271Z" />
<path d="M13.4516 7.02896V7.0688C13.4516 7.40874 13.1272 7.64777 12.7975 7.56012C11.3346 7.17105 9.68215 7.63183 8.64236 8.70213C8.43947 8.91061 8.10845 8.92256 7.90156 8.71673L7.8802 8.69549C7.67731 8.49497 7.67331 8.17627 7.86685 7.97443C9.16292 6.6266 11.2358 6.0317 13.0818 6.5363C13.3021 6.59606 13.4516 6.80056 13.4516 7.02763V7.02896Z"/>
</g>
<defs>
<clipPath id="clip0_1_46">
<rect width="16" height="16" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>

</template>

<style scoped>

path {
    fill: var(--main-color)
}

</style>