<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_62)">
<path d="M12.0005 3.5C7.31322 3.5 3.5 7.31322 3.5 11.9995C3.5 14.6011 4.67455 16.9328 6.52075 18.4916C8.00118 19.7445 9.91487 20.5 12.0005 20.5C14.0862 20.5 15.9988 19.7445 17.4793 18.4916C19.3254 16.9328 20.5 14.6011 20.5 11.9995C20.5 7.31322 16.6868 3.5 12.0005 3.5ZM14.3388 14.2223C15.731 14.9549 16.5496 16.3047 16.6585 18.0377C15.3696 19.0348 13.7531 19.6292 12.0005 19.6292C10.248 19.6292 8.62602 19.0337 7.33608 18.0333C7.40139 16.4985 8.08283 15.2455 9.29221 14.4585C10.7618 13.5017 12.7897 13.407 14.3388 14.2223ZM9.67321 10.4581C9.67321 9.17791 10.716 8.13508 11.9973 8.13508C13.026 8.13508 13.9197 8.79365 14.2201 9.77662C14.2887 9.99542 14.3224 10.224 14.3224 10.4581C14.3224 10.5147 14.3203 10.5756 14.3159 10.6366C14.2256 11.8198 13.2665 12.7397 12.08 12.781C12.0528 12.78 12.0245 12.78 11.9973 12.78C11.9701 12.78 11.9429 12.78 11.9156 12.781C10.6573 12.7375 9.67321 11.7208 9.67321 10.4581ZM17.4063 17.378C17.1396 15.6418 16.2046 14.2843 14.7176 13.5017C14.3954 13.3318 14.0557 13.1969 13.7041 13.0945C14.5031 12.5797 15.0507 11.7099 15.1269 10.6975C15.1323 10.6159 15.1356 10.5343 15.1356 10.4581C15.1356 10.1424 15.0899 9.83214 14.9984 9.53605C14.5913 8.21128 13.3852 7.32084 11.9973 7.32084C10.2676 7.32084 8.85897 8.72834 8.85897 10.4581C8.85897 11.5771 9.43264 12.5535 10.3078 13.1098C9.79077 13.2611 9.29766 13.4832 8.84917 13.776C7.57991 14.6 6.79942 15.8475 6.58062 17.365C5.21557 15.9858 4.37085 14.0895 4.37085 11.9995C4.37085 7.79327 7.79327 4.37085 12.0005 4.37085C16.2078 4.37085 19.6292 7.79327 19.6292 11.9995C19.6292 14.096 18.779 15.9977 17.4063 17.378Z" />
</g>
<defs>
<clipPath id="clip0_1_62">
<rect width="17" height="17" transform="translate(3.5 3.5)"/>
</clipPath>
</defs>
</svg>
</template>
<style scoped>

path { 
    fill: var(--main-color)
}

</style>