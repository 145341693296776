<template>
    <div class="container">
        <router-link  to="/">
        <button>
            <span class="button-text">Go back home</span>
        </button> </router-link>
    </div>
</template>
<style scoped>


* {
    box-sizing: border-box;

}

.container {
    align-self: center;
    justify-self: center;
    padding: 40px;
}

button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 12px 18px;
    background: transparent;
    border-radius: 2.2rem;
    border: solid .7px;
    transition: all 500ms;
}

.button-text {

   color: var(--main-color);
   text-transform: uppercase;
   text-align: center;
   font-weight: 700;
   padding: 0.75rem 0;
   min-width: 120px;

}

button:hover {
    background: var(--main-color);
}

button:hover .button-text  {
    color: var(--OnNegativeSurface)
    
}
</style>
