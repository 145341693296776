<template>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
              <div class="text-block textbox half2">
            <p>
         We gained valuable insights and identified pain points by doing this. These insights were crucial in establishing a strong foundation for the project and helped us determine actionable steps to enhance the user journey and create a better structure for the product with clear flows.
            </p>
        </div>
         <div class="lspace"></div>
         <img class="center" id="sitemap" src="@/assets/case01/sitemap.jpg" alt="app sitemap">
         <div class="lspace"></div>

    </div>
    <div class="tile grid" v-motion-slide-visible-once-bottom>
         <div class="lspace"></div>
        <img class="center" src="@/assets/case01/flow.png" alt="flow">
        <div class="lspace"></div>
        <img class="center" src="@/assets/case01/userflow.png" alt="userflow">

    </div>
        

    <div class="tile grid" v-motion-slide-visible-once-bottom>
                <p class="text-block textbox half2">Working closely with our developers, to make sure we meet both user and product needs. We reviewed the flow of the application and rethought the main routes to make the application environment easier for users to understand and navigate. We proceeded to design the screens and components.</p>
        <img class="center" src="@/assets/case01/wireframes.png" alt="userflow">
    </div>


</template>
<script>
import '@/assets/caseslayout.css'

export default {
    components: {  }
}

</script>

<style scoped>

* {
    max-width: 100%;
}

#sitemap{
    width: 100%;
}


</style>