<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_120)">
<path d="M16.1202 19.0581V20.4692H5.08337V6.02658H6.79644V4.94321H5.08337C4.48481 4.94321 4 5.42937 4 6.02658V20.4692C4 21.0678 4.48481 21.5526 5.08337 21.5526H16.1188C16.7174 21.5526 17.2022 21.0678 17.2022 20.4692V19.0581H16.1202Z" />
<path d="M19.7332 5.28582L18.918 4.54778L17.794 3.53076L16.8271 2.6573C16.6781 2.52188 16.4845 2.4474 16.2827 2.4474H7.87982C7.28261 2.4474 6.79645 2.93356 6.79645 3.53076V17.9748C6.79645 18.572 7.28261 19.0581 7.87982 19.0581H18.9166C19.5138 19.0581 20 18.572 20 17.9748V5.88709C20 5.65823 19.9025 5.4402 19.7332 5.28582ZM16.6091 5.06102L16.6131 3.92619L18.3424 5.48895H16.712C16.6768 5.48895 16.6524 5.4727 16.6389 5.45781C16.624 5.44426 16.6077 5.41989 16.6077 5.38332V5.06102H16.6091ZM18.9166 17.9748H7.87982V3.53076H15.5311L15.5257 4.94321V5.37926C15.523 5.61219 15.5893 5.83563 15.7126 6.02657C15.7586 6.09699 15.8114 6.162 15.871 6.22293C15.9455 6.29742 16.0295 6.36106 16.1188 6.41252C16.2976 6.5168 16.4994 6.57232 16.712 6.57232H18.9166V17.9748Z" />
</g>
<defs>
<clipPath id="clip0_1_120">
<rect width="16" height="19.1052"  transform="translate(4 2.4474)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>