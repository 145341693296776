<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_116)">
<path d="M19.2323 4H4.76774C3.82783 4 3.05882 4.75176 3.05882 5.67058V15.2806C3.05882 16.1994 3.82783 16.9512 4.76774 16.9512H9.60111L8.92894 18.8863H8.3237C8.00897 18.8863 7.75406 19.1355 7.75406 19.4431C7.75406 19.5963 7.81815 19.7355 7.92068 19.8371C8.02322 19.9374 8.16563 20 8.3237 20H15.6122C15.9269 20 16.1819 19.7508 16.1819 19.4431C16.1819 19.29 16.1178 19.1508 16.0152 19.0492C15.9127 18.9489 15.7703 18.8863 15.6122 18.8863H15.0582L14.3547 16.9512H19.2323C20.175 16.9512 20.9412 16.2008 20.9412 15.2806V5.67058C20.9412 4.75037 20.175 4 19.2323 4ZM10.1323 18.8863L10.8045 16.9512H13.1443L13.8478 18.8863H10.1323ZM19.8019 15.2806C19.8019 15.5869 19.547 15.8375 19.2323 15.8375H4.76774C4.45443 15.8375 4.1981 15.5869 4.1981 15.2806V13.8634H19.8019V15.2806ZM19.8019 12.7497H4.1981V5.67058C4.1981 5.36431 4.45443 5.11372 4.76774 5.11372H19.2323C19.547 5.11372 19.8019 5.36431 19.8019 5.67058V12.7497Z" />
<path d="M12.4507 14.9437C12.4151 15.133 12.2571 15.2862 12.0648 15.3224C11.7173 15.3878 11.4168 15.0941 11.4838 14.7558C11.5208 14.5678 11.6774 14.4133 11.8711 14.3771C12.2172 14.3117 12.5177 14.6054 12.4507 14.9437Z" />
</g>
<defs>
<clipPath id="clip0_1_116">
<rect width="17.8824" height="16" fill="white" transform="translate(3.05882 4)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>