<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_78)">
<path d="M19.0427 20.8892H15.1345C14.6068 20.8892 14.1772 20.4554 14.1772 19.9226V15.9753C14.1772 14.7647 13.2008 13.7788 12.0018 13.7788C10.8028 13.7788 9.82634 14.7647 9.82634 15.9753V19.9226C9.82634 20.4554 9.39675 20.8892 8.86904 20.8892H4.9573C4.70122 20.8892 4.4607 20.7889 4.28001 20.6053C4.09932 20.4228 4 20.18 4 19.9214L4.00957 9.05586C4.00957 8.74172 4.16274 8.44571 4.41762 8.26568L11.4513 3.2866C11.7804 3.05341 12.2232 3.05341 12.5522 3.2866L19.5896 8.29106C19.8432 8.47229 19.9952 8.7671 19.9952 9.08123L20 19.9226C20 20.1812 19.9007 20.424 19.72 20.6065C19.5393 20.7889 19.2988 20.8892 19.0427 20.8892ZM12.0006 4.07799L4.96687 9.05707L4.9573 19.9238H8.86904V15.9766C8.86904 14.2319 10.2739 12.8134 12.0018 12.8134C13.7297 12.8134 15.1345 14.2319 15.1345 15.9766V19.9238H19.0427L19.0379 9.08244L12.0006 4.07799Z" />
</g>
<defs>
<clipPath id="clip0_1_78">
<rect width="16" height="17.7778"  transform="translate(4 3.11111)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>