<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon/Moon">
<path id="Vector" d="M19.0516 13.9957C17.9717 14.8617 16.6017 15.3801 15.1134 15.3801C11.6348 15.3801 8.80531 12.5496 8.80531 9.07209C8.80531 7.47579 9.40186 6.01629 10.3821 4.90341C10.4359 4.84243 10.4908 4.78251 10.5469 4.72368C10.7932 4.46508 10.5989 4.00297 10.2521 4.08816C10.212 4.09801 10.172 4.10816 10.1321 4.11863C6.68034 5.01757 4.12544 8.16183 4.12544 11.8913C4.12544 16.3192 7.72842 19.9222 12.1573 19.9222C15.7531 19.9222 18.8048 17.5462 19.8241 14.2827C19.8363 14.2436 19.8483 14.2043 19.86 14.1649C19.9616 13.8223 19.5089 13.6062 19.2387 13.8401C19.1773 13.8932 19.115 13.945 19.0516 13.9957ZM12.1573 19.0993C8.18201 19.0993 4.94828 15.8656 4.94828 11.8913C4.94828 9.16289 6.47155 6.78313 8.71333 5.55935C8.84428 5.48787 8.98084 5.63764 8.91044 5.76918C8.3833 6.75411 8.0843 7.87893 8.0843 9.07209C8.0843 12.9476 11.2378 16.1001 15.1134 16.1001C16.1865 16.1001 17.2046 15.8586 18.1152 15.4259C18.25 15.3619 18.3932 15.5055 18.3155 15.633C17.0494 17.7099 14.7626 19.0993 12.1573 19.0993Z" fill="#2A2A46"/>
</g>
</svg> 
</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>