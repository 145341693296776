<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_169)">
<path d="M20.9865 11.6252C20.9865 10.7154 20.9731 9.99137 20.9596 9.47881C20.9596 9.17767 20.9462 8.94061 20.9395 8.78043C20.926 8.52415 20.9126 8.09487 20.4484 8.07565C20.3206 8.07565 20.2735 8.06925 16.6413 9.68383V6.90315C16.6413 6.1279 15.9821 5.5 15.1682 5.5H4.47309C3.66592 5.5 3 6.1279 3 6.90315V17.0904C3 17.8657 3.66592 18.4936 4.47309 18.4936H15.1682C15.9821 18.4936 16.6413 17.8593 16.6413 17.0904V14.329L20.1054 15.8603C20.1928 15.8987 20.2937 15.9179 20.3946 15.9179C20.5224 15.9179 20.6502 15.8859 20.7511 15.8218C20.9058 15.7257 21 15.572 21 15.399C21 14.9184 21 13.2141 20.9865 11.6188V11.6252ZM15.6592 17.0968C15.6592 17.3531 15.4372 17.5646 15.1682 17.5646H4.47309C4.20404 17.5646 3.98206 17.3531 3.98206 17.0968V6.90315C3.98206 6.64687 4.20404 6.43544 4.47309 6.43544H15.1682C15.4372 6.43544 15.6592 6.64687 15.6592 6.90315V17.0904V17.0968ZM16.6413 13.3038V10.7282C18.0067 10.1195 19.2578 9.56851 19.9709 9.25456C19.9978 10.3245 20.0112 12.5991 20.0112 14.7967L16.6413 13.3103V13.3038Z" />
</g>
<defs>
<clipPath id="clip0_1_169">
<rect width="18" height="13" fill="white" transform="translate(3 5.5)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>