<template>

     <!--------USER JOURNEY------->
      <div class="tile grid" v-motion-slide-visible-once-bottom>
        <div class="lspace"></div>
        <h2 class="half1">User journey</h2>
        <div class="mspace"></div>
        <div class="center"><img src="@/assets/case02/BTS05.png" alt="User journey"></div>
      </div>
        <!--------IDEATION------->
        <div class="lspace"></div>
        <div class="tile grid"   v-motion-slide-visible-once-bottom>  
            <div class="center draft"><img src="@/assets/case02/BTS06.png" alt=""></div>
        </div>   
        <div class="tile grid"   v-motion-slide-visible-once-bottom>  
            <div class="center"><img src="@/assets/case02/BTS08.png" alt=""></div>
        </div>  
         <div class="lspace"></div>

        <div class="tile grid"   v-motion-slide-visible-once-bottom>   
            <div class="textbox text-block half2">
            <p>At this point our solution began to emerge. Designing our user journey took us to the decision to reorganize the content in our two tabs in a more intuitive way for the user.</p>
            <p >By sketching out some different options and thinking through the preliminary flow, I reviewed what was necessary, and what areas still needed improvement through wireframes. By spending some time on this step, I tried to ensure a solid base in the underlying UX, before moving on to the visuals.</p>
            </div> 
        </div>
     <!--------PROTOTYPE AND USER TEST------->


    

</template>
<script>
import ArrowRight from '@/assets/navigationicons/Arrowright.vue';
import '@/assets/caseslayout.css'

export default {
    components: {
        ArrowRight,
    }
}
</script>
<style scoped>

/*------Define-----*/

*  { 
    background-color: var(--background);
    max-width: 100%;
    }


.draft img {
    filter: grayscale(100%)  contrast(1.2);

}


</style>