<script>
import GoBackButton from '@/components/Goback-button.vue'
import CaseIntro from '@/components/CaseComponents/CaseIntro.vue'
import Cover from '@/assets/case04/covergif.gif'
import '@/assets/casestyle.css'

export default {
    components: {
        GoBackButton, CaseIntro, Cover,
    },

    data () {
    return {
      intros : [
        {
          id: '[04]', 
          title: 'supplier information request FOR AN EVENT MANAGEMENT PLATFORM', 
          introimg: [Cover],
          productbrief: 'A comprehensive platform utilized by event organizers to facilitate communication and gather vital information from their suppliers. As part of its functionality, the platform enables organizers to send Production Requests to suppliers, prompting them to provide essential details regarding event logistics, personnel, and accommodations.',
          challenge: 'Design an experience that guides the user through all the required actions, gathering different types of information for suppliers.',
          basics: 'Strategy, concept & design',
          overview: 'This plataform is used by event organisers to collect relevant information from their suppliers. They do this by sending their suppliers a Production Request. The supplier receives a request email, clicks on the link and gets redirected to an external webpage where they can fill in their Production request.'
        },
      ],
    }
  },
}
</script>
<template>
  <div class="caseEve">     
   <section>
    <CaseIntro v-for="intro in intros" v-bind="intro" :key="intro.title" />
   </section>
   <section>
      <div class="box even">
        <div class="textblock"  v-motion-slide-visible-once-bottom>
          <h2>Background</h2>
          <p>The platform enables event organizers to gather essential information from suppliers by sending Production Requests. Suppliers receive a request email with a link to fill in their details on an external webpage.</p>
          <dl>
            <dt>What a Production Request is for?</dt>
            <dt>Through this Production Request the supplier can let the organiser know</dt>
            <dd> which people will be working as crew on the event site, </dd>
            <dd> which people are invited as guests to the event, </dd>
            <dd> what accreditation (For example; parking tickets, portophones, access, dinner vouchers) these people need </dd>
            <dd> if these people require accommodation for an overnight stay. </dd>
            <dd> specify their power need during the event </dd>
            <dd> specify their transports from and to the event site </dd></dl>
          <p>And when the supplier is done filling in his request, the organiser will then review and approve the request.</p>     
        </div>
      </div> 
    </section>
    <section>
      <div class="previousscreens">
        <div class="figure">
          <img src="@/assets/case04/EVEbefore1.png" alt="Initial Screens">
        </div>
        <div class="figure">
          <img src="@/assets/case04/EVEbefore2.png" alt="Initial Screens">
        </div>
        <div class="figure">
          <img src="@/assets/case04/EVEbefore3.png" alt="Initial Screens">
        </div>
        <div class="figure">
          <img src="@/assets/case04/EVEbefore4.png" alt="Initial Screens">
        </div>
        <div class="figure">
          <img src="@/assets/case04/EVEbefore5.png" alt="Initial Screens">
        </div>
        <div class="figure">
          <img src="@/assets/case04/EVEbefore6.png" alt="Initial Screens">
        </div>
      </div>
    </section>
    <section>
      <div class="box even">        
        <img src="@/assets/case04/EveFlow01.png" alt="Initial Userflow">
      </div>
    </section>
    <section>
      <div class="box">
        <div class="textblock">
              <p>This is part of a personal redesign project.</p>
            <p>At this point, our main focus was on gathering initial information from suppliers, which is crucial for fostering effective communication between the organizer and the supplier throughout the project lifecycle.</p>
            <p>To kickstart this process, I carefully examined the current flow and screens, identifying any gaps or weaknesses that needed attention. Simultaneously, I delved into the necessary information and functionalities required for this stage, guiding us towards establishing clear requirements and identifying 'nice-to-haves' for the project.</p>
            <p>Rather than reinventing the wheel, our goal was to refine the existing flow, ensuring that all necessary actions are seamlessly executed without skipping any steps or leaving vital information blank. Additionally, we aimed to introduce brand customization and enhance communication channels between suppliers and organizers, offering added value to the project.
            </p>
        </div>
      </div>
    </section>
    <section>
      <div class="box even">
        <img src="@/assets/case04/EveFlow02.png" alt="New Userflow">
      </div>
    </section>
    <div class="box">
      <div class="texttotheleft requirements">
          <img src="@/assets/case04/Requirementstable.png" alt="Requirements table">
          <div class="textblockleft">
            <p>To ensure every task got done, we decided to go with a continuous flow to lead the user through.  </p>
            <p>We also introduced a new step regarding the supplier's schedule, which we found relevant at this early stage. Instead of just informing what the event date would be, the challenge here was to figure out a way to present the different stages of the organization, both on the organizer and supplier side, which would be the available dates and on which days the supplier would be on the spot.</p>
            <p>Another addition was a section dedicated to event organizer information. We realized the need to highlight this information, creating an anchor point on the overview page about organizers, contacts, and locations.</p>
            <p>Lastly, we also take the chance to boost the messaging system and customization features as our bonuses.</p>
          </div> 
      </div>
    </div> 
    <div class="bleed">
      <img src="@/assets/case04/MKEVE.gif" alt=""> 
    </div>
    <!-- Overview -->
    <div class="divider">
        <hr>
        <h2>Overview</h2>
    </div>
    <div class="wireframeswrap"> 
        <div class="wireframe">
          <img src="@/assets/case04/WR1.png" alt="" >
        </div>  
        <div class="wireframe">
          <img src="@/assets/case04/WR2.png" alt="" >
        </div> 
        <div class="wireframe">
          <img src="@/assets/case04/WR3.png" alt="" >
        </div> 
        <div class="wireframe">
          <img src="@/assets/case04/WR4.png" alt="" >
        </div> 
        <div class="wireframe">
          <img src="@/assets/case04/WR5.png" alt="" >
        </div> 
        <div class="wireframe">
          <img src="@/assets/case04/WR6.png" alt="" >
        </div> 
    </div>
    <div class="box divided">
      <div class="figure">
        <img src="@/assets/case04/Requestoverview-during.png" alt="Request Overview - in progress">
        <figcaption>[Request Overview] - in progress </figcaption>
      </div>
      <div class="figure">
        <img src="@/assets/case04/Requestoverview-after.png" alt="Request Overview - complete">
        <figcaption>[Request Overview] - complete </figcaption>
      </div>
   </div>
   <div class="box">
    <div class=texttotheleft>
      <div class="imagegroup">
          <div class="row">
            <div class="figure"><img src="@/assets/case04/OverviewComp11.png" alt=""></div>
            <div class="figure"><img src="@/assets/case04/OverviewComp12.png" alt=""></div>
          </div>
          <div class="figure"><img src="@/assets/case04/OverviewComp2.png" alt=""></div>
          <div class="figure"><img src="@/assets/case04/OverviewComp3.png" alt=""></div>
          <div class="figure"><img src="@/assets/case04/OverviewComp4.png" alt=""></div>
      </div>
      <div class="textblockleft">
          <p>Event Details: Displays pertinent information such as event organizer details, contact person, and event locations.</p>
          <p>Downloadable Resources: Offers access to relevant downloads, such as event site ground plans or additional documentation, to assist suppliers in fulfilling their requests.</p>
          <p>Event dates: build-up and break-off schedules</p>
          <p>Progress Tracker: Offers a visual representation of the completion status of required actions, guiding suppliers through the necessary steps to submit their Production Requests.</p>
      </div>
    </div>

  </div>
  <!-- Required Steps -->
    <div class="divider even">
        <hr>
        <h2>Required Steps - Flow</h2>
    </div>
    <div class="bleed even" id="flowscreens">
        <div class="figure"><img src="@/assets/case04/flowscreens.png" alt=""></div>

    </div>
    <div class="box">
      <div class="texttotheleft">
          <div class="imagegroup">
            <div class="row">
              <div class="figure"><img src="@/assets/case04/rs01.png" alt=""></div>
              <div class="figure"><img src="@/assets/case04/rs02.png" alt=""></div>
            </div>
            <div class="row">
              <div class="figure"><img src="@/assets/case04/rs03.png" alt=""></div>
              <div class="figure"><img src="@/assets/case04/rs04.png" alt=""></div>
            </div>
          </div>
          <div class="textblockleft">
            <p>The forms have been divided into multiple steps, accompanied by a progress tracker that serves as a to-do list. It aims to provide users with a sense of progress and achievement as they complete each step, while also encouraging them to remain engaged until all tasks are finished.</p>
          </div>

      </div>
    </div>
    <div class="box totheright">
      <div class="figure screensrow"><img src="@/assets/case04/Terms.png" alt=""></div>
      <div class="figure screensrow"><img src="@/assets/case04/companydetails.png" alt=""></div>
      <div class="figure screensrow"><img src="@/assets/case04/contactonlocation.png" alt=""></div>
      <div class="figure screensrow"><img src="@/assets/case04/eventschedule.png" alt=""></div>
    </div>
        <div class="box totheright even">
      <div class="figure screensrow"><img src="@/assets/case04/SlowChat.png" alt=""></div>
      <div class="figure screensrow"><img src="@/assets/case04/brandcustomization.png" alt=""></div>
    </div>
    <div class="bleed even">
      <div class="figure screensrow"><img src="@/assets/case04/allscreems.png" alt=""></div>

    </div>
    <div class="box even">
      <div class="textblock">
   
        <p>Developing this solution spanned a week, building upon prior research, feedback collection, and pain point identification efforts.
      The redesigned Production Request pages prioritize efficiency, enhancing the overall supplier experience and streamlining event planning.
      The Overview page remains a centralized hub, now facilitating collaboration, communication, and management between organizers and suppliers.
      Successfully overcoming key challenges, our solution integrates with the existing product, offers easy navigation, and effectively guides users through each step of the process.
      </p>
      </div>
    </div>
</div>
</template>
<style scoped>

* {
  --color1: #0058FF;
  --color2: #ABABAB;
  --color3: #FEC671;
  box-sizing: border-box;

}

.navigation {
  margin-left: 120px;
}

.caseEve {
  display: flex;
  flex-direction: column;
  margin-left: 58px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100%;
  height: 100%;


}

.previousscreens {
  display: flex;
  padding: 6% 8%;
  margin: 2%;
  object-fit: contain;
  gap: 24px;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  width: fit-content;
      max-width: calc(100% - 58px);

}

.previousscreens .figure {
  flex: 0 0 calc(92% / 3)
}

.wireframeswrap {
  display: flex;
  flex-direction: row nowrap;
  width: 100%;
  padding: 4% 8%;
}

.wireframe {
  flex: 1;
  will-change: flex; 
  object-fit: contain;
  width: auto;
  padding: 0 8px;
  transition: 750ms ease-in-out;
}

.wireframe:hover {
    flex: 2;
    z-index: 999 !important;
}

#flowscreens{
  padding: 10px 0 80px 0;
}

.totheright {
 display: flex;
 justify-content: flex-start;
 align-content: flex-start;
 flex-direction: column;
 min-width: 100%;
}

.screensrow {
  align-self: flex-start;
}


@media only screen and (min-width: 1800px) {

  .previousscreens, .wireframeswrap {
    padding: 8% 20%;
  }
}

@media only screen and (max-width: 900px) {

  .previousscreens {
    height: unset;
    padding: 8%;
    gap: 16px;
  }

  .previousscreens .figure {
  flex: 0 0 calc(92% / 2)

  }}

  @media only screen and (max-width: 720px) {

  
    .screensrow img {
    height: auto;  
    max-width: 100%;
    }

}

</style>
