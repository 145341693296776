<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_92)">
<path d="M12.0215 12.7401C10.425 12.7401 9.12627 11.4744 9.12627 9.91869C9.12627 8.36293 10.425 7.09727 12.0215 7.09727C13.6179 7.09727 14.9167 8.36293 14.9167 9.91869C14.9167 11.4744 13.6179 12.7401 12.0215 12.7401ZM12.0215 8.17807C11.0356 8.17807 10.2339 8.95932 10.2339 9.92003C10.2339 10.8807 11.0356 11.662 12.0215 11.662C13.0073 11.662 13.809 10.8807 13.809 9.92003C13.809 8.95932 13.0073 8.17807 12.0215 8.17807Z" />
<path d="M11.9993 20.5L11.6075 20.1181C8.82165 17.4033 5.5 13.4148 5.5 9.83368C5.5 6.2526 8.41597 3.5 11.9993 3.5C15.5826 3.5 18.4986 6.34165 18.4986 9.83368C18.4986 13.3257 15.177 17.402 12.3898 20.1181L11.9979 20.5H11.9993ZM11.9993 4.57945C9.02657 4.57945 6.60768 6.9367 6.60768 9.83368C6.60768 13.0167 9.82133 16.7813 12.0007 18.9672C14.5484 16.417 17.3923 12.8858 17.3923 9.83368C17.3923 6.78153 14.9734 4.57945 11.9993 4.57945Z" />
</g>
<defs>
<clipPath id="clip0_1_92">
<rect width="13" height="17" fill="white" transform="translate(5.5 3.5)"/>
</clipPath>
</defs>
</svg>

</template>
<style scoped>

path {
    fill: var(--main-color)
}

</style>